import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  changeRowsPerPage,
  changePage,
} from '../../../../redux/tasks/tasks.actions'
import TasksTable, { availableColumns } from '../../../molecules/TasksTable'

const TasksListTable = ({
  tasksList,
  fetching,
  pagination,
  changeRowsPerPageHandler,
  changePageHandler,
  clickTaskRowHandler,
  error,
}) => (
  <TasksTable
    onChangePage={changePageHandler}
    onChangeRowsPerPage={changeRowsPerPageHandler}
    onClickTaskRow={clickTaskRowHandler}
    tasksList={tasksList}
    fetching={fetching}
    error={error}
    pagination={pagination}
    columnsToDisplay={[
      availableColumns.NAME,
      availableColumns.DESCRIPTION,
      availableColumns.ID,
      availableColumns.TOOLS,
    ]}
  />
)

const mapStateToProps = (state) => {
  return {
    tasksList: state.tasks.tasksList,
    fetching: state.tasks.isFetching,
    pagination: state.tasks.pagination,
    error: state.tasks.errorMessagge,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRowsPerPageHandler: (e, pagination) =>
    dispatch(changeRowsPerPage(e, pagination)),
  changePageHandler: (page, pagination) =>
    dispatch(changePage(page, pagination)),
})

TasksListTable.propTypes = {
  tasksList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  changeRowsPerPageHandler: PropTypes.func.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  clickTaskRowHandler: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksListTable)
