const AppTestMui = {}

AppTestMui.Table = {}
AppTestMui.Table.Pagination = '.MuiTablePagination-select.MuiSelect-selectMenu'
AppTestMui.Table.PageMenuList = '.MuiMenu-list'
AppTestMui.Table.NextPage = '[aria-label="Next page"]'
AppTestMui.Table.PrevPage = '[aria-label="Previous page"]'

AppTestMui.Modal = {}
AppTestMui.Modal.Root = '.MuiDialogContent-root'

export default AppTestMui
