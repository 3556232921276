import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import ValidationError from '../atoms/ValidationError'

const styles = (theme) => ({
  root: { height: 38 },
  input: {
    height: 36,
  },

  textFieldInputUnderline: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
})

const textFieldStyle = {
  display: 'flex',
  marginRight: 1,
  marginBottom: 8,
}

const FormTextField = ({
  className,
  classes,
  label,
  input,
  InputLabelProps,
  meta: { touched, invalid, error, warning },
  type,
  disableMinHeight,
  ...restProps
}) => {
  if (error && !React.isValidElement(error)) {
    if (typeof error === 'function') {
      const { translationKey, translationProperties } = error()
      error = (
        <ValidationError
          error={translationKey}
          translationProperties={translationProperties}
        />
      )
    } else {
      error = <ValidationError error={error} />
    }
  }

  if (warning) {
    touched = true
  }

  const inputLabelProps = { ...InputLabelProps }
  if (type === 'date') inputLabelProps.shrink = true
  return (
    <TextField
      label={label}
      placeholder={label}
      style={textFieldStyle}
      InputLabelProps={inputLabelProps}
      InputProps={{
        classes: {
          underline: classes.textFieldInputUnderline,
          input: classes.input,
        },
      }}
      error={touched && invalid}
      helperText={touched && error}
      type={type}
      {...input}
      {...restProps}
    />
  )
}

FormTextField.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  InputLabelProps: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  disableMinHeight: PropTypes.bool,
}

const styled = withStyles(styles)(FormTextField)

export default styled
