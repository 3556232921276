import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const TableNoData = ({ cols, t }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={cols}>
        {t('noData')}
      </TableCell>
    </TableRow>
  )
}

TableNoData.propTypes = {
  t: PropTypes.func,
  cols: PropTypes.number.isRequired,
}

export default withTranslation(['global'])(TableNoData)
