export const mapWorklogEntryDataToServer = (data) => ({
  ...data,
  start_time: `${data.start_time}:00 ${data.tz_offset}`,
  end_time: `${data.end_time}:00 ${data.tz_offset}`,
  tools: data.tools
    ? JSON.stringify(
        data.tools
          .map((tool) => ({ [tool.id]: tool.selected }))
          .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
      )
    : null,
  comments: '', // as of Workforce (Android) v2.4.0, comments cannot be null
})
