import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { change, reset, SubmissionError, formValueSelector } from 'redux-form'
import { compose } from 'redux'
import { fetchTasksListStartAsync } from '../../../../redux/tasks/tasks.actions'
import { fetchToolsList } from '../../../../redux/tools/tools.actions'
import { Button, Box } from '@material-ui/core'
import ModalBox from '../../ModalBox'
import TasksListTable from './TasksListTable'
import CreateTaskForm from './form/CreateTaskForm'
import RemoteSubmitButton from '../../../atoms/RemoteSubmitButton'
import { createNewTask } from '../../../../api/api.tasks'
import { createNewTool, deleteTool } from '../../../../api/api.tools'
import {
  CREATE_TASK_FORM_ID,
  CREATE_TOOL_FORM_ID,
  Routes,
} from '../../../../constants'
import { createRoute } from '../../../../utils'
import CreateToolForm from './form/CreateToolForm'

class TasksListView extends Component {
  state = {
    openFormDialog: false,
    showToolInput: false,
  }

  async componentDidMount() {
    this.props.fetchTasksList()
    this.props.fetchToolsList()
  }

  handleOpenFormDialog = () => this.setState({ openFormDialog: true })
  handleCloseFormDialog = () => this.setState({ openFormDialog: false })

  handleHideToolInput = () => this.setState({ showToolInput: false })
  handleShowToolInput = () => this.setState({ showToolInput: true })

  handleClickTaskRow = (e, id) =>
    this.props.history.push(
      createRoute(Routes.Tasks.Detail.url, { taskId: id }),
    )

  submitDeleteTool = async (toolId) => {
    const { fetchToolsList } = this.props

    const rootResponse = await deleteTool(toolId)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TASK_DELETED'
    ) {
      fetchToolsList()
    }
  }

  submitCreateToolForm = async (values) => {
    const { resetForm, preselectNewTool, tenantId, fetchToolsList } = this.props

    values.tenant_id = tenantId

    const rootResponse = await createNewTool(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TOOL_CREATED'
    ) {
      await fetchToolsList()
      resetForm(CREATE_TOOL_FORM_ID)
      this.handleHideToolInput()
      preselectNewTool(rootResponse.response.id)
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  submitCreateTaskForm = async (values) => {
    const { tenantId, fetchTasksList, toolsList } = this.props

    values.tenant_id = tenantId

    const rootResponse = await createNewTask(toolsList, values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TASK_CREATED'
    ) {
      this.handleCloseFormDialog()
      fetchTasksList()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const { t, isFetchingToolsList, fetchingToolsListError, toolsList } =
      this.props
    const { openFormDialog, showToolInput } = this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.tasks')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('tasks:list.createModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openFormDialog}
          actionButton={
            <RemoteSubmitButton
              formId={CREATE_TASK_FORM_ID}
              appTestId={'create-task-form-button-id'}
            />
          }
        >
          <>
            <CreateTaskForm
              onSubmit={this.submitCreateTaskForm}
              isFetchingToolsList={isFetchingToolsList}
              fetchingToolsListError={fetchingToolsListError}
              toolsList={toolsList}
              handleDeleteTool={this.submitDeleteTool}
            />
            <CreateToolForm
              onSubmit={this.submitCreateToolForm}
              showToolInput={toolsList.length === 0 || showToolInput}
              onShowToolInput={this.handleShowToolInput}
            />
          </>
        </ModalBox>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px 8px -8px' }}
        >
          <Box p={1}>
            <Button
              aria-label="add task"
              variant="contained"
              color="primary"
              onClick={this.handleOpenFormDialog}
            >
              {t('add') + ' ' + t('task')}
            </Button>
          </Box>
        </Box>

        <TasksListTable clickTaskRowHandler={this.handleClickTaskRow} />
      </>
    )
  }
}

const toolFormSelector = formValueSelector(CREATE_TOOL_FORM_ID)

const mapStateToProps = (state) => ({
  tenantId: state.user.tenantId,
  isFetchingToolsList: state.tools.isFetching,
  fetchingToolsListError: state.tools.errorMessage,
  toolsList: state.tools.list,
  toolName: toolFormSelector(state, 'name'),
})

const mapDispatchToProps = (dispatch) => ({
  fetchTasksList: () => dispatch(fetchTasksListStartAsync()),
  fetchToolsList: () => dispatch(fetchToolsList()),
  resetForm: (formId) => dispatch(reset(formId)),
  preselectNewTool: (id) =>
    dispatch(change(CREATE_TASK_FORM_ID, `tools.${id}`, true)),
})

TasksListView.propTypes = {
  history: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  fetchTasksList: PropTypes.func.isRequired,
  fetchToolsList: PropTypes.func.isRequired,
  isFetchingToolsList: PropTypes.bool.isRequired,
  fetchingToolsListError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  preselectNewTool: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toolsList: PropTypes.array.isRequired,
  tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const TasksListViewComposed = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(TasksListView)

export default TasksListViewComposed
