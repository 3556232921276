import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support

const SpringFade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        // onEnter()
        // fade on enter causes a react ref bug TODO investigate and fix
      }
    },
    onRest: () => {
      if (open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

SpringFade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

export default SpringFade
