import { UIActionTypes } from './ui.types'

const INITIAL_STATE = {
  ui: {
    isLeftSidebarCollapsed: false,
    barAlertContents: null,
  },
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UIActionTypes.EXPAND_LEFT_SIDEBAR:
      return {
        ui: {
          ...state.ui,
          isLeftSidebarCollapsed: false,
        },
      }
    case UIActionTypes.COLLAPSE_LEFT_SIDEBAR:
      return {
        ui: {
          ...state.ui,
          isLeftSidebarCollapsed: true,
        },
      }
    case UIActionTypes.OPEN_BAR_ALERT:
      return {
        ui: {
          ...state.ui,
          barAlertContents: {
            title: action.payload.title,
            type: action.payload.type,
            body: action.payload.body,
          },
        },
      }
    case UIActionTypes.CLOSE_BAR_ALERT:
      return {
        ui: {
          ...state.ui,
          barAlertContents: null,
        },
      }
    default:
      return state
  }
}

export default reducer
