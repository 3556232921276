import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Routes from '../../../../constants/Routes'
import ReportListSubview from './ReportsListView'
import ReportDetailView from './ReportDetailView'

class ReportsView extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Reports.url} component={ReportListSubview} />
        <Route path={Routes.Reports.Detail.url} component={ReportDetailView} />
      </Switch>
    )
  }
}

export default ReportsView
