import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import GroupAssignedUsersList from './GroupAssignedUsersList'
import GroupAssignedExoskeletonsList from './GroupAssignedExoskeletonsList'
import GroupDetailsCard from './GroupDetailsCard'

class GroupDetailView extends Component {
  render() {
    const { t } = this.props

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.groups')}</title>
        </Helmet>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <GroupDetailsCard />
          </Grid>
          <Grid item xs={4}>
            <GroupAssignedExoskeletonsList />
          </Grid>
          <Grid item xs={8}>
            <GroupAssignedUsersList />
          </Grid>
        </Grid>
      </>
    )
  }
}

GroupDetailView.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation(['groups'])(GroupDetailView)
