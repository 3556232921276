import React from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { TableRowSkeleton, TableNoData } from '../atoms'
import Paper from '@material-ui/core/Paper'
import { TableRow, Checkbox } from '@material-ui/core'
import { DEFAULT_PAGINATION_OPTIONS } from '../../constants'
import { withTranslation } from 'react-i18next'

export const availableColumns = Object.freeze({
  CHECKBOX: 'checkbox',
  NAME: 'name',
  URL: 'gf_url',
  TYPE: 'type',
})

const renderCellContents = (
  t,
  row,
  columnName,
  selectedIdsList,
  checkboxOnChange,
) => {
  switch (columnName) {
    case availableColumns.CHECKBOX:
      return (
        <Checkbox
          checked={selectedIdsList.includes(row.id)}
          value={row.id}
          onChange={checkboxOnChange}
        />
      )
    case availableColumns.TYPE:
      return row[columnName] == null
        ? 'N/A'
        : t(`components:surveyTypeDropdown.${row[columnName]}`)
    default:
      return row[columnName]
  }
}

const SurveysTable = ({
  surveysList,
  selectedIdsList = [],
  checkboxOnChange = () => {},
  fetching,
  pagination,
  onChangeRowsPerPage: changeRowsPerPageHandler,
  onChangePage: changePageHandler,
  error,
  t,
  columnsToDisplay,
}) => {
  let tableRows = null

  if (
    (fetching && surveysList.length === 0) ||
    (fetching && surveysList.length > 0)
  ) {
    tableRows = (
      <TableRowSkeleton
        cells={columnsToDisplay.length}
        rows={pagination.rowsPerPage}
      />
    )
  }

  if (!fetching && error === 0) {
    tableRows = (
      <TableRow>
        <TableCell align="center" colSpan={columnsToDisplay.length}>
          {t('errors:failedToLoadData')}
        </TableCell>
      </TableRow>
    )
  }

  if (!fetching && surveysList.length === 0) {
    tableRows = <TableNoData cols={columnsToDisplay.length} />
  }

  if (!fetching && surveysList.length > 0) {
    tableRows = surveysList.map((row) => (
      <TableRow key={row.id} hover style={{ cursor: 'pointer' }}>
        {columnsToDisplay.map((columnName) => (
          <TableCell key={columnName}>
            {renderCellContents(
              t,
              row,
              columnName,
              selectedIdsList,
              checkboxOnChange,
            )}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columnsToDisplay.map((val, i) => (
              <TableCell key={i}>
                {t(`components:surveysTable.tableHeaders.${val}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions}
        component="div"
        count={pagination.total || -1}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.currentPage}
        onChangePage={(e, page) => changePageHandler(page, pagination)}
        onChangeRowsPerPage={(e) => changeRowsPerPageHandler(e, pagination)}
      />
    </TableContainer>
  )
}

SurveysTable.propTypes = {
  columnsToDisplay: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(availableColumns)),
  ),
  selectedIdsList: PropTypes.array,
  checkboxOnChange: PropTypes.func,
  surveysList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['components'])(SurveysTable)
