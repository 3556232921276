import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'

const mapStateToProps = (state) => ({
  ...state.dialog,
})

class DialogView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        dialogIsOpen: nextProps.dialogProps.open,
        content: nextProps.dialogProps.content,
        title: nextProps.dialogProps.title,
      })
    }
  }

  closeModal = () => {
    this.setState({ dialogIsOpen: false })
  }

  render() {
    const { dialogIsOpen, title, content } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={dialogIsOpen}
        onClose={this.closeModal}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    )
  }
}

DialogView.propTypes = {
  dialogProps: PropTypes.object,
}

const connected = connect(mapStateToProps, null)(DialogView)

export default connected
