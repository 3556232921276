import React from 'react'
import PropTypes from 'prop-types'

import CountryDropdown from './CountryDropdown'

const CountryDropdownField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...rest
}) => (
  <CountryDropdown
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...rest}
  />
)
CountryDropdownField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
}

export default CountryDropdownField
