import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { chartColors } from '../../constants/ChartBoundaries'

function LinearProgressWithLabel({ devColor = 1, ...props }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <Typography>{props.label}</Typography>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" dev-color={devColor} {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  devColor: PropTypes.number,
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '20px',
    '& .MuiLinearProgress-root': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiLinearProgress-root[dev-color="1"]': {
      '& .MuiLinearProgress-bar': {
        backgroundColor: `rgba(${chartColors.electric.join(',')})`,
      },
    },
    '& .MuiLinearProgress-root[dev-color="2"]': {
      '& .MuiLinearProgress-bar': {
        backgroundColor: `rgba(${chartColors.pink.join(',')})`,
      },
    },
  },
})

export default function LinearWithValueLabel(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel
        value={props.value > 100 ? 100 : props.value}
        label={props.label}
        devColor={props.devColor}
      />
    </div>
  )
}

LinearWithValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  devColor: PropTypes.number,
}
