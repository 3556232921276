import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import AvailableTasksDropdown from './AvailableTasksDropdown'
import { change, formValueSelector } from 'redux-form'

const inputStyles = (theme) => ({
  selectField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 24,
    fontWeight: 500,
  },
})

const AvailableTasksDropdownField = ({
  label,
  input,
  classes,
  formId,
  meta: { touched, invalid, error },
  taskAvailableTools,
  updateAvailableTools,
  ...rest
}) => {
  const changeHandlerCallback = (e, tools) => {
    if (tools && typeof tools === 'string') {
      const toolsArray = JSON.parse(tools)
      updateAvailableTools(
        formId,
        toolsArray.map((tool) => ({ ...tool, selected: false })),
      )
    } else {
      updateAvailableTools(formId, null)
    }
    input.onChange(e)
  }

  return (
    <AvailableTasksDropdown
      label={label}
      variant="outlined"
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      className={classes.selectField}
      changeHandlerCallback={changeHandlerCallback}
      {...input}
      {...rest}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  taskAvailableTools: formValueSelector(ownProps.formId)(state, 'tools'),
})

const mapDispatchToProps = (dispatch) => ({
  updateAvailableTools: (formId, tools) => {
    dispatch(change(formId, 'tools', tools))
  },
})

AvailableTasksDropdownField.propTypes = {
  taskAvailableTools: PropTypes.array,
  updateAvailableTools: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  classes: PropTypes.any,
  meta: PropTypes.object,
}

export default compose(
  withStyles(inputStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(AvailableTasksDropdownField)
