const chartConst = {
  B_REST: 'rest',
  B_LOW: 'low',
  B_MODERATE: 'moderate',
  B_HIGH: 'high',
  B_VERY_HIGH: 'very_High',
  D_SENSOR_0: 'sensor_0',
  D_SENSOR_1: 'sensor_1',
}

const chartColors = {
  green: ['62,201,62'],
  yellow: ['255,223,41'],
  red: ['252,64,43'],
  orange: ['255,170,33'],
  blue: ['24,216,219'],
  electric: ['145, 206, 255'],
  pink: ['255, 145, 242'],
  // blue: ['119,218,230'],
}

const chartOptions = {
  // Customize chart options
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          drawBorder: false,
        },

        stacked: true,
        ticks: {
          autoSkip: true,
          suggestedMin: 40,
          display: true,
          stepSize: 2,
          beginAtZero: true,
          suggestedMax: 10,
        },

        maxRotation: 0,
        minRotation: 0,
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
          drawBorder: false,
          color: 'rgba(0, 0, 0, 0)',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          autoSkipPadding: 20,
        },
      },
    ],
  },
}

const chartDatasetProps = {
  barChart: {
    steppedLine: false,
    spanGaps: false,
    pointRadius: 0,
    borderWidth: {
      top: 2,
      right: 0,
      bottom: 0,
      left: 0,
    },
    categoryPercentage: 1,
    barPercentage: 1.0,
    barThickness: 'flex',
  },
}

export { chartConst, chartOptions, chartDatasetProps, chartColors }
