import React from 'react'
import { Divider, FormGroup, Typography, withStyles } from '@material-ui/core'
import { Field, Form, formValues, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { required } from '../../../../utils'
import {
  normalizeDateTimeInput,
  normalizeTzOffset,
} from '../../../../utils/data-formatter'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { FormCheckbox } from '../../../molecules'
import FormOutlinedTextField from '../../../molecules/FormOutlinedTextField'
import WorkersDropdownField from '../../../molecules/WorkersDropdownField'
import AvailableTasksDropdownField from '../../../molecules/AvailableTasksDropdownField'

const styles = () => ({
  checkboxField: {
    display: 'inline',
  },
  divider: {
    marginBottom: '24px',
  },
  formLabel: {
    marginBottom: '16px',
  },
})

const CreateWorklogEntryForm = (props) => {
  const { classes, form, handleSubmit, t, submitting, tools } = props
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Typography variant="h6" gutterBottom>
          Assigned to
        </Typography>
        <Field
          disabled={submitting}
          label={t('worklog:form.worker.label')}
          name="user_id"
          component={WorkersDropdownField}
          type="select"
          required
          validate={[required]}
        />
      </FormGroup>
      <FormGroup>
        <Typography variant="h6" gutterBottom>
          Task details
        </Typography>
        <Field
          disabled={submitting}
          label={t('worklog:form.task.label')}
          name="task_id"
          component={AvailableTasksDropdownField}
          props={{
            formId: form,
          }}
          type="select"
          required
          validate={[required]}
        />
        <Field
          disabled={submitting}
          label={t('worklog:form.tzOffset.label')}
          name="tz_offset"
          component={FormOutlinedTextField}
          placeholder={'+01:00'}
          normalize={normalizeTzOffset}
          type="datetime"
          helperText={t('worklog:form.tzOffset.helperText')}
          required
          validate={[required]}
        />
        <Field
          disabled={submitting}
          label={t('worklog:form.startTime.label')}
          name="start_time"
          component={FormOutlinedTextField}
          placeholder={'YYYY-MM-DD HH:MM'}
          normalize={normalizeDateTimeInput}
          type="datetime"
          helperText={t('worklog:form.startTime.helperText')}
          required
          validate={[required]}
        />
        <Field
          disabled={submitting}
          label={t('worklog:form.endTime.label')}
          name="end_time"
          component={FormOutlinedTextField}
          placeholder={'YYYY-MM-DD HH:MM'}
          normalize={normalizeDateTimeInput}
          type="datetime"
          helperText={t('worklog:form.endTime.helperText')}
          required
          validate={[required]}
        />
        <Field
          disabled={submitting}
          label={t('worklog:form.borgScore.label')}
          name="borg_score"
          component={FormOutlinedTextField}
          placeholder={'A number from 6 to 20 based on the BORG scale'}
          type="number"
        />
      </FormGroup>

      {tools && typeof tools === 'object' && (
        <>
          <Divider variant="middle" className={classes.divider} />
          <FormGroup>
            <Typography variant="h6">Tools</Typography>
            {tools.map((tool, index) => (
              <Field
                styles={{
                  root: { '& .MuiFormControlLabel-root': { display: 'block' } },
                }}
                type="checkbox"
                key={tool.id}
                name={`tools[${index}].selected`}
                disabled={submitting}
                label={tool.name}
                component={FormCheckbox}
                formControlLabelProps={{
                  onClick: (event) => event.stopPropagation(),
                }}
                checked={tool.selected}
              />
            ))}
          </FormGroup>
        </>
      )}
    </Form>
  )
}

CreateWorklogEntryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  tools: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  withStyles(styles),
  withTranslation(['worklog']),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  }),
  formValues('tools'),
)(CreateWorklogEntryForm)
