import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core/'
import { TitleWithSubtitle, ColorizeNumbers } from '../atoms/index'
import { makeStyles } from '@material-ui/core/styles'
import * as ct from '../organisms/SummaryCards/card-types'
// import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
import BadgePillow from '../atoms/BadgePillow'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import p from '../themes/styles/palette'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  positionNum: {
    position: 'relative',
    fontWeight: 400,
    letterSpacing: '-.025rem',
    // backgroundColor: 'red',
  },

  capitalize: {
    textTransform: 'capitalize',
  },

  colorizeNumbers: {
    height: '56px',
    marginTop: '20px',
    minWidth: '82px',
    letterSpacing: '-.1rem',

    '& small': {
      fontSize: '.62em',
      fontWeight: 600,
    },
  },

  wrapper: {
    display: 'inline-block',
    width: '12px',
  },
  percent: {
    // backgroundColor: 'red',
    minWidth: '62px',
    textAlign: 'left',
    display: 'inline',
  },

  withArrow: {
    paddingLeft: '0px',
  },

  downArrow: {
    position: 'relative',
    display: 'inline-block',
    color: p.green[500],

    left: -8,
    bottom: -10,
  },

  upArrow: {
    position: 'relative',
    display: 'inline-block',
    color: p.red[500],
    left: -8,
    bottom: -10,
  },

  h6: {
    letterSpacing: '-.025rem',
    lineHeight: '1.125rem',
  },

  flexEvenWidth: {
    flexBasis: '100%',
  },

  caption: {
    fontWeight: 500,
    letterSpacing: '-0.025rem',
  },
  customText: {
    margin: '12px 0 4px 0',
    padding: '8px 6px',
    minWidth: '82px',
    fontWeight: 400,
    borderRadius: '6px',
    letterSpacing: '-.25rem',
  },
}))

export const SummaryCards = ({ metrics, classes, type = ct.SIMPLE_CARD }) => {
  const additionClasses = useStyles()

  let cardContent = null

  const percentArrow = (val) => {
    val = +val

    if (val > 0) {
      return <ArrowDropUpIcon className={additionClasses.upArrow} />
    }

    if (val < 0) {
      return <ArrowDropDownIcon className={additionClasses.downArrow} />
    }
  }

  switch (type) {
    case ct.SIMPLE_CARD:
      cardContent = (
        <>
          <Box display="flex" flexWrap="nowrap">
            {metrics
              ? metrics.map((el, i) => (
                  <Box
                    key={i}
                    flexGrow={1}
                    className={additionClasses.flexEvenWidth}
                  >
                    <TitleWithSubtitle
                      title={el.title}
                      subtitle={el.subtitle}
                    />

                    <Typography
                      className={additionClasses.positionNum}
                      fontWeight="fontWeightLight"
                      align="left"
                      variant="h3"
                    >
                      <ColorizeNumbers value={el.value} color={el.color} />
                    </Typography>
                  </Box>
                ))
              : null}
          </Box>
        </>
      )
      break
    case ct.SINGLE_TITLE:
      cardContent = (
        <>
          <Typography noWrap variant="h6" align="left">
            {metrics.title}
          </Typography>

          <Box
            display="flex"
            flexWrap="nowrap"
            // justifyContent="center"

            style={{ height: 'calc(100% - 18px)' }}
            // alignItems="center"
          >
            {metrics.data
              ? metrics.data.map((el, i) => (
                  <Box key={i} flexGrow={1} padding={0.5} flexBasis={0}>
                    <Typography
                      // component="div"
                      className={additionClasses.positionNum}
                      variant="h4"
                    >
                      {el?.postfix ? el.postfix : null}
                      {el.style === 'text-bg' ? (
                        <BadgePillow
                          value={el.value}
                          color={el.color}
                          scale={metrics.colorScale}
                          className={additionClasses.customText}
                        />
                      ) : null}
                      {el.style === 'text' ? (
                        <ColorizeNumbers
                          className={additionClasses.colorizeNumbers}
                          scale={metrics.colorScale}
                          value={el.value}
                          color={el.color}
                        />
                      ) : null}
                      {el.style === 'percent' && el.value !== null ? (
                        <ColorizeNumbers
                          className={[
                            additionClasses.colorizeNumbers,
                            additionClasses.withArrow,
                          ].join(' ')}
                          scale={metrics.colorScale}
                          value="children"
                          color={el.color}
                        >
                          <span className={additionClasses.wrapper}>
                            {percentArrow(el.value)}
                          </span>
                          <span className={additionClasses.percent}>
                            {Math.abs(el.value)}
                            <small>%</small>
                          </span>
                        </ColorizeNumbers>
                      ) : null}
                      {el.style === 'percent' && el.value === null ? (
                        <Skeleton animation="wave" variant="text" />
                      ) : null}
                    </Typography>
                    <TitleWithSubtitle
                      title={el.title}
                      subtitle={el.subtitle}
                      align="center"
                    />
                  </Box>
                ))
              : null}
          </Box>
        </>
      )
      break
    default:
      cardContent = ''
  }

  return (
    <Paper align="left" spacing={1} className={classes.join(' ')}>
      {cardContent}
    </Paper>
  )
}

SummaryCards.propTypes = {
  classes: PropTypes.array,
  metrics: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.string,
}
