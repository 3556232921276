const toJSONArray = (toolsList, jsonObj) => {
  const array = Object.keys(jsonObj)
    .filter((key) => jsonObj[key])
    .map((key) => ({
      id: key,
      name: toolsList.find((tool) => tool.id === key).name,
    }))

  return array.length > 0 ? JSON.stringify(array) : null
}

export const mapTaskDataToServer = (toolsList, data) => ({
  name: data.name,
  tenant_id: data.tenant_id,
  description: data.description,
  tools: !data.tools ? null : toJSONArray(toolsList, data.tools),
})
