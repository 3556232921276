import { ToolsActionTypes } from './tools.types'
import { _ } from '../../lib'

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  errorMessagge: undefined,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ToolsActionTypes.FETCH_TOOLS_START: {
      const oldState = _.cloneDeep(state)
      return {
        ...oldState,
        isFetching: true,
      }
    }
    case ToolsActionTypes.FETCH_TOOLS_SUCCESS: {
      const oldState = _.cloneDeep(state)

      return {
        ...oldState,
        isFetching: false,
        list: action.payload,
      }
    }
    case ToolsActionTypes.FETCH_TOOLS_FAILURE: {
      const oldState = _.cloneDeep(state)
      return {
        ...oldState,
        isFetching: false,
        errorMessagge: action.payload,
      }
    }

    default:
      return state
  }
}

export default reducer
