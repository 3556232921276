import React from 'react'
import PropTypes from 'prop-types'
import { Fab, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { GoBackIcon } from './Icons'
import { AppTestIds } from '../../constants'

const styles = (theme) => ({
  backButtonClass: {
    color: 'black',
    width: 38,
    height: 38,
    background: 'transparent',
    top: -4,
    marginRight: 4,
    boxShadow: 'none',
    '&:hover': {
      background: '#ccc',
    },
  },
})

class BackButton extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  handlePress = () => {
    const { history } = this.props

    history.goBack()
  }

  render() {
    const { classes } = this.props

    return (
      <Fab
        app-testid={AppTestIds.Reports.Details.BackButton}
        color="primary"
        aria-label="Back"
        className={classes.backButtonClass}
        onClick={this.handlePress}
      >
        <GoBackIcon />
      </Fab>
    )
  }
}

const styled = withStyles(styles)(BackButton)
const routed = withRouter(styled)

export default routed
