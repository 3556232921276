import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  changeRowsPerPage,
  changePage,
} from '../../../../redux/surveys/surveys.actions'
import SurveysTable, { availableColumns } from '../../../molecules/SurveysTable'

const SurveysListTable = ({
  surveysList,
  fetching,
  pagination,
  changeRowsPerPageHandler,
  changePageHandler,
  error,
}) => (
  <SurveysTable
    onChangePage={changePageHandler}
    onChangeRowsPerPage={changeRowsPerPageHandler}
    surveysList={surveysList}
    fetching={fetching}
    error={error}
    pagination={pagination}
    columnsToDisplay={[
      availableColumns.NAME,
      availableColumns.URL,
      availableColumns.TYPE,
    ]}
  />
)

const mapStateToProps = (state) => {
  return {
    surveysList: state.surveys.surveysList,
    fetching: state.surveys.isFetching,
    pagination: state.surveys.pagination,
    error: state.surveys.errorMessagge,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRowsPerPageHandler: (e, pagination) =>
    dispatch(changeRowsPerPage(e, pagination)),
  changePageHandler: (page, pagination) =>
    dispatch(changePage(page, pagination)),
})

SurveysListTable.propTypes = {
  surveysList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  changeRowsPerPageHandler: PropTypes.func.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveysListTable)
