import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  h6: {
    letterSpacing: '-.025rem',
    lineHeight: '1.125rem',
  },

  capitalize: {
    textTransform: 'capitalize',
  },

  caption: {
    fontWeight: 500,
    letterSpacing: '-0.025rem',
  },
}))

const TitleWithSubtitle = ({ title, subtitle, align = 'left' }) => {
  const classes = useStyles()

  return (
    <>
      {title ? (
        <Typography
          noWrap
          className={[classes.h6, classes.capitalize].join(' ')}
          align={align}
          variant="h6"
        >
          {title}
        </Typography>
      ) : null}

      {subtitle ? (
        <Typography
          variant="subtitle2"
          align={align}
          className={[classes.caption, classes.capitalize].join(' ')}
        >
          {subtitle}
        </Typography>
      ) : null}
    </>
  )
}

TitleWithSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  align: PropTypes.string,
}

export default TitleWithSubtitle
