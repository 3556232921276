import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Drawer, List, withStyles } from '@material-ui/core'
import wearhealthLogo from '../../../images/wh-logo-inverted.svg'
// import wearhealthWletterLogo from '../../../images/wearhealth-logo-w.svg'
import graphBg from '../../../images/graph-background.svg'
import {
  collapseLeftSidebar,
  expandLeftSidebar,
} from '../../../redux/ui/ui.actions'
import { appUiSelector } from '../../../redux/ui/ui.selectors'
import ToggleCollapseButton from '../../atoms/ToggleCollapseButton'
import MapAllowedMenuItems from '../../../routes/MapAllowedMenuItems'

const EXPANDED_DRAWER_WIDTH = 264
const COLLAPSED_DRAWER_WIDTH = 72

const styles = (theme) => ({
  collapsedDrawer: {
    width: COLLAPSED_DRAWER_WIDTH,
    flexShrink: 0,
    overflowY: 'hidden',
  },
  expandedDrawer: {
    width: EXPANDED_DRAWER_WIDTH,
    flexShrink: 0,
    overflowY: 'hidden',
  },
  appLogoStyle: {
    display: 'block',
    margin: '4px auto 0',
    transition: 'all 1s ease-in-out',
  },

  collapsedDrawerPaper: {
    width: COLLAPSED_DRAWER_WIDTH,
    overflow: ' visible',
    paddingTop: 12,
    background: `url(${graphBg}), linear-gradient(135deg, rgba(124,196,250,1) 0%, rgba(5,82,181,1) 100%)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .collapesed': {
      marginLeft: '11px',
      width: '52px',
      overflow: 'hidden',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  expandedDrawerPaper: {
    width: EXPANDED_DRAWER_WIDTH,
    overflow: ' visible',
    paddingTop: 12,
    background: `url(${graphBg}), linear-gradient(135deg, rgba(124,196,250,1) 0%, rgba(5,82,181,1) 100%)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .collapesed': {
      marginLeft: '0px',
      width: EXPANDED_DRAWER_WIDTH,
      overflow: 'hidden',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '& .toggle': {
      opacity: 1, // put 0 to make it visible on hover only
      transition: 'all .25s ease-in-out',
    },
    '&:hover': {
      '& .toggle': {
        opacity: 1,
        transition: 'all .25s ease-in-out',
      },
    },
  },
  toolbar: theme.mixins.toolbar,
  navLink: {
    textDecoration: 'none',
    '& span': {
      fontWeight: 600,
    },
    color: '#fff',
    display: 'block',
    '& .nav-icon': {
      color: 'rgba(255,255,255,.54)',
      minWidth: '48px',
      position: 'relative',
      left: '-4px',
    },
  },

  activeNavLink: {
    // backgroundColor: 'blue',
    '& > div': {
      // backgroundColor: `${theme.palette.primary[1000]}8a`,
      background: `linear-gradient(90deg, ${theme.palette.primary[1000]}8a 0%, rgba(255,255,255,.0) 120%)`,
    },
    '& span': {
      color: '#fff',
    },
    '& .nav-icon': {
      color: theme.palette.primary[50],
    },
  },
  list: {
    overflow: 'hidden',
  },
  hideText: {
    opacity: '0',
  },

  versionTextContainer: {
    position: 'fixed',
    paddingLeft: 4,
    fontSize: 11,
    bottom: 0,
    opacity: 0.7,
    color: theme.palette.common.white,
  },
})

const mapDispatchToProps = {
  collapseLeftSidebar,
  expandLeftSidebar,
}

class LeftSidebar extends React.Component {
  render() {
    const { classes, collapsed } = this.props

    return (
      <Drawer
        className={collapsed ? classes.collapsedDrawer : classes.expandedDrawer}
        variant="permanent"
        classes={{
          paper: collapsed
            ? classes.collapsedDrawerPaper
            : classes.expandedDrawerPaper,
        }}
      >
        <ToggleCollapseButton />
        <div className="collapesed">
          <img
            className={classes.appLogoStyle}
            src={wearhealthLogo}
            alt="WorkSense"
          />
        </div>

        <div className={classes.toolbar} />
        <List className={classes.list}>
          <MapAllowedMenuItems collapsed={collapsed} classes={classes} />
        </List>
      </Drawer>
    )
  }
}

LeftSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  collapsed: appUiSelector(state).isLeftSidebarCollapsed,
})

const styled = withStyles(styles)(LeftSidebar)
const connected = connect(mapStateToProps, mapDispatchToProps)(styled)

export default connected
