import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  changeRowsPerPage,
  changePage,
} from '../../../../redux/deviceTelemetry/deviceTelemetry.actions'
import EventsTable, { availableColumns } from '../../../molecules/EventsTable'

const EventsListTable = ({
  eventsList,
  fetching,
  pagination,
  changeRowsPerPageHandler,
  changePageHandler,
  error,
}) => (
  <EventsTable
    onChangePage={changePageHandler}
    onChangeRowsPerPage={changeRowsPerPageHandler}
    eventsList={eventsList}
    fetching={fetching}
    error={error}
    pagination={pagination}
    columnsToDisplay={[
      availableColumns.MAC_ADR,
      availableColumns.USER_EMAIL,
      availableColumns.EVENT,
      availableColumns.DEV_TYPE,
      availableColumns.BTR_LVL,
      availableColumns.BT_RSSI,
      availableColumns.WIFI_RSSI,
      availableColumns.MB_DATA_RSSI,
      availableColumns.CREATED_AT,
    ]}
  />
)

const mapStateToProps = (state) => {
  return {
    eventsList: state.deviceTelemetry.eventsList,
    fetching: state.deviceTelemetry.isFetching,
    pagination: state.deviceTelemetry.pagination,
    error: state.deviceTelemetry.errorMessagge,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRowsPerPageHandler: (e, pagination) =>
    dispatch(changeRowsPerPage(e, pagination)),
  changePageHandler: (page, pagination) =>
    dispatch(changePage(page, pagination)),
})

EventsListTable.propTypes = {
  eventsList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  changeRowsPerPageHandler: PropTypes.func.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsListTable)
