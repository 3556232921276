import { UsersActionTypes } from './users.types'
import { _ } from '../../lib'

const INITIAL_STATE = {
  usersList: [],
  isFetching: false,
  errorMessagge: undefined,
  pagination: {
    rowsPerPage: 10,
    currentPage: 0,
    total: 0,
  },
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_START: {
      const oldState = _.cloneDeep(state)
      return {
        ...oldState,
        isFetching: true,
      }
    }
    case UsersActionTypes.FETCH_USERS_SUCCESS: {
      const oldState = _.cloneDeep(state)

      return {
        ...oldState,
        isFetching: false,
        usersList: action.payload.data,
        pagination: {
          rowsPerPage: +action.payload.per_page,
          currentPage: +action.payload.current_page - 1,
          total: +action.payload.total,
        },
      }
    }
    case UsersActionTypes.FETCH_USERS_FAILURE: {
      const oldState = _.cloneDeep(state)
      return {
        ...oldState,
        isFetching: false,
        pagination: { ...state.pagination },
        errorMessagge: action.payload,
      }
    }

    default:
      return state
  }
}

export default reducer
