import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { SubmissionError } from 'redux-form'
import { Parser, transforms } from 'json2csv'
import { BeatLoader } from 'react-spinners'
import { Button, Box } from '@material-ui/core'

import { CREATE_WORKLOG_ENTRY_FORM_ID, Routes } from '../../../../constants'
import { fetchWorklogListStartAsync } from '../../../../redux/worklog/worklog.actions'
import {
  createNewWorklogEntry,
  fetchWorklog,
} from '../../../../api/api.worklog'
import RemoteSubmitButton from '../../../atoms/RemoteSubmitButton'
import WorklogListTable from './WorklogListTable'
import CreateWorklogEntryForm from './CreateWorklogEntryForm'
import ModalBox from '../../ModalBox'
import { createRoute } from '../../../../utils'

class WorklogListView extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    fetchWorklogList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  state = {
    openFormDialog: false,
    fetching: false,
    worklog: null,
  }

  async componentDidMount() {
    this.props.fetchWorklogList()
  }

  handleOpenFormDialog = () => this.setState({ openFormDialog: true })
  handleCloseFormDialog = () => this.setState({ openFormDialog: false })

  handleClickWorklogRow = (e, id) =>
    this.props.history.push(
      createRoute(Routes.Worklog.Detail.url, { worklogId: id }),
    )

  submitCreateWorklogEntryForm = async (values) => {
    const { fetchWorklogList } = this.props

    const rootResponse = await createNewWorklogEntry(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'WORKLOG_ENTRY_CREATED'
    ) {
      this.handleCloseFormDialog()
      fetchWorklogList()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  fetchWorklog = async () => {
    this.setState({ fetching: true })

    const rootResponse = await fetchWorklog()
    const enclosedResponse = rootResponse.response

    if (!('error' in rootResponse)) {
      this.setState(
        {
          fetching: false,
          worklog: enclosedResponse,
        },
        () =>
          this.exportCsvData(this.convertJsonDataToCsvString(enclosedResponse)),
      )
    } else {
      this.setState({
        fetching: false,
      })
    }
  }

  exportCsvData = (data) => {
    const {
      user: {
        permissions: { tenants },
      },
    } = this.props
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.download = `worklog_${tenants[0].name}_${Date.now()}.csv`
    link.href = url
    link.click()
  }

  convertJsonDataToCsvString = () => {
    const { worklog } = this.state
    const { flatten } = transforms
    const opts = {
      transforms: [flatten({ objects: 'true', separator: '/' })],
      quote: "'",
      escapedQuote: "'",
    }
    const json2csvParser = new Parser(opts)

    return json2csvParser.parse(worklog)
  }

  render() {
    const { t } = this.props
    const { fetching, openFormDialog } = this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.worklog')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('worklog:list.createModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openFormDialog}
          actionButton={
            <RemoteSubmitButton
              formId={CREATE_WORKLOG_ENTRY_FORM_ID}
              appTestId={'create-worklog-entry-form-button-id'}
            />
          }
        >
          <CreateWorklogEntryForm
            form={CREATE_WORKLOG_ENTRY_FORM_ID}
            onSubmit={this.submitCreateWorklogEntryForm}
          />
        </ModalBox>

        <Box
          display="flex"
          flexDirection="row-reverse"
          justifyContent="space-between"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px 8px -8px' }}
        >
          <Box p={1}>
            <Button
              aria-label="add task"
              variant="contained"
              color="primary"
              onClick={this.handleOpenFormDialog}
            >
              {t('add') + ' ' + t('entry')}
            </Button>
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.fetchWorklog}
              disabled={fetching}
            >
              {fetching ? (
                <BeatLoader
                  sizeUnit={'px'}
                  size={9}
                  color={'#fff'}
                  loading={fetching}
                />
              ) : (
                `Download Worklog`
              )}
            </Button>
          </Box>
        </Box>

        <WorklogListTable clickWorklogRowHandler={this.handleClickWorklogRow} />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchWorklogList: () => dispatch(fetchWorklogListStartAsync()),
})

const mapStateToProps = (state) => ({
  user: state.user,
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(WorklogListView)
