import palette from './palette'

const overrides = {
  MuiListItem: {
    button: {
      height: 48,
    },
  },

  MuiInput: {
    input: {
      // height: 30,
    },
    underline: {
      '&:before': { borderBottom: `2px solid  ${palette.text[14]} ` },
    },
  },

  MuiPaper: {
    elevation1: {
      boxShadow: '0px 2px 8px rgba(128, 138, 157, .06)',
    },
    elevation2: {
      boxShadow:
        'rgba(128, 138, 157,  .1) 0px 4px 16px, rgba(128, 138, 157, 0.12) 0px 2px 8px',
    },
    elevation3: {
      boxShadow:
        'rgba(128, 138, 157,  .12) -1px 8px 32px, rgba(128, 138, 157,  .12) 0px 1px 4px 1px',
    },
  },

  MuiInputBase: {
    root: {},
    input: {
      paddingLeft: 12,
      paddingRight: 12,

      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
  },

  MuiButton: {
    // Name of the styleSheet
    root: {
      // Name of the rule
      height: 48,
      textTransform: 'none',
      // fontSize: '15px',
      fontWeight: 600,
      borderRadius: '6px',
      padding: '8px 16px',
      letterSpacing: '-0.0125rem',
    },

    containedPrimary: {
      color: '#fff',
      background: `linear-gradient(0deg, ${palette.primary[1000]} -39%,    ${palette.primary[400]} 100%)`,
      disabled: {
        color: '#fff',
      },
      '&$disabled': {
        opacity: 0.54,

        color: 'white',
        cursor: 'disabled',
      },
    },
  },
  MuiTableCell: {
    head: {
      color: palette.text.secondary,
      letterSpacing: '-0.0125rem',
    },
    root: {
      fontWeight: 500,
      // color: 'red',
    },
  },
  MuiAvatar: {
    colorDefault: {
      fontSize: 21,
      backgroundColor: palette.text[27],
    },
  },
  MuiSelect: {
    icon: {
      right: 12,
      fill: palette.text[27],
    },

    selectMenu: {
      paddingRight: '36px !important',
    },

    root: {
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
      background: 'linear-gradient(#fff 80%, #c5d9e8 180%)',
      border: '1px solid #c5d9e8',
      color: palette.text.primary,
      '&:hover': {
        background: '#cecece',
      },
    },
  },
}

export default overrides
