import {
  CountryCodes,
  MOBILE_PHONE_NO_MIN_LENGTH,
  MOBILE_PHONE_NO_MAX_LENGTH,
} from '../constants'

export const required = (value) => (value ? undefined : 'Required')

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined

export const maxValue = (max) => (value) =>
  value && value > max ? `Must be at most ${max}` : undefined

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const exactLength = (max) => (value) =>
  value && (value.length < max || value.length > max)
    ? `Must be ${max} characters`
    : undefined

/**
 * Validates format of mobile phone number
 * @param {string} value
 * @returns {boolean}
 */
export const mobilePhoneNo = (value) => {
  if (!value) return undefined

  const regEx = new RegExp(
    '^\\+(' +
      Object.values(CountryCodes).reduce(
        (prev, curr) => prev + `|${curr.DIAL}`,
      ) +
      ') ?[1-9][\\d ]+$',
    'i',
  )
  const formatted = regEx.test(value)
  const trimmed = value.replace(/ +/g, '')

  const length =
    trimmed.length >= MOBILE_PHONE_NO_MIN_LENGTH &&
    trimmed.length <= MOBILE_PHONE_NO_MAX_LENGTH

  return !formatted || !length ? 'invalidMobilePhoneNo' : undefined
}

export const demographics = (demographics) => {
  if (!demographics) return undefined

  const { weight, height, birthday, sex } = demographics

  if (weight && height && birthday && sex) return undefined

  return 'allDemographicsFieldsAreRequired'
}

export const weight = (value) => {
  if (!value) return undefined

  const regEx = new RegExp('^\\d{1,3}(\\.\\d{1,2})?$')
  const formatted = regEx.test(value)

  return !formatted ? 'invalidWeight' : undefined
}

export const height = (value) => {
  if (!value) return undefined

  const regEx = new RegExp('^\\d{1,3}$')
  const formatted = regEx.test(value)

  return !formatted ? 'invalidHeight' : undefined
}
