import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { fetchWithErrors, withParams } from '../../../../utils'
import { _ } from '../../../../lib'
import { Endpoints } from '../../../../constants'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CalendarChart from '../../charts/CalendarChart'
import { formatCalendarData } from '../../../../utils/data-formatter'
import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = (theme) => ({
  paper: {
    position: 'relative',
    borderRadius: '0px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
})

class WorkloadCalendarWidget extends Component {
  state = {
    fetchError: false,
    fetching: false,
    loaded: false,
    workload: {
      data: [],
    },
  }

  async componentDidMount() {
    await this.getAnalytics()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { groupCode: prevPropsGroupCode } = prevProps

    const { groupCode: propsGroupCode } = this.props

    if (prevPropsGroupCode !== propsGroupCode) {
      await this.getAnalytics()
    }
  }

  getAnalytics = async () => {
    const workload = await this.fetchAnalytics()

    const oldState = _.cloneDeep(this.state)

    if (workload !== undefined) {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          loaded: true,
          workload,
        })
    } else {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          fetchError: true,
          workload: { workload: [] },
        })
    }
  }

  fetchAnalytics = async (type) => {
    const oldState = { ...this.state }
    !this.unmount && this.setState({ ...oldState, fetching: true })

    const response = await fetchWithErrors(
      withParams(Endpoints.Analytics.Urls.WorkloadCalendar, {
        type: type,
        group_code:
          this.props.groupCode === 'all' ? null : this.props.groupCode,
        year: '2024',
      }),
    )

    if (!('error' in response)) {
      return formatCalendarData([
        {
          day: '2024-02-09',
          value: '3.4',
        },
        {
          day: '2024-02-10',
          value: '5',
        },
        {
          day: '2024-02-20',
          value: '2.1',
        },
        {
          day: '2024-02-22',
          value: '6.5',
        },
        {
          day: '2024-03-03',
          value: '2.1',
        },
        {
          day: '2024-03-09',
          value: '4',
        },
        {
          day: '2024-03-10',
          value: '4',
        },
        {
          day: '2024-03-17',
          value: '8',
        },
        {
          day: '2024-03-18',
          value: '1.2',
        },
        {
          day: '2024-03-22',
          value: '4.5',
        },
        {
          day: '2024-03-23',
          value: '6.5',
        },
        {
          day: '2024-03-24',
          value: '5',
        },
        {
          day: '2024-03-25',
          value: '4.2',
        },
        {
          day: '2024-04-09',
          value: '2',
        },
        {
          day: '2024-04-14',
          value: '1.7',
        },
        {
          day: '2024-04-17',
          value: '6.76',
        },
        {
          day: '2024-04-19',
          value: '8.21',
        },
        {
          day: '2024-04-20',
          value: '5.55',
        },
        {
          day: '2024-04-21',
          value: '6.35',
        },
        {
          day: '2024-04-22',
          value: '7.96',
        },
        {
          day: '2024-05-01',
          value: '2.38',
        },
        {
          day: '2024-05-02',
          value: '6.11',
        },
        {
          day: '2024-05-03',
          value: '4.5',
        },
        {
          day: '2024-05-04',
          value: '7.38',
        },
        {
          day: '2024-05-05',
          value: '4.38',
        },
        {
          day: '2024-05-05',
          value: '1.87',
        },
        {
          day: '2024-05-06',
          value: '4.7',
        },
        {
          day: '2024-05-07',
          value: '1.2',
        },
        {
          day: '2024-05-08',
          value: '8.8',
        },
        {
          day: '2024-05-09',
          value: '3.66',
        },
        {
          day: '2024-05-10',
          value: '2.08',
        },
        {
          day: '2024-05-11',
          value: '1.2',
        },
        {
          day: '2024-05-12',
          value: '3.58',
        },
        {
          day: '2024-05-13',
          value: '3.38',
        },
        {
          day: '2024-05-14',
          value: '7.38',
        },
        {
          day: '2024-05-15',
          value: '5.68',
        },
        {
          day: '2024-05-16',
          value: '5.97',
        },
        {
          day: '2024-05-17',
          value: '4.72',
        },
        {
          day: '2024-05-18',
          value: '2.665',
        },
        {
          day: '2024-05-19',
          value: '3.595',
        },
        {
          day: '2024-05-20',
          value: '8',
        },
        {
          day: '2024-05-21',
          value: '3.4',
        },
        {
          day: '2024-05-22',
          value: '8.6',
        },
        {
          day: '2024-05-23',
          value: '4.7',
        },
        {
          day: '2024-05-24',
          value: '6.1',
        },
        {
          day: '2024-05-25',
          value: '2.98',
        },
        {
          day: '2024-05-26',
          value: '1.91',
        },
        {
          day: '2024-05-27',
          value: '3.81',
        },
        {
          day: '2024-05-28',
          value: '5',
        },
        {
          day: '2024-05-29',
          value: '4.22',
        },
        {
          day: '2024-05-30',
          value: '7.9',
        },
        {
          day: '2024-05-31',
          value: '2.1',
        },
      ])
      // return formatCalendarData(response.response)
    } else {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          fetchError: true,
        })
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  triggerViewError = (error) => {
    if (error) {
      this.setState({
        fetching: false,
        fetchError: true,
      })
    }
  }

  render() {
    const { t, classes } = this.props

    return (
      <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
        <Typography variant="h6" align="left">
          {`${t('workload')} ${t('heatmap')}`}
        </Typography>
        <CalendarChart
          type="workload"
          loaded={!this.state.fetching}
          data={this.state.workload.data}
          minDate={this.state.workload.minDate}
          maxDate={this.state.workload.maxDate}
        />
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    groupCode: state.dashboard.groupCode,
  }
}

WorkloadCalendarWidget.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  groupCode: PropTypes.string.isRequired,
}

export default compose(
  connect(mapStateToProps, null),
  withRouter,
  withStyles(styles),
  withTranslation(['global', 'dashboard']),
)(WorkloadCalendarWidget)
