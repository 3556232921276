import { DeviceTelemetryEventTypes } from '../constants'

/**
 * Return color based on scale
 * Low= [1 to 4) i.e until 3,9
 * Moderate= [4 to 8 ) i.e until 7.9
 * High= [8,10]
 * @param {Number} value
 * @param {String} color
 * @param {String} scale default physical
 *  @returns {String} string with color name
 */

export const semanticColorFromValue = (value, color, scale = 'physical') => {
  let colors = ''
  const REST_BOUND = 1.9
  const LOW_BOUND = 3.9
  const MODERATE_BOUND = 4.9
  const HIGH_BOUND = 7.9

  // Color override
  if (color === 'black') {
    return (colors = '')
  }

  switch (scale) {
    case 'physical':
      if (value <= REST_BOUND) {
        colors = 'lightblue'
      }

      if (value > REST_BOUND && value <= LOW_BOUND) {
        colors = 'green'
      }

      if (value > LOW_BOUND && value <= MODERATE_BOUND) {
        colors = 'yellow'
      }

      if (value > MODERATE_BOUND && value <= HIGH_BOUND) {
        colors = 'darkorange'
      }

      if (value > HIGH_BOUND) {
        colors = 'red'
      }

      break
    case 'mental':
      if (value <= LOW_BOUND) {
        colors = 'green'
      }

      if (value > LOW_BOUND && value <= HIGH_BOUND) {
        colors = 'darkorange'
      }

      if (value > HIGH_BOUND) {
        colors = 'red'
      }
      break
    case 'deviceTelemetryEvent':
      if (value === DeviceTelemetryEventTypes.DEV_C) {
        colors = 'green'
      }
      if (value === DeviceTelemetryEventTypes.DEV_DC) {
        colors = 'red'
      }
      if (value === DeviceTelemetryEventTypes.DEV_RC) {
        colors = 'yellow'
      }
      if (value === DeviceTelemetryEventTypes.PING) {
        colors = 'lightblue'
      }
      break
    case 'batteryLevel':
      if (value >= 0 && value <= 0.25) {
        colors = 'red'
      }
      if (value > 0.25 && value <= 0.5) {
        colors = 'yellow'
      }
      if (value > 0.5 && value <= 0.75) {
        colors = 'lightblue'
      }
      if (value > 0.75 && value <= 1) {
        colors = 'green'
      }
      break
    case 'bluetoothRSSI':
      if (value >= -1000 && value <= -66) {
        colors = 'red'
      }
      if (value > -66 && value <= -41) {
        colors = 'yellow'
      }
      if (value > -41 && value <= 0) {
        colors = 'green'
      }
      break
    case 'wifiRSSI':
      if (value >= -1000 && value <= -80) {
        colors = 'red'
      }
      if (value > -80 && value <= -70) {
        colors = 'yellow'
      }
      if (value > -70 && value <= -67) {
        colors = 'lightblue'
      }
      if (value > -67 && value <= 0) {
        colors = 'green'
      }
      break
    // case 'mbDataRSSI':
    default:
      colors = ''
  }

  return colors
}

/**
 * Converts hex colors #000000 to "rgba(0,0,0,1)"
 * @param {String} hex color format with hashtag
 * @param {Number} alpha optional, default value 1
 * @returns {String} rgba string fortmat
 */

export const hexToRgbA = (hex, alpha = 1) => {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      `,${alpha})`
    )
  }
  throw new Error('Bad Hex')
}

/**
 * Round onlu float number by precision
 * 2.33333 => 2.3
 * 2.0 => 2
 * @param {Number} number
 * @param {Precision} precision
 * @return {Number} rounded number
 */
export const roundFloat = (number, precision = 1) => {
  if (Number.isInteger(number) && number) {
    return +number.toFixed()
  } else {
    return +number.toFixed(precision)
  }
}

/**
 * Create string from error object
 * {mail:"error 1", id:"error 2" ... }
 * "error 1 error 2 ..."
 * @param {Object} number
 * @return {String}
 */
export const getErrors = (obj) => {
  const errorArray = []

  for (var key in obj) {
    errorArray.push(obj[key])
  }

  return errorArray.join(' ')
}
