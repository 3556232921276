import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TextField, MenuItem } from '@material-ui/core'
import { fetchAvailableTasks } from '../../api/api.tasks'

class AvailableTasksDropdown extends Component {
  static propTypes = {
    changeHandlerCallback: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  state = {
    fetching: false,
    fetchError: false,
    errorMessage: '',
    availableTasks: null,
  }

  async componentDidMount() {
    !this.unmount && this.setState({ fetching: true })

    await this.fetchAvailableTasks()
  }

  componentWillUnmount() {
    this.unmount = true
  }

  fetchAvailableTasks = async () => {
    const rootResponse = await fetchAvailableTasks()
    const enclosedResponse = rootResponse.response

    if (!('error' in rootResponse)) {
      !this.unmount &&
        this.setState({
          fetching: false,
          availableTasks: enclosedResponse,
        })
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchError: true,
          errorMessage: 'Error fetching available tasks',
        })
    }
  }

  render() {
    const { availableTasks, fetching, fetchError, errorMessage } = this.state
    const {
      label,
      defaultValue = '',
      value,
      changeHandlerCallback,
      ...rest
    } = this.props
    return (
      <TextField
        select
        label={label}
        defaultValue={defaultValue}
        // TODO: Refactor condition below after all forms and success/error popups have been refactored
        // EXPLANATION: A render will trigger from a form somewhere when reduxform is destroying the inputs
        // after successfully submitting. For that render, the props submitting and submitSucceeded will be both false
        // this means that before this component re-renders, the "value" will be set but the "roles" will not be fetched yet
        value={availableTasks ? value : ''}
        {...rest}
        onChange={(e) =>
          changeHandlerCallback(
            e,
            availableTasks.find((t) => t.id === e.target.value).tools,
          )
        }
      >
        {!fetching && availableTasks
          ? availableTasks.map((availableTask) => (
              <MenuItem value={availableTask.id} key={availableTask.id}>
                {availableTask.name}
              </MenuItem>
            ))
          : 'Loading'}
        {fetchError ? errorMessage : null}
      </TextField>
    )
  }
}

export default AvailableTasksDropdown
