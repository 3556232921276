import React, { PureComponent } from 'react'
import Chart from 'chart.js'
import { chartConst, chartOptions } from '../../../constants/ChartBoundaries'
import PropTypes from 'prop-types'
import { mapGradients } from '../../../utils/data-formatter'
import { withTranslation } from 'react-i18next'

Chart.defaults.global.defaultFontFamily = 'Maven Pro'

class LineChart extends PureComponent {
  state = {
    chartDOMref: null,
  }
  chartRef = React.createRef()

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    // this.buildChart()
    const { data: prevData } = prevProps
    const { data } = this.props

    if (data !== prevData && data.data.length > 0) {
      this.buildChart()
    }
  }

  buildChart = () => {
    const { t } = this.props
    const canvasChartRef = this.chartRef.current.getContext('2d')

    let gradientModifierStop = {}
    gradientModifierStop[chartConst.B_REST] = 0.3

    const colors = mapGradients(
      chartConst.B_REST,
      canvasChartRef,
      280,
      gradientModifierStop,
    )

    const stepSize = ((this.props.data.max - this.props.data.min) / 5).toFixed()

    const cx = new Chart(canvasChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels: this.props.data.labels,
        datasets: [
          {
            label: t('heartRate'),
            data: this.props.data.data,
            pointRadius: 0,
            spanGaps: false,
            backgroundColor: colors.area,
            borderColor: colors.stroke,
            borderWidth: 2,
          },
        ],
      },
      options: {
        ...chartOptions,
        legend: {
          align: 'end',
        },
        elements: {
          line: {
            tension: 0.4, // disables bezier curves
          },
        },
        scales: {
          yAxes: [
            {
              ...chartOptions.scales.yAxes.gridLines,
              ticks: {
                stepSize: stepSize,
                suggestedMin: this.props.data.min - 2,
                suggestedMax: this.props.data.max + 7,
              },
            },
          ],
          xAxes: [...chartOptions.scales.xAxes],
        },
        plugins: {
          crosshair: false,
        },
      },
    })

    if (this.state.chartDOMref === null) {
      this.setState({ chartDOMref: cx })
    }
  }
  render() {
    return (
      <div
        style={{
          height: this.props.height + 'px',
          position: 'absolute',
          bottom: '16px',
          width: 'calc(100% - 32px)',
        }}
      >
        <canvas id={this.props.chartId} ref={this.chartRef} />
      </div>
    )
  }
}

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  chartId: PropTypes.string.isRequired,
  t: PropTypes.func,
}

export default withTranslation(['global'])(LineChart)
