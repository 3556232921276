import {
  createRoute,
  fetchWithErrors,
  mapWorklogEntryDataToServer,
} from '../utils'
import { Endpoints } from '../constants'

export const fetchWorklog = () => fetchWithErrors(Endpoints.Worklog.Urls.Export)

export const createNewWorklogEntry = (formData) =>
  fetchWithErrors(Endpoints.Worklog.Urls.Create, {
    method: 'POST',
    body: JSON.stringify(mapWorklogEntryDataToServer(formData)),
  })

export const fetchWorklogEntry = (worklogId) =>
  fetchWithErrors(createRoute(Endpoints.Worklog.Urls.Detail, { worklogId }))

export const updateWorklogEntry = (worklogId, formData) =>
  fetchWithErrors(createRoute(Endpoints.Worklog.Urls.Update, { worklogId }), {
    method: 'PUT',
    body: JSON.stringify(mapWorklogEntryDataToServer(formData)),
  })

export const deleteWorklogEntry = (worklogId) =>
  fetchWithErrors(createRoute(Endpoints.Worklog.Urls.Delete, { worklogId }), {
    method: 'DELETE',
  })

export const fetchWorkers = () =>
  fetchWithErrors(Endpoints.WorklogManagement.Urls.ListWorkers)
