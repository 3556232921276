import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change, reset, SubmissionError, formValueSelector } from 'redux-form'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { fetchToolsList } from '../../../../../redux/tools/tools.actions'
import { RemoteSubmitButton } from '../../../../atoms'
import CreateTaskForm from '../form/CreateTaskForm'
import CreateToolForm from '../form/CreateToolForm'
import { fetchTask, updateTask } from '../../../../../api/api.tasks'
import { createNewTool, deleteTool } from '../../../../../api/api.tools'
import {
  AppTestIds,
  UPDATE_TASK_FORM_ID,
  CREATE_TOOL_FORM_ID,
} from '../../../../../constants'

const styles = () => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
  deleteButton: {
    height: 'auto',
  },
  remoteSubmitButton: {
    height: 'auto',
  },
})

const initializeToolsCheckboxes = (json) => {
  const arr = JSON.parse(json).map((tool) => ({
    [tool.id]: true,
  }))

  return arr.reduce((obj, value) => {
    Object.assign(obj, value)
    return obj
  }, {})
}

class TaskDetailCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    handleOpenDeleteDialog: PropTypes.func.isRequired,
    fetchToolsList: PropTypes.func.isRequired,
    toolsList: PropTypes.array.isRequired,
    resetForm: PropTypes.func.isRequired,
    isFetchingToolsList: PropTypes.bool.isRequired,
    fetchingToolsListError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    preselectNewTool: PropTypes.func.isRequired,
    tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }

  state = {
    task: null,
    showToolInput: false,
  }

  async componentDidMount() {
    this.fetchTask()
    this.props.fetchToolsList({ withDeleted: true })
  }

  handleHideToolInput = () => this.setState({ showToolInput: false })
  handleShowToolInput = () => this.setState({ showToolInput: true })

  async fetchTask() {
    const {
      match: {
        params: { taskId },
      },
    } = this.props

    const rootResponse = await fetchTask(taskId)

    this.setState({ task: rootResponse.response })
  }

  submitDeleteTool = async (toolId) => {
    const { fetchToolsList } = this.props

    const rootResponse = await deleteTool(toolId)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TASK_DELETED'
    ) {
      fetchToolsList({ withDeleted: true })
    }
  }

  submitCreateToolForm = async (values) => {
    const { resetForm, preselectNewTool, tenantId, fetchToolsList } = this.props

    values.tenant_id = tenantId

    const rootResponse = await createNewTool(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TOOL_CREATED'
    ) {
      await fetchToolsList()
      resetForm(CREATE_TOOL_FORM_ID)
      this.handleHideToolInput()
      preselectNewTool(rootResponse.response.id)
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  submitUpdateTaskForm = async (values) => {
    const {
      match: {
        params: { taskId },
      },
      toolsList,
      tenantId,
    } = this.props

    values.tenant_id = tenantId
    const rootResponse = await updateTask(taskId, toolsList, values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TASK_UPDATED'
    ) {
      this.fetchTask()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const {
      classes,
      t,
      toolsList,
      isFetchingToolsList,
      fetchingToolsListError,
      handleOpenDeleteDialog,
    } = this.props
    const { task, showToolInput } = this.state

    return (
      <Card>
        <CardHeader
          title={t('tasks:detail.detailsCard.title')}
          subheader={t('tasks:detail.detailsCard.subtitle', {
            date: task ? task.updated_at : '...',
          })}
        />
        <CardContent>
          <CreateTaskForm
            form={UPDATE_TASK_FORM_ID}
            isFetchingToolsList={isFetchingToolsList}
            fetchingToolsListError={fetchingToolsListError}
            toolsList={toolsList}
            onSubmit={this.submitUpdateTaskForm}
            initialValues={
              task && {
                ...(task.tools != null && {
                  tools: initializeToolsCheckboxes(task.tools),
                }),
                name: task.name,
                description: task.description,
              }
            }
            handleDeleteTool={this.submitDeleteTool}
          />
          <CreateToolForm
            onSubmit={this.submitCreateToolForm}
            showToolInput={toolsList.length === 0 || showToolInput}
            onShowToolInput={this.handleShowToolInput}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.deleteButton}
            variant="contained"
            component="button"
            color="secondary"
            onClick={handleOpenDeleteDialog}
          >
            {t('tasks:detail.button.delete.label')}
          </Button>
          <RemoteSubmitButton
            appTestId={AppTestIds.Tasks.UpdateButton}
            className={classes.remoteSubmitButton}
            formId={UPDATE_TASK_FORM_ID}
            buttonLabel={'update'}
          />
        </CardActions>
      </Card>
    )
  }
}

const toolFormSelector = formValueSelector(CREATE_TOOL_FORM_ID)

const mapStateToProps = (state) => ({
  tenantId: state.user.tenantId,
  toolsList: state.tools.list,
  isFetchingToolsList: state.tools.isFetching,
  fetchingToolsListError: state.tools.errorMessage,
  toolName: toolFormSelector(state, 'name'),
})

const mapDispatchToProps = (dispatch) => ({
  fetchToolsList: (params) => dispatch(fetchToolsList(params)),
  resetForm: (formId) => dispatch(reset(formId)),
  preselectNewTool: (id) =>
    dispatch(change(UPDATE_TASK_FORM_ID, `tools.${id}`, true)),
})

export default compose(
  withStyles(styles),
  withTranslation(['tasks']),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TaskDetailCard)
