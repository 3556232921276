import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Button, Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import WorklogDetailCard from './WorklogDetailCard'
import ModalBox from '../../../ModalBox'
import { Routes } from '../../../../../constants'
import { deleteWorklogEntry } from '../../../../../api/api.worklog'

class WorklogDetailView extends Component {
  state = {
    openDeleteDialog: false,
  }

  handleOpenDeleteDialog = () => this.setState({ openDeleteDialog: true })
  handleCloseDeleteDialog = () => this.setState({ openDeleteDialog: false })

  submitDeleteWorklogEntry = async () => {
    const {
      history,
      match: {
        params: { worklogId },
      },
    } = this.props

    const rootResponse = await deleteWorklogEntry(worklogId)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'WORKLOG_DELETED'
    ) {
      history.push(Routes.Worklog.url)
    }
  }

  render() {
    const { t } = this.props
    const { openDeleteDialog } = this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.worklog')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('worklog:detail.modal.delete.title')}
          onClose={this.handleCloseDeleteDialog}
          open={openDeleteDialog}
          actionButton={
            <Button color="secondary" onClick={this.submitDeleteWorklogEntry}>
              {t('global:delete')}
            </Button>
          }
        >
          <span>{t('worklog:detail.modal.delete.content')}</span>
        </ModalBox>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <WorklogDetailCard
              handleOpenDeleteDialog={this.handleOpenDeleteDialog}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

WorklogDetailView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTranslation(['worklog'])(WorklogDetailView)
