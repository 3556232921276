import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { ENV, ENVIRONMENTS } from '../../config/environment'
import { QRCodeCanvas } from 'qrcode.react'
import { Box, Button, Grid, TextField } from '@material-ui/core'
import { DownloadIcon } from '../atoms'
import whtaQrIcon from '../../images/wh-task-app-qr-icon.png'

const IMG_SRC = {
  [ENVIRONMENTS.local]: 'http://localhost:3000/favicon.ico',
  [ENVIRONMENTS.development]:
    'https://dashboard-dev.wearhealth.com/favicon.ico',
  [ENVIRONMENTS.production]: 'https://dashboard.wearhealth.com/favicon.ico',
}

const imageSettings = {
  src: IMG_SRC[ENV],
  x: undefined,
  y: undefined,
  height: 38,
  width: 38,
  excavate: true,
}

const QRCodeComponent = ({ alias, pin, value, size = 312, app = 'WH' }) => {
  const canvasRef = useRef(null)

  const handleDownloadQRCode = () => {
    const canvas = canvasRef.current.children[0]
    const image = canvas.toDataURL('image/png')
    const link = document.createElement('a')

    link.download = `qrcode-for-${alias}.png`
    link.href = image
    link.click()
  }

  return (
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <div ref={canvasRef}>
        <QRCodeCanvas
          value={value}
          size={size}
          imageSettings={
            // for the WH Task App change icon
            app.toUpperCase() === 'WHTA'
              ? { ...imageSettings, src: whtaQrIcon }
              : imageSettings
          }
        />
      </div>
      <Grid container spacing={2} style={{ marginTop: '2rem' }}>
        <Grid item xs={6}>
          <TextField
            label={'Username'}
            value={alias}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={'PIN'}
            value={pin}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownloadQRCode}
        style={{ width: '100%', marginTop: '2rem' }}
      >
        <DownloadIcon style={{ marginRight: '1rem' }} /> Download as PNG
      </Button>
    </Box>
  )
}

QRCodeComponent.propTypes = {
  app: PropTypes.string,
  alias: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
}

export default QRCodeComponent
