import { secondsToHour } from './time-calc'

/**
 * Calculate the KPIs of all filtered groups
 * all the single group contains averages for that group
 * in future this KPIs will become more dynamic to reflect
 * also date time selections
 * @param {String} selectedGroup id of the group
 * @param {Object} responseJSON JSON object of groups
 * @returns {Object}  returns final KPIs
 */

export const getSummaries = (selectedGroup, responseJSON) => {
  let sum = {
    avg_duration: null,
    avg_kcal: null,
    avg_mental: null,
    avg_physical: null,
  }

  // default values if there are no values for the group
  const sumDefault = {
    avg_duration: 0,
    avg_kcal: 0,
    avg_mental: 0,
    avg_physical: 0,
  }

  const ALL = 'all'

  if (selectedGroup === ALL && responseJSON.length > 0) {
    let totalArr = 0
    sum = responseJSON.reduce(
      (total, group, i, arr) => {
        if (group.kpis !== null && group.kpis !== undefined) {
          total.avg_duration += +group.kpis.agg_avg_duration
          total.avg_kcal += +group.kpis.agg_avg_kcal_per_minute
          total.avg_physical += +group.kpis.agg_avg_physical_load
          total.avg_mental += +group.kpis.agg_avg_mental_load

          // count valid group
          totalArr++
        }
        // return results when whole group array is looped
        if (i === arr.length - 1) {
          // Divide by group for average per group
          // total.avg_duration = +total.avg_duration / arr.length
          // avg_duration is converted from seconds to hours
          total.avg_duration = secondsToHour(total.avg_duration / totalArr)

          // avg_kcal is converted from kcal/minutes to kcal/hour
          total.avg_kcal = ((+total.avg_kcal * 60) / totalArr).toFixed(1)
          total.avg_mental = +total.avg_mental / totalArr
          total.avg_physical = +total.avg_physical / totalArr

          return total
        } else {
          return total
        }
      },
      { ...sumDefault },
    )

    return sum
  }

  if (selectedGroup !== ALL && responseJSON.length > 0) {
    // console.log('not all group', responseJSON)
    let filteredGroup = responseJSON.find((group) => group.id === selectedGroup)

    // check if there
    if (filteredGroup.kpis !== null) {
      return {
        avg_duration: secondsToHour(filteredGroup.kpis.agg_avg_duration),
        avg_kcal: (+filteredGroup.kpis.agg_avg_kcal_per_minute * 60).toFixed(2),
        avg_mental: +filteredGroup.kpis.agg_avg_mental_load,
        avg_physical: +filteredGroup.kpis.agg_avg_physical_load,
      }
    } else {
      return sumDefault
    }
  }
}
