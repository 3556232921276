import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Typography } from '@material-ui/core'
import queryString from 'query-string'

import { Endpoints, Routes } from '../../../../constants'

class ActivateSubview extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  state = {
    invalidToken: false,
    processingRequest: true,
  }

  componentDidMount() {
    const query =
      this.props.location.search &&
      queryString.parse(this.props.location.search)

    if (query && query.t) {
      this.activateUser(query.t)
    } else {
      this.setState({ invalidToken: true, processingRequest: false })
    }
  }

  activateUser = (token) => {
    const { history } = this.props

    return fetch(Endpoints.Auth.Urls.Activate + '?activation_token=' + token, {
      method: 'PUT',
    })
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          this.setState({ invalidToken: true, processingRequest: false })
        } else {
          this.setState({ invalidToken: false, processingRequest: false })
          return response.json()
        }
      })
      .then((data) => {
        setTimeout(() => history.push(Routes.Login.url), 5000)
      })
      .catch((error) => {
        console.log(error)
        return this.setState({ processingRequest: false })
      })
  }

  render() {
    const { invalidToken, processingRequest } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <title>{Routes.Activate.title}</title>
        </Helmet>
        <Typography align="center" variant="h6" gutterBottom>
          {processingRequest && 'processing request, please be patient...'}
          {invalidToken && !processingRequest && 'Your token is invalid.'}
          {!invalidToken &&
            !processingRequest &&
            'Your account has now been activated. You will be redirected to the Login page in 5 seconds...'}
        </Typography>
      </React.Fragment>
    )
  }
}

export default ActivateSubview
