import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const inputStyles = (theme) => ({
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 24,
    fontWeight: 500,
  },
})

const FormOutlinedTextField = ({
  label,
  input,
  classes,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    height={48}
    className={classes.textField}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    variant="outlined"
    {...input}
    {...custom}
  />
)

FormOutlinedTextField.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  InputLabelProps: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  disableMinHeight: PropTypes.bool,
}

export default withStyles(inputStyles)(FormOutlinedTextField)
