import React, { Component } from 'react'
import { createMuiTheme } from '@material-ui/core'
import ThemeWrapper from './ThemeWrapper'
import PropTypes from 'prop-types'
import * as styles from './styles/index'

const baseName = 'main-theme'

const mainTheme = createMuiTheme({
  root: {
    height: '100%',
    letterSpacing: '-0.025rem',
  },

  bold: {
    fontWeight: 600,
  },

  palette: styles.palette,

  typography: {
    useNextVariants: true,
    fontFamily: ['Maven Pro'],
    fontSize: 15,
    lineHeight: '1.5rem',
    body1: {
      fontSize: 15,
      fontWeight: 500,
    },
    h2: {
      letterSpacing: '-0.25rem',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: styles.palette.text.secondary,
      lineHeight: '1.5rem',
    },
    subtitle2: {
      color: styles.palette.text.secondary,
    },
  },

  paperShadow: {
    boxShadow: '0px 2px 6px #0000000A',
  },

  paperCards: {},

  overrides: styles.overrides,
})

class MainTheme extends Component {
  render() {
    const { children, className, ...others } = this.props
    const classes = [baseName, className]

    return (
      <ThemeWrapper className={classes.join(' ')} theme={mainTheme} {...others}>
        {children}
      </ThemeWrapper>
    )
  }
}

MainTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  others: PropTypes.object,
}

export default MainTheme
