import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { compose } from 'redux'
import { fetchSurveysListStartAsync } from '../../../../redux/surveys/surveys.actions'
import { Button, Box } from '@material-ui/core'
import ModalBox from '../../ModalBox'
import SurveysListTable from './SurveysListTable'
import CreateSurveyForm from './CreateSurveyForm'
import RemoteSubmitButton from '../../../atoms/RemoteSubmitButton'
import { createNewSurvey } from '../../../../api/api.surveys'
import { CREATE_SURVEY_FORM_ID } from '../../../../constants'

class SurveysListView extends Component {
  state = {
    openFormDialog: false,
  }

  async componentDidMount() {
    this.props.fetchSurveysList()
  }

  handleOpenFormDialog = () => this.setState({ openFormDialog: true })
  handleCloseFormDialog = () => this.setState({ openFormDialog: false })

  submitCreateSurveyForm = async (values) => {
    const { tenantId, fetchSurveysList } = this.props

    values.tenant_id = tenantId

    const rootResponse = await createNewSurvey(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'SURVEY_CREATED'
    ) {
      this.handleCloseFormDialog()
      fetchSurveysList()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const { t } = this.props
    const { openFormDialog } = this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.surveys')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('surveys:list.createModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openFormDialog}
          actionButton={
            <RemoteSubmitButton
              formId={CREATE_SURVEY_FORM_ID}
              appTestId={'create-survey-form-button-id'}
            />
          }
        >
          <CreateSurveyForm onSubmit={this.submitCreateSurveyForm} />
        </ModalBox>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px 8px -8px' }}
        >
          <Box p={1}>
            <Button
              aria-label="add task"
              variant="contained"
              color="primary"
              onClick={this.handleOpenFormDialog}
            >
              {t('add') + ' ' + t('survey')}
            </Button>
          </Box>
        </Box>

        <SurveysListTable />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  tenantId: state.user.tenantId,
})

const mapDispatchToProps = (dispatch) => ({
  fetchSurveysList: () => dispatch(fetchSurveysListStartAsync()),
})

SurveysListView.propTypes = {
  fetchSurveysList: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const SurveysListViewComposed = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SurveysListView)

export default SurveysListViewComposed
