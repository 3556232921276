import React, { PureComponent } from 'react'
import Chart from 'chart.js'
import { chartConst, chartOptions } from '../../../constants/ChartBoundaries'
import { mapGradients } from '../../../utils/data-formatter'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

Chart.defaults.global.defaultFontFamily = 'Maven Pro'

const addLineStyleProperties = (datasets, canvasChartRef) => {
  let colors = {}

  return datasets.map((dataset, index) => {
    colors = mapGradients(chartConst[`D_SENSOR_${index}`], canvasChartRef, 280)

    return {
      ...dataset,
      pointRadius: 1,
      spanGaps: false,
      backgroundColor: colors.area,
      borderColor: colors.stroke,
      borderWidth: 2,
      lineTension: 0,
    }
  })
}

class MotionMetricsChart extends PureComponent {
  state = {
    chartDOMref: null,
  }
  chartRef = React.createRef()

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { data: prevData } = prevProps
    const { data } = this.props

    if (
      data !== prevData &&
      Object.prototype.hasOwnProperty.call(data, 'datasets')
    ) {
      this.buildChart()
    }
  }

  buildChart = () => {
    // const { t } = this.props
    const canvasChartRef = this.chartRef.current.getContext('2d')

    const cx = new Chart(canvasChartRef, {
      type: 'line',
      data: {
        // remove duplicate strings from array
        labels: [
          ...new Set(
            this.props.data.datasets
              .map((dataset) => dataset.data.map((data) => data.t).flat())
              .flat(),
          ),
        ],
        datasets: addLineStyleProperties(
          this.props.data.datasets,
          canvasChartRef,
        ),
      },
      options: {
        ...chartOptions,
        scales: {
          yAxes: [
            {
              ...chartOptions.scales.yAxes.gridLines,
              ticks: {
                suggestedMin: this.props.data.min,
                suggestedMax: this.props.data.max,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:MM',
                },
                distribution: 'linear',
              },
            },
          ],
        },
        legend: {
          align: 'end',
        },
        elements: {
          line: {
            tension: 0.4, // disables bezier curves
          },
        },
        plugins: {
          crosshair: false,
        },
      },
    })

    if (this.state.chartDOMref === null) {
      this.setState({ chartDOMref: cx })
    }
  }
  render() {
    return (
      <div
        style={{
          height: this.props.height + 'px',
          position: 'absolute',
          bottom: '16px',
          width: 'calc(100% - 32px)',
        }}
      >
        <canvas id={this.props.chartId} ref={this.chartRef} />
      </div>
    )
  }
}

MotionMetricsChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  chartId: PropTypes.string.isRequired,
}

export default withTranslation(['global', 'deviceTelemetry'])(
  MotionMetricsChart,
)
