import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  changeRowsPerPage,
  changePage,
} from '../../../../redux/users/users.actions'
import UsersTable, { availableColumns } from '../../../molecules/UsersTable'

const UsersListTable = ({
  usersList,
  fetching,
  pagination,
  changeRowsPerPageHandler,
  changePageHandler,
  error,
}) => (
  <UsersTable
    onChangePage={changePageHandler}
    onChangeRowsPerPage={changeRowsPerPageHandler}
    usersList={usersList}
    fetching={fetching}
    error={error}
    pagination={pagination}
    columnsToDisplay={[
      availableColumns.FULL_NAME,
      availableColumns.EMAIL,
      availableColumns.ID,
      availableColumns.ROLES,
      availableColumns.STATUS,
      availableColumns.ACTIONS,
    ]}
  />
)

const mapStateToProps = (state) => {
  return {
    usersList: state.users.usersList,
    fetching: state.users.isFetching,
    pagination: state.users.pagination,
    error: state.users.errorMessagge,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRowsPerPageHandler: (e, pagination) =>
    dispatch(changeRowsPerPage(e, pagination)),
  changePageHandler: (page, pagination) =>
    dispatch(changePage(page, pagination)),
})

UsersListTable.propTypes = {
  usersList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  changeRowsPerPageHandler: PropTypes.func.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListTable)
