import { UserActionTypes } from './user.types'

export const setUserPermissions = (data) => ({
  type: UserActionTypes.SET_PERMISSIONS,
  payload: data,
})

export const setUserTenant = (data) => ({
  type: UserActionTypes.SET_TENANT,
  payload: data,
})

export const flushUserSettings = (data) => ({
  type: UserActionTypes.FLUSH_USER_SETTINGS,
})
