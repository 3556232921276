import React from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { TableRowSkeleton, TableNoData, BadgePillow } from '../atoms'
import Paper from '@material-ui/core/Paper'
import { TableRow } from '@material-ui/core'
import { DEFAULT_PAGINATION_OPTIONS } from '../../constants'
import { withTranslation } from 'react-i18next'

export const availableColumns = Object.freeze({
  MAC_ADR: 'mac_adr',
  USER_EMAIL: 'user_email',
  EVENT: 'event',
  DEV_TYPE: 'dev_type',
  BTR_LVL: 'btr_lvl',
  BT_RSSI: 'bt_rssi',
  WIFI_RSSI: 'wifi_rssi',
  MB_DATA_RSSI: 'mb_data_rssi',
  CREATED_AT: 'created_at',
})

const btSignalStrenght = (t, btRSSI) => {
  let strength = 'unknown'

  if (btRSSI >= -1000 && btRSSI <= -66) {
    strength = 'weak'
  }
  if (btRSSI > -66 && btRSSI <= -41) {
    strength = 'sufficient'
  }
  if (btRSSI > -41 && btRSSI <= 0) {
    strength = 'strong'
  }
  return t(`deviceTelemetry:signalStrength.${strength}`)
}

const wifiSignalRSSI = (t, wifiRSSI) => {
  let strength = 'unknown'

  if (wifiRSSI >= -1000 && wifiRSSI <= -80) {
    strength = 'veryWeak'
  }
  if (wifiRSSI > -80 && wifiRSSI <= -70) {
    strength = 'weak'
  }
  if (wifiRSSI > -70 && wifiRSSI <= -67) {
    strength = 'sufficent'
  }
  if (wifiRSSI > -67 && wifiRSSI <= 0) {
    strength = 'veryStrong'
  }

  return t(`deviceTelemetry:signalStrength.${strength}`)
}

const renderCellContents = (t, row, columnName) => {
  switch (columnName) {
    case availableColumns.USER_EMAIL:
      return row.user.email ? row.user.email : row.user.alias
    case availableColumns.EVENT:
      return (
        <BadgePillow
          value={row.event}
          scale="deviceTelemetryEvent"
          localizedString={t(`deviceTelemetry:eventTypes.${row.event}`)}
        />
      )
    case availableColumns.DEV_TYPE:
      return t(`deviceTelemetry:deviceTypes.${row.dev_type}`)
    case availableColumns.BTR_LVL:
      return (
        <BadgePillow
          value={row.btr_lvl}
          scale="batteryLevel"
          localizedString={`${Math.floor(row.btr_lvl * 100)}%`}
        />
      )
    case availableColumns.BT_RSSI:
      return (
        <BadgePillow
          value={row.bt_rssi}
          scale="bluetoothRSSI"
          localizedString={`${row.bt_rssi} dBm (${btSignalStrenght(
            t,
            row.bt_rssi,
          )})`}
        />
      )
    case availableColumns.WIFI_RSSI:
      return (
        <BadgePillow
          value={row.wifi_rssi}
          scale="wifiRSSI"
          localizedString={`${row.wifi_rssi} dBm (${wifiSignalRSSI(
            t,
            row.wifi_rssi,
          )})`}
        />
      )
    default:
      return row[columnName]
  }
}

const EventsTable = ({
  eventsList,
  fetching,
  pagination,
  onChangeRowsPerPage: changeRowsPerPageHandler,
  onChangePage: changePageHandler,
  error,
  t,
  columnsToDisplay,
}) => {
  let tableRows = null

  if (
    (fetching && eventsList.length === 0) ||
    (fetching && eventsList.length > 0)
  ) {
    tableRows = (
      <TableRowSkeleton
        cells={columnsToDisplay.length}
        rows={pagination.rowsPerPage}
      />
    )
  }

  if (!fetching && error === 0) {
    tableRows = (
      <TableRow>
        <TableCell align="center" colSpan={columnsToDisplay.length}>
          {t('errors:failedToLoadData')}
        </TableCell>
      </TableRow>
    )
  }

  if (!fetching && eventsList.length === 0) {
    tableRows = <TableNoData cols={columnsToDisplay.length} />
  }

  if (!fetching && eventsList.length > 0) {
    tableRows = eventsList.map((row, i) => (
      <TableRow
        key={`${row.mac_adr}_${row.created_at}_${i}`}
        hover
        style={{ cursor: 'pointer' }}
      >
        {columnsToDisplay.map((columnName) => (
          <TableCell key={columnName}>
            {renderCellContents(t, row, columnName)}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columnsToDisplay.map((val, i) => (
              <TableCell key={i}>
                {t(`components:eventsTable.tableHeaders.${val}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions}
        component="div"
        count={pagination.total || -1}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.currentPage}
        onChangePage={(e, page) => changePageHandler(page, pagination)}
        onChangeRowsPerPage={(e) => changeRowsPerPageHandler(e, pagination)}
      />
    </TableContainer>
  )
}

EventsTable.propTypes = {
  columnsToDisplay: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(availableColumns)),
  ),
  eventsList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['components'])(EventsTable)
