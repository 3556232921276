import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, MenuItem, Grid, MenuList } from '@material-ui/core'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import DaysJsAdapter from '@material-ui/pickers/adapter/dayjs' // choose your lib
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
  setDate,
  setPredefinedDate,
} from '../../../redux/reports/reports.actions'
import AppTestIds from '../../../constants/AppTestIds'

const useStyles = makeStyles({
  root: {},
  dateButton: {
    textTransform: 'capitalize',
  },
  neutralButton: {
    height: '48px',
    paddingRight: '16px',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
    background: 'linear-gradient(#fff 80%, #c5d9e8 180%)',
    border: '1px solid #c5d9e8',
    fontWeight: 500,
    color: '#3F536E',
    '&:hover': {
      background: '#cecece',
    },
  },
  popover: { maxWidth: '800px' },
  grid: { width: '940px' },
  menu: {
    borderLeft: '1px solid rgba(0,0,0,.14)',
  },
  menuItem: {
    height: '44px',
    '&.border': {
      borderBottom: '1px solid rgba(0,0,0,.14)',
      marginBottom: '8px',
    },
  },
})

const DateRangePicker = ({
  displayDate,
  closeMenuHandler,
  reportsQuery,
  anchorMenuEl,
  selectedDateRange,
  openMenuHandler,
  onDateChange,
  onDateFromMenu,
}) => {
  const classes = useStyles()

  const dateMenu = [
    { query: 'all', name: 'All' },
    { query: 'today', name: 'Today' },
    { query: 'yesterday', name: 'Yesterday' },
    { query: '7d', name: 'Last 7 days' },
    { query: '30d', name: 'Last 30 days' },
    { query: '60d', name: 'Last 60 days' },
    { query: '6m', name: 'Last 6 months' },
    { query: '12m', name: 'Last 12 months' },
  ]

  if (selectedDateRange[0] === '') {
    selectedDateRange[0] = null
    selectedDateRange[1] = null
  }

  // Before updating the reducer state, the best way is to
  // pass to the reducer both dates at the same time
  const handleDateChange = (range) => {
    let startDate, endDate

    if (range[0] !== null && range[1] !== null) {
      startDate = dayjs(range[0].$d)
      endDate = dayjs(range[1].$d)

      const newRange = {
        startDate: startDate,
        endDate: endDate,
      }

      onDateChange(newRange)
      // TODO:  dateMenuHandle: null,
    }
  }

  return (
    <>
      <Button
        aria-describedby="date-range-picker"
        app-testid={AppTestIds.Filters.DatePicker.Button}
        disableElevation
        className={[classes.dateButton, classes.neutralButton].join(' ')}
        variant="contained"
        color="primary"
        onClick={openMenuHandler}
      >
        {displayDate}
        <KeyboardArrowDownOutlinedIcon
          color="disabled"
          style={{ right: '-6px', position: 'relative' }}
        />
      </Button>

      <Popover
        id="date-range-picker"
        transitionDuration={200}
        open={reportsQuery.dateMenuIsOpen}
        anchorEl={anchorMenuEl}
        onClose={closeMenuHandler}
        style={{ maxWidth: '860px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container spacing={0} className={classes.grid}>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={DaysJsAdapter}>
              <StaticDateRangePicker
                className="test-class-datepicker"
                displayStaticWrapperAs="desktop"
                disableFuture
                value={selectedDateRange}
                // maxDate={dayjs.utc(new Date()).format('YYYY-MM-DD')}
                onChange={(date) => handleDateChange(date)}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4}>
            <MenuList className={classes.menu}>
              {dateMenu.map((date, index) => (
                <MenuItem
                  app-testid={
                    AppTestIds.Filters.DatePicker.Base + '.' + date.query
                  }
                  className={[
                    classes.menuItem,
                    index === 0 || index === 3 || index === 5 ? 'border' : '',
                  ].join(' ')}
                  key={date.query}
                  // selected={index === selectedIndex}
                  onClick={() =>
                    onDateFromMenu({
                      query: date.query,
                      name: date.name,
                    })
                  }
                >
                  {date.name}
                </MenuItem>
              ))}
            </MenuList>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    reportsQuery: state.reports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDateChange: (date) => dispatch(setDate(date)),
    onDateFromMenu: (date) => dispatch(setPredefinedDate(date)),
  }
}

DateRangePicker.propTypes = {
  displayDate: PropTypes.string.isRequired,
  closeMenuHandler: PropTypes.func.isRequired,
  anchorMenuEl: PropTypes.object,
  selectedDateRange: PropTypes.array,
  onDateChange: PropTypes.func.isRequired,
  onDateFromMenu: PropTypes.func.isRequired,
  openMenuHandler: PropTypes.func.isRequired,
  reportsQuery: PropTypes.object.isRequired,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(DateRangePicker)

export default connected
