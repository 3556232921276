import React from 'react'
import * as PropTypes from 'prop-types'
import type { InferProps } from 'prop-types'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import type { WithTranslation } from 'react-i18next'

import { Roles } from '../../constants'

export const AuthMethodType = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  eligibleRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Roles)))
    .isRequired,
}

const UserAuthMethodRadioGroupTypes = {
  userRoleScope: PropTypes.oneOf(Object.values(Roles)).isRequired,
  availableAuthMethods: PropTypes.array.isRequired,
  changeAuthMethodHandler: PropTypes.func.isRequired,
  value: PropTypes.shape(AuthMethodType).isRequired,
}

const UserAuthMethodRadioGroup: React.FC<
  InferProps<typeof UserAuthMethodRadioGroupTypes> & WithTranslation
> = (props) => {
  const {
    userRoleScope,
    availableAuthMethods,
    changeAuthMethodHandler,
    value,
    t,
  } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    changeAuthMethodHandler(
      availableAuthMethods.filter(
        (authMethod: InferProps<typeof AuthMethodType>) =>
          authMethod.name === (event.target as HTMLInputElement).value,
      )[0],
    )
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">Authentication Method</FormLabel>
      <RadioGroup
        aria-label="auth-method"
        value={value.name}
        onChange={handleChange}
        row
        style={{
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        {availableAuthMethods.map(
          (authMethod: InferProps<typeof AuthMethodType>) => (
            <FormControlLabel
              key={authMethod.name}
              value={authMethod.name}
              control={<Radio color="primary" />}
              label={t(
                `components:userAuthMethodRadioGroup.${authMethod.label}.label`,
              )}
              disabled={!authMethod.eligibleRoles.includes(userRoleScope)}
            />
          ),
        )}
      </RadioGroup>
    </FormControl>
  )
}

UserAuthMethodRadioGroup.propTypes = UserAuthMethodRadioGroupTypes

export default withTranslation(['components'])(UserAuthMethodRadioGroup)
