import React from 'react'
import * as PropTypes from 'prop-types'
import type { InferProps } from 'prop-types'
import DatePicker from './DatePicker'

import type { RestProps } from '../../types'

const DatePickerFieldTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
  }).isRequired,
  rest: PropTypes.object,
}

const DatePickerField: React.FC<
  InferProps<typeof DatePickerFieldTypes> & RestProps
> = ({ label, input, meta: { error, touched, invalid }, ...rest }) => (
  <DatePicker
    label={label}
    error={touched && invalid}
    helperText={error}
    {...input}
    {...rest}
  />
)

export default DatePickerField
