import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { SurveyTypes } from '../../constants'
import { filterDomProps } from '../../utils'

const SurveyTypeDropdown = ({
  label,
  defaultValue = SurveyTypes['NONE'],
  value,
  t,
  ...rest
}) => {
  return (
    <TextField
      select
      label={label}
      defaultValue={defaultValue}
      value={value}
      {...filterDomProps(rest)}
    >
      {Object.keys(SurveyTypes).map((surveyType) => (
        <MenuItem value={SurveyTypes[surveyType]} key={surveyType}>
          {t(`components:surveyTypeDropdown.${surveyType}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}

SurveyTypeDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
}

export default withTranslation(['components'])(SurveyTypeDropdown)
