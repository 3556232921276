import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { TableRowSkeleton, TableNoData, QRCodeIcon } from '../atoms'
import { BadgePillowText } from '../atoms/BadgePillow'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import {
  Badge,
  TableRow,
  Grid,
  Checkbox,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { DEFAULT_PAGINATION_OPTIONS } from '../../constants'
import { withTranslation } from 'react-i18next'
import ModalBox from '../organisms/ModalBox'
import QRCodeComponent from './QRCodeComponent'

export const availableColumns = Object.freeze({
  CHECKBOX: 'checkbox',
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  GENDER: 'gender',
  ID: 'id',
  ROLES: 'roles',
  STATUS: 'status',
  ACTIONS: 'actions',
})

const avatarLetter = (firstName, lastName, alias) =>
  (firstName || lastName || alias || '-').charAt(0).toUpperCase()

const fullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName) {
    return firstName
  }
  if (lastName) {
    return lastName
  }
  return '(none provided)'
}

const renderCellContents = (
  row,
  columnName,
  t,
  selectedIdsList,
  checkboxOnChange,
  actionCallback = () => {},
) => {
  switch (columnName) {
    case availableColumns.CHECKBOX:
      return (
        <Checkbox
          checked={selectedIdsList.includes(row.id)}
          value={row.id}
          onChange={checkboxOnChange}
        />
      )
    case availableColumns.FULL_NAME:
      return (
        <Grid container alignItems="center">
          <Avatar color="">
            {avatarLetter(row.first_name, row.last_name, row.alias)}
          </Avatar>
          <div style={{ paddingLeft: '16px' }}>
            {fullName(row.first_name, row.last_name)}
          </div>
        </Grid>
      )
    case availableColumns.ROLES:
      return (
        row.roles &&
        row.roles.map((role) => (
          <BadgePillowText key={role.id} color="lightblue" text={role.name} />
        ))
      )
    case availableColumns.STATUS:
      return row.active ? (
        <BadgePillowText
          color="green"
          text={t('states:active').toUpperCase()}
        />
      ) : (
        <BadgePillowText
          color="red"
          text={t('states:notActive').toUpperCase()}
        />
      )
    case availableColumns.GENDER:
      return row.sex ? row.sex.toUpperCase() : ''
    case availableColumns.ACTIONS:
      return (
        row.qr_code && (
          <Tooltip
            title={t('components:usersTable.actions.showQrCode.tooltip')}
          >
            <IconButton
              onClick={() => actionCallback(row.alias, row.pin, row.qr_code)}
            >
              <Badge color="primary" badgeContent={'QR'}>
                <QRCodeIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )
      )
    default:
      return row[columnName]
  }
}

const UsersTable = ({
  usersList,
  selectedIdsList = [],
  checkboxOnChange = () => {},
  fetching,
  pagination,
  onChangeRowsPerPage: changeRowsPerPageHandler,
  onChangePage: changePageHandler,
  error,
  t,
  columnsToDisplay,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [qrComponentData, setQrComponentData] = useState(['', '', ''])

  let tableRows = null

  const handleQRCodePreview = () => (alias, pin, qrCodeValue) => {
    setQrComponentData([alias, pin, qrCodeValue])
    setModalOpen(true)
  }

  if (
    (fetching && usersList.length === 0) ||
    (fetching && usersList.length > 0)
  ) {
    tableRows = (
      <TableRowSkeleton
        cells={columnsToDisplay.length}
        rows={pagination.rowsPerPage}
      />
    )
  }

  if (!fetching && error === 0) {
    tableRows = (
      <TableRow>
        <TableCell align="center" colSpan={columnsToDisplay.length}>
          {t('errors:failedToLoadData')}
        </TableCell>
      </TableRow>
    )
  }

  if (!fetching && usersList.length === 0) {
    tableRows = <TableNoData cols={columnsToDisplay.length} />
  }

  if (!fetching && usersList.length > 0) {
    tableRows = usersList.map((row) => (
      <TableRow key={row.id}>
        {columnsToDisplay.map((columnName) => (
          <TableCell key={columnName}>
            {renderCellContents(
              row,
              columnName,
              t,
              selectedIdsList,
              checkboxOnChange,
              Object.prototype.hasOwnProperty.call(row, 'qr_code') &&
                row.qr_code !== null
                ? handleQRCodePreview()
                : () => {},
            )}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <>
      <ModalBox
        modalTitle={t('components:usersTable.modal.title', {
          alias: qrComponentData[0],
        })}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
      >
        <QRCodeComponent
          alias={qrComponentData[0]}
          pin={qrComponentData[1]}
          value={qrComponentData[2]}
        />
      </ModalBox>
      <TableContainer
        component={Paper}
        style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columnsToDisplay.map((val, i) => (
                <TableCell key={i}>
                  {t(`components:usersTable.tableHeaders.${val}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions}
          component="div"
          count={pagination.total || -1}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.currentPage}
          onChangePage={(e, page) => changePageHandler(page, pagination)}
          onChangeRowsPerPage={(e) => changeRowsPerPageHandler(e, pagination)}
        />
      </TableContainer>
    </>
  )
}

UsersTable.propTypes = {
  columnsToDisplay: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(availableColumns)),
  ),
  selectedIdsList: PropTypes.array,
  checkboxOnChange: PropTypes.func,
  usersList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['components'])(UsersTable)
