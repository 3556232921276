import config from '../utils/config'

export const ENVIRONMENTS = Object.freeze({
  local: 'local',
  development: 'development',
  production: 'production',
})

export const setEnvironment = (env) => {
  if (!ENVIRONMENTS[env]) {
    throw new Error(
      `Unknown env, existing: ${Object.keys(ENVIRONMENTS).join(', ')}`,
    )
  }
  window.ENV = env

  console.info('setEnvironment', env)
  config.setEnv(env)
}

window.setEnvironment = (env) => {
  setEnvironment(env)
  window.location.reload()
}

export const whConsole = (text, result, obj) => {
  if (ENV === 'local' || ENV === 'development') {
    if (!obj) {
      console.log(text, result)
    } else if (obj.type === 'table') {
      console.table(result)
    }
  }
}

const getEnv = () => {
  const localStorageEnv = config.getEnv()
  if (localStorageEnv) {
    return localStorageEnv
  }

  const processEnv = process.env.REACT_APP_ENV
  config.setEnv(processEnv)
  return processEnv
}

export const ENV = getEnv()
window.ENV = `${process.env.REACT_APP_ENV}` || ENV

console.info('ENV:', ENV)
