import React from 'react'
import PropTypes from 'prop-types'

import Grow from '@material-ui/core/Grow'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import { AlertTypes } from '../../constants'

const useStyles = makeStyles({
  bar: {
    left: '288px',
    width: 'calc(100% - 312px) ',
    transform: 'inherit',
    display: 'block',
  },
})

const BarAlert = ({
  alertType = AlertTypes.INFO,
  alertBody = '',
  autoHideDuration = 4680,
  children,
  handleClose,
  open,
  t,
}) => {
  const classes = useStyles()

  return (
    <>
      <Snackbar
        className={classes.bar}
        elevation={6}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Alert
          severity={alertType.toLowerCase()}
          variant="filled"
          elevation={20}
        >
          <AlertTitle>
            <strong>{t(`barAlert.title.${alertType.toLowerCase()}`)}</strong>
          </AlertTitle>
          {alertBody}
          {children}
        </Alert>
      </Snackbar>
    </>
  )
}

BarAlert.propTypes = {
  alertBody: PropTypes.string,
  alertType: PropTypes.oneOf(Object.values(AlertTypes)),
  autoHideDuration: PropTypes.number,
  children: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['components'])(BarAlert)
