import { createAction } from 'redux-actions'
import { UIActionTypes } from './ui.types'

export const expandLeftSidebar = createAction(UIActionTypes.EXPAND_LEFT_SIDEBAR)

export const collapseLeftSidebar = createAction(
  UIActionTypes.COLLAPSE_LEFT_SIDEBAR,
)

export const openBarAlert = (barAlertContent) => ({
  type: UIActionTypes.OPEN_BAR_ALERT,
  payload: barAlertContent,
})

export const closeBarAlert = createAction(UIActionTypes.CLOSE_BAR_ALERT)
