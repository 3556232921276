import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Form, Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Box, Grid, withStyles } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'

import {
  FormOutlinedTextField,
  SurveyTypeDropdownField,
} from '../../../molecules'
import { InfoIcon } from '../../../atoms/Icons'
import { required } from '../../../../utils'
import { CREATE_SURVEY_FORM_ID } from '../../../../constants'

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formField: {
    marginBottom: '16px',
  },
  informationBox: {
    borderLeft: '5px solid #9b57cf',
    backgroundColor: 'rgba(155, 87, 207, 0.2)',
    padding: '8px 12px',
    borderRadius: '4px',
    marginBottom: '12px',
  },
  informationIcon: {
    color: '#9b57cf',
  },
  icon: {
    fontSize: '4rem',
    '&.green': {
      color: green[500],
    },
    '&.red': {
      color: red[500],
    },
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
})

const CreateSurveyForm = (props) => {
  const { classes, handleSubmit, t, submitting } = props

  const inputDisabled = submitting

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        disabled={inputDisabled}
        name="name"
        type="text"
        component={FormOutlinedTextField}
        label={t('surveys:form.name.label')}
        validate={[required]}
        required
      />
      <Box component="span" display="block" className={classes.informationBox}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <InfoIcon className={classes.informationIcon} />{' '}
          </Grid>
          <Grid item xs={11}>
            {t('tasks:form.messages.googleForm')}
          </Grid>
        </Grid>
      </Box>
      <Field
        disabled={inputDisabled}
        name="gf_url"
        type="text"
        component={FormOutlinedTextField}
        label={t('surveys:form.url.label')}
        validate={[required]}
        required
      />
      <Field
        disabled={submitting}
        label={t('surveys:form.type.label')}
        name="type"
        component={SurveyTypeDropdownField}
        type="select"
      />
    </Form>
  )
}

CreateSurveyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  withTranslation(['surveys']),
  withStyles(styles),
  reduxForm({
    form: CREATE_SURVEY_FORM_ID,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  }),
)(CreateSurveyForm)
