import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Form, Field, reduxForm, formValueSelector } from 'redux-form'
import { FormOutlinedTextField } from '../../../../molecules'
import { withTranslation } from 'react-i18next'
import { CREATE_TOOL_FORM_ID } from '../../../../../constants'
import { InputAdornment, IconButton, Link, withStyles } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'

const styles = (theme) => ({
  form: {
    marginTop: '12px',
  },
  'checkIcon-valid': {
    color: theme.palette.green['500'],
  },
  checkIcon: {
    color: theme.palette.text.disabled,
  },
})

class CreateToolForm extends Component {
  render() {
    const {
      classes,
      t,
      handleSubmit,
      submitting,
      toolName,
      showToolInput,
      onShowToolInput: handleShowToolInput,
    } = this.props

    return (
      <Form className={classes.form} onSubmit={handleSubmit}>
        {showToolInput && (
          <Field
            disabled={submitting}
            label={t('tools:form.name.label')}
            name="name"
            component={FormOutlinedTextField}
            type="text"
            helperText={t('tools:form.name.helper')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add tool"
                    onClick={handleSubmit}
                    edge="end"
                  >
                    <CheckCircleOutline
                      className={
                        !toolName
                          ? classes.checkIcon
                          : classes['checkIcon-valid']
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {!showToolInput && (
          <Link
            component="button"
            type="submit"
            variant="body1"
            onClick={handleShowToolInput}
          >
            {t('tools:form.button.addMore.label')}
          </Link>
        )}
      </Form>
    )
  }
}

const selector = formValueSelector(CREATE_TOOL_FORM_ID)
const mapStateToProps = (state) => ({
  toolName: selector(state, 'name'),
})

CreateToolForm.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toolName: PropTypes.string,
  showToolInput: PropTypes.bool.isRequired,
  onShowToolInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  withTranslation(['tools']),
  withStyles(styles),
  reduxForm({
    form: CREATE_TOOL_FORM_ID,
  }),
  connect(mapStateToProps, null),
)(CreateToolForm)
