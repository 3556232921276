import dayjs from 'dayjs'
import {
  chartConst,
  chartDatasetProps,
  chartColors,
} from '../constants/ChartBoundaries'
import { DEFAULT_TIME_FORMAT } from './config'

export const getMinutesFromStringTzOffset = (tzOffset) => {
  const [hours, minutes] = tzOffset.split(':')
  const minutesFromTz =
    Math.abs(parseInt(hours, 10)) * 60 + parseInt(minutes, 10)

  return hours.includes('-') ? -1 * minutesFromTz : minutesFromTz
}

export const formatRawData = (rawData) => {
  let formattedData = []
  let formattedLabels = []
  let formattedTime = []

  /* Sample date from API response
      "08:35:00": 1.0, "13:15:00": 1.0,
      "09:55:00": 3.0, "09:25:00": 2.0,
  */

  // Get timestamps as labels
  for (let k in rawData) {
    if (Object.prototype.hasOwnProperty.call(rawData, k)) {
      formattedLabels.push(k)
    }
  }

  // Sort timestamps
  formattedLabels.sort()

  // Get values sorted by time
  for (let i = 0; i < formattedLabels.length; i++) {
    if (
      typeof rawData[formattedLabels[i]] === 'object' &&
      rawData[formattedLabels[i]] !== null
    ) {
      if (rawData[formattedLabels[i]].v > 0) {
        formattedData.push(rawData[formattedLabels[i]].v)
      } else {
        // We don't draw null values
        formattedData.push(null)
      }
    } else {
      if (rawData[formattedLabels[i]] > 0) {
        formattedData.push(rawData[formattedLabels[i]])
      } else {
        // We don't draw null values
        formattedData.push(null)
      }
    }
  }

  // Format timestamps
  for (let i = 0; i < formattedLabels.length; i++) {
    formattedTime.push({
      time: formattedLabels[i],
      hour: formattedLabels[i].slice(0, -3),
    })
  }

  return {
    labels: formattedLabels,
    data: formattedData,
    dataTime: formattedTime,
  }
}

const parseMotionMetric = (metrics, tzOffset) => {
  const tzOffsetInMinutes = getMinutesFromStringTzOffset(tzOffset)

  // add local time attributes to the metrics
  metrics.signal_lengths = metrics.signal_lengths.map((signal) => ({
    ...signal,
    end_local: dayjs(signal.end_utc)
      .add(tzOffsetInMinutes, 'minute')
      .format(`${DEFAULT_TIME_FORMAT} HH:mm:ss`),
    start_local: dayjs(signal.start_utc)
      .add(tzOffsetInMinutes, 'minute')
      .format(`${DEFAULT_TIME_FORMAT} HH:mm:ss`),
  }))

  // sort the metrics by start_local
  metrics.signal_lengths.sort(
    (a, b) => dayjs(a.start_local) - dayjs(b.start_local),
  )

  // add NaN signal lengths for gaps greater than 1 minute
  metrics.signal_lengths.map((signal, index) => {
    if (index > 0) {
      const prevSignal = metrics.signal_lengths[index - 1]
      const prevEndTime = dayjs(prevSignal.end_local)
      const currStartTime = dayjs(signal.start_local)
      if (currStartTime - prevEndTime > 1000 * 60) {
        metrics.signal_lengths.splice(index, 0, {
          start_local: prevEndTime.format(`${DEFAULT_TIME_FORMAT} HH:mm:ss`),
          end_local: currStartTime.format(`${DEFAULT_TIME_FORMAT} HH:mm:ss`),
          quality_score: NaN,
        })
      }
    }
  })

  return metrics
}

/**
 * Example data:
 * "duration": 660.00,
 * "quality_score": 1,
 * "sensor_position": "UPPER_ARM_LEFT",
 * "signal_lengths": [
 *  {
 *    start_utc: '08:00:00',
 *    end_utc: '09:00:00',
 *    quality_score: 1,
 *  },
 * ...]
 *
 * @param {*} data
 * @returns {*} datasets
 */
export const formatMotionMetricsData = (data, tzOffset) => {
  let datasets = []

  const defaultProperties = {
    label: 'UNKNOWN',
    data: [],
  }

  data.map((metric) => {
    metric = parseMotionMetric(metric, tzOffset)

    datasets.push({
      ...defaultProperties,
      duration: metric.duration,
      quality_score: metric.quality_score,
      label: metric.sensor_position,
      data: metric.signal_lengths
        .map((signal) => [
          {
            t: dayjs(signal.start_local).format(
              `${DEFAULT_TIME_FORMAT} HH:mm:ss`,
            ),
            y: signal.quality_score,
          },
          {
            t: dayjs(signal.end_local).format(
              `${DEFAULT_TIME_FORMAT} HH:mm:ss`,
            ),
            y: signal.quality_score,
          },
        ])
        .flat(),
    })
  })

  return datasets
}

// Group data by ranges
// TODO re-check if ranges are correct @Andrea
export const groupData = (formattedData, formatting) => {
  const REST_BOUND = 1.9
  const LOW_BOUND = 3.9
  const MODERATE_BOUND = 4.9
  const HIGH_BOUND = 7.9
  const timestampsNumber = []
  const DATA = formattedData.data

  let groupedData = {}

  const pushData = (dataPoint, boundary) => {
    const boundaries = Object.keys(groupedData)

    for (let i = 0; i < boundaries.length; i++) {
      if (Object.keys(groupedData)[i].includes(boundary)) {
        groupedData[boundaries[i]].push(dataPoint)
      } else {
        groupedData[boundaries[i]].push(null)
      }
    }
  }

  // PHYSICAL LOAD
  if (formatting === 'physical') {
    groupedData[chartConst.B_REST] = []
    groupedData[chartConst.B_LOW] = []
    groupedData[chartConst.B_MODERATE] = []
    groupedData[chartConst.B_HIGH] = []
    groupedData[chartConst.B_VERY_HIGH] = []

    // format data for physical load
    for (let i = 0; i < DATA.length; i++) {
      timestampsNumber.push(i)

      if (DATA[i] <= REST_BOUND) {
        pushData(DATA[i], chartConst.B_REST)
      }

      if (DATA[i] > REST_BOUND && DATA[i] <= LOW_BOUND) {
        pushData(DATA[i], chartConst.B_LOW)
      }

      if (DATA[i] > LOW_BOUND && DATA[i] <= MODERATE_BOUND) {
        pushData(DATA[i], chartConst.B_MODERATE)
      }
      if (DATA[i] > MODERATE_BOUND && DATA[i] <= HIGH_BOUND) {
        pushData(DATA[i], chartConst.B_HIGH)
      }
      if (DATA[i] > HIGH_BOUND) {
        pushData(DATA[i], chartConst.B_VERY_HIGH)
      }
    }

    return {
      data: groupedData,
      labels: timestampsNumber,
      timeLabels: formattedData.dataTime,
    }
  }

  // MENTAL LOAD OR WORKLOAD
  if (formatting === 'mental' || formatting === 'workload') {
    groupedData[chartConst.B_LOW] = []
    groupedData[chartConst.B_MODERATE] = []
    groupedData[chartConst.B_VERY_HIGH] = []

    // format data for mental load
    for (let i = 0; i < DATA.length; i++) {
      timestampsNumber.push(i)

      if (DATA[i] <= LOW_BOUND) {
        pushData(DATA[i], chartConst.B_LOW)
      }

      if (DATA[i] > LOW_BOUND && DATA[i] <= HIGH_BOUND) {
        pushData(DATA[i], chartConst.B_MODERATE)
      }

      if (DATA[i] > HIGH_BOUND) {
        pushData(DATA[i], chartConst.B_VERY_HIGH)
      }
    }

    return {
      data: groupedData,
      labels: timestampsNumber,
      timeLabels: formattedData.dataTime,
    }
  }
}

export const mapColor = (name) => {
  switch (name) {
    case chartConst.B_REST:
      return 'lightblue'
    case chartConst.B_LOW:
      return 'green'
    case chartConst.B_MODERATE:
      return 'yellow'
    case chartConst.B_HIGH:
      return 'orange'
    case chartConst.B_VERY_HIGH:
      return 'red'
    default:
      break
  }
}

export const RGBa = (rgb, alpha) => `rgba(${rgb},${alpha})`

export const mapGradients = (name, chart, height, stopModifier = {}) => {
  // ChartColors = CColors
  let CColors = {
    area: null,
    stroke: null,
  }

  const { green, yellow, red, orange, blue, electric, pink } = chartColors

  // each color canvas value needs own calibration by adding or
  // deducting to the height of the chart
  switch (name) {
    case chartConst.B_REST:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 35)
      CColors.area.addColorStop(
        stopModifier[chartConst.B_REST] || 0.88,
        RGBa(blue[0], 0.7),
      )
      CColors.area.addColorStop(1, RGBa(blue[0], 0.1))
      CColors.stroke = RGBa(blue[0], 1)
      break
    case chartConst.B_LOW:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 40)
      CColors.area.addColorStop(0.78, RGBa(green[0], 0.7))
      CColors.area.addColorStop(1, RGBa(green[0], 0.15))
      CColors.stroke = RGBa(green[0], 1)
      break
    case chartConst.B_MODERATE:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 40)
      CColors.area.addColorStop(0.78, RGBa(yellow[0], 0.7))
      CColors.area.addColorStop(1, RGBa(yellow[0], 0.1))
      CColors.stroke = RGBa(yellow[0], 1)
      break
    case chartConst.B_HIGH:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 40)
      CColors.area.addColorStop(0.58, RGBa(orange[0], 0.7))
      CColors.area.addColorStop(1, RGBa(orange[0], 0.1))
      CColors.stroke = RGBa(orange[0], 1)
      break
    case chartConst.B_VERY_HIGH:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 40)
      CColors.area.addColorStop(0.38, RGBa(red[0], 0.8))
      CColors.area.addColorStop(1, RGBa(red[0], 0.1))
      CColors.stroke = RGBa(red[0], 1)
      break
    case chartConst.D_SENSOR_0:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 35)
      CColors.area.addColorStop(0.3, RGBa(electric[0], 0.7))
      CColors.area.addColorStop(1, RGBa(electric[0], 0.1))
      CColors.stroke = RGBa(electric[0], 1)
      break
    case chartConst.D_SENSOR_1:
      CColors.area = chart.createLinearGradient(0, 0, 0, height - 35)
      CColors.area.addColorStop(0.3, RGBa(pink[0], 0.7))
      CColors.area.addColorStop(1, RGBa(pink[0], 0.1))
      CColors.stroke = RGBa(pink[0], 1)
      break
    default:
      break
  }

  return CColors
}

export const datasetMapper = (data) => {
  const dataset = Object.keys(data).map((name) => {
    if (data[name].length > 0) {
      return {
        label: name,
        data: data[name],
        backgroundColor: mapColor(name),
        ...chartDatasetProps.barChart,
      }
    } else {
      delete data[name]
      return null
    }
  })

  if (dataset.every((item) => item === null)) {
    return []
  }

  return dataset
}

/**
 * Format data for calendar view, convert string to numbers and
 * find min and max date for calnendar dimension
 * @param {Array} data [{day: "2020-03-21", value: "1.2" } ...]
 * @returns {Object}
 */
export const formatCalendarData = (data) => {
  if (data.length === 0) {
    return { data: [], maxDate: null, minDate: null }
  } else {
    let dates = []

    data.forEach((el) => {
      el.value = Number((+el.value).toFixed(1))
      dates.push(new Date(el.day))
    })

    const maxDate = dayjs(Math.max.apply(null, dates)).format(
      DEFAULT_TIME_FORMAT,
    )
    const minDate = dayjs(Math.min.apply(null, dates)).format(
      DEFAULT_TIME_FORMAT,
    )

    return { data: data, maxDate: maxDate, minDate: minDate }
  }
}

/**
 * Converts string number to rounded number
 * @param {String} num "35.3434"
 * @param {Number} rounding default rounding of numbers
 * @returns {Num} 34.4
 */
export const toNum = (num, rounding = 1) => +(+num).toFixed(rounding)

/**
 * Converts thousands 1000000 to 1.000.000
 * @param {Number} num 10000
 * @returns {String} 1.000.000
 */
export const formatThousand = (num) => {
  let parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return parts.join(',')
}

/**
 * Convert numbers to percent
 * @param {Number} x 3
 * @param {Number} y 5
 * @returns {Number}
 */
export const formatPercentage = (x, y) => (((x - y) / y) * 100).toFixed(0)

/**
 * Calculates average from array of numbers
 * @param {Array} data [2,3,4,5,6..]
 * @returns {Number}
 */
export const sumAverage = (arr) =>
  (arr.reduce((p, c) => p + c, 0) / arr.length).toFixed(1)

/**
 * Format UTC offset +02:00 => +02
 * @param {String} offset +02:00
 * @returns {String}
 */
export const formatUTC = (offset) => {
  const hours = offset.split(':')[0]
  const sign = hours.charAt(0)
  const numbers = parseInt(hours.charAt(1) + hours.charAt(2))

  return `${sign}${numbers}`
}

export const normalizeDateTimeInput = (value) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 4) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4)}`
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
      6,
    )}`
  }
  if (onlyNums.length <= 10) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
      6,
      8,
    )} ${onlyNums.slice(8)}`
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
      6,
      8,
    )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10)}`
  }
  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
    6,
    8,
  )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10, 12)}`
}

export const normalizeTzOffset = (value) => {
  // allow plus or minus characters
  const onlyNums = value.replace(/[^\d+-]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}:${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}:${onlyNums.slice(3, 5)}`
}
