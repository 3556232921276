import React from 'react'
import PropTypes from 'prop-types'
// import colors
import p from '../themes/styles/palette'
// import styles
import { makeStyles } from '@material-ui/core/styles'
import { semanticColorFromValue } from '../../utils/ui-fns'
import { Skeleton } from '@material-ui/lab'

const { cyan, orange, green, red, yellow } = p

const useStyles = makeStyles({
  pillowSize: {
    padding: '4px 10px',
    minWidth: '64px',
    fontWeight: 'bold',
    borderRadius: '16px',
  },

  pillowText: {
    fontFamily: 'Maven Pro',
    padding: '4px 16px',
    minWidth: '64px',
    fontWeight: 'bold',
    letterSpacing: '0.025rem',
    fontSize: '13px',
    borderRadius: '16px',
  },
  root: {
    display: 'inline-block',
    textAlign: 'center',

    '&.lightblue': {
      background: cyan[50],
      color: cyan[900],
    },
    '&.green': {
      background: green[50],
      color: green[900],
    },
    '&.yellow': {
      background: yellow[100],
      color: yellow[800],
    },

    '&.darkorange': {
      background: orange[50],
      color: orange[700],
    },
    '&.red': {
      background: red[50],
      color: red['A200'],
    },
  },
})

const BadgePillow = ({ value, scale, ...rest }) => {
  const classes = useStyles()

  // convert string to number, API returns string
  if (!value && value !== 0) return <Skeleton animation="wave" variant="text" />

  if (!isNaN(value)) {
    value = +value
  }

  const valueColor = [
    classes.root,
    semanticColorFromValue(value, rest.color, scale),
  ].join(' ')

  return (
    <span
      className={[valueColor, rest.className || classes.pillowSize].join(' ')}
    >
      {rest.localizedString ? rest.localizedString : value}
    </span>
  )
}

BadgePillow.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scale: PropTypes.string,
}

export default BadgePillow

export const BadgePillowText = ({ color, text }) => {
  const classes = useStyles()

  return (
    <span className={[classes.root, color, classes.pillowText].join(' ')}>
      {text}
    </span>
  )
}

BadgePillowText.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
}
