import { ReportsActionTypes } from './reports.types'

export const toggleDateMenu = (data) => ({
  type: ReportsActionTypes.TOGGLE_DATE_MENU,
  payload: data,
})

export const clearFilters = () => ({
  type: ReportsActionTypes.CLEAR_FILTERS,
})

export const setReportName = (data) => ({
  type: ReportsActionTypes.SET_NAME,
  payload: data,
})

export const isSearching = () => ({
  type: ReportsActionTypes.IS_SEARCHING,
})

export const setDate = (data) => ({
  type: ReportsActionTypes.SET_DATE,
  payload: data,
})

export const setPredefinedDate = (data) => ({
  type: ReportsActionTypes.SET_PREDEFINED_DATE,
  payload: data,
})

export const setReportGroup = (data) => ({
  type: ReportsActionTypes.SET_GROUP,
  payload: data,
})
