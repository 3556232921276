import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { SubmissionError } from 'redux-form'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { RemoteSubmitButton } from '../../../../atoms'
import CreateWorklogEntryForm from '../CreateWorklogEntryForm'
import {
  fetchWorklogEntry,
  updateWorklogEntry,
} from '../../../../../api/api.worklog'
import {
  AppTestIds,
  UPDATE_WORKLOG_ENTRY_FORM_ID,
} from '../../../../../constants'
import { getMinutesFromStringTzOffset } from '../../../../../utils/data-formatter'

const styles = () => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
  deleteButton: {
    height: 'auto',
  },
  remoteSubmitButton: {
    height: 'auto',
  },
})

class WorklogDetailCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    handleOpenDeleteDialog: PropTypes.func.isRequired,
  }

  state = {
    worklogEntry: null,
  }

  async componentDidMount() {
    this.fetchWorklogEntry()
  }

  async fetchWorklogEntry() {
    const {
      match: {
        params: { worklogId },
      },
    } = this.props

    const rootResponse = await fetchWorklogEntry(worklogId)

    this.setState({ worklogEntry: rootResponse.response })
  }

  submitUpdateWorklogEntryForm = async (values) => {
    const {
      match: {
        params: { worklogId },
      },
    } = this.props

    const rootResponse = await updateWorklogEntry(worklogId, values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'WORKLOG_UPDATED'
    ) {
      this.fetchWorklogEntry()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const { classes, t, handleOpenDeleteDialog } = this.props
    const { worklogEntry } = this.state

    return (
      <Card>
        <CardHeader
          title={t('worklog:detail.detailsCard.title')}
          subheader={t('worklog:detail.detailsCard.subtitle', {
            date: worklogEntry ? worklogEntry.updated_at : '...',
          })}
        />
        <CardContent>
          <CreateWorklogEntryForm
            form={UPDATE_WORKLOG_ENTRY_FORM_ID}
            onSubmit={this.submitUpdateWorklogEntryForm}
            initialValues={
              worklogEntry && {
                ...worklogEntry,
                ...(worklogEntry.tools != null &&
                  worklogEntry.task.tools != null &&
                  worklogEntry.tools.length > 2 && {
                    tools: JSON.parse(worklogEntry.task.tools).map((tool) => ({
                      ...tool,
                      selected: JSON.parse(worklogEntry.tools)[tool.id],
                    })),
                  }),
                start_time: dayjs(worklogEntry.start_time.substring(0, 16))
                  .add(
                    getMinutesFromStringTzOffset(worklogEntry.utc_offset),
                    'minute',
                  )
                  .format('YYYY-MM-DD HH:mm'),
                end_time: dayjs(worklogEntry.end_time.substring(0, 16))
                  .add(
                    getMinutesFromStringTzOffset(worklogEntry.utc_offset),
                    'minute',
                  )
                  .format('YYYY-MM-DD HH:mm'),
                task_id: worklogEntry.task.id,
                tz_offset: worklogEntry.utc_offset,
              }
            }
            handleDeleteTool={this.submitDeleteTool}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.deleteButton}
            variant="contained"
            component="button"
            color="secondary"
            onClick={handleOpenDeleteDialog}
          >
            {t('worklog:detail.button.delete.label')}
          </Button>
          <RemoteSubmitButton
            appTestId={AppTestIds.Tasks.UpdateButton}
            className={classes.remoteSubmitButton}
            formId={UPDATE_WORKLOG_ENTRY_FORM_ID}
            buttonLabel={'update'}
          />
        </CardActions>
      </Card>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['worklog']),
  withRouter,
)(WorklogDetailCard)
