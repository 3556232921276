import React from 'react'
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ModalBox from '../organisms/ModalBox'
import errorIllustration from '../../images/app-error-illustration-blue.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  illustration: {
    maxWidth: '90%',
    margin: '2rem auto 2rem',
    display: 'block',
    position: 'relative',
    left: '0%',
  },
  button: {
    display: 'block',
    margin: '2rem auto 1.5rem',
  },
}))

const ErrorPage = (props) => {
  const handleReloadPage = () => {
    window.location.reload()
  }

  const classes = useStyles()

  return (
    <>
      <ModalBox className={classes.root} open type="error" noHeader>
        <>
          <img
            src={errorIllustration}
            className={classes.illustration}
            alt="WearHealth"
          />
          <Typography variant="h4" align="center" gutterBottom>
            Oops!
          </Typography>
          <br />
          <Typography variant="body1" align="center" gutterBottom>
            We strive for enhancing safety and health at your workplace
            sometimes however the digital world is more unforgiving
          </Typography>
          <br />
          <Typography variant="body1" align="center" gutterBottom>
            Our development team has been notified of this digital accident.
            <br />
            Please refresh the page and try again.
          </Typography>

          <Button
            onClick={handleReloadPage}
            variant="contained"
            color="primary"
            disableElevation
            className={classes.button}
          >
            Reload page
          </Button>
        </>
      </ModalBox>
    </>
  )
}

export default ErrorPage
