import { ToolsActionTypes } from './tools.types'
import { Endpoints } from '../../constants'
import { fetchWithErrors, withParams } from '../../utils'

export const fetchToolsListStart = () => ({
  type: ToolsActionTypes.FETCH_TOOLS_START,
})

export const fetchToolsListSuccess = (responseJSON) => ({
  type: ToolsActionTypes.FETCH_TOOLS_SUCCESS,
  payload: responseJSON,
})

export const fetchToolsListFailure = (errorMessagge) => ({
  type: ToolsActionTypes.FETCH_TOOLS_FAILURE,
  payload: errorMessagge,
})

// Default fetch action
export const fetchToolsList = (params) => async (dispatch) =>
  toolsListFetcher(dispatch, params)

const toolsListFetcher = async (dispatch, params) => {
  dispatch(fetchToolsListStart())

  const response = await fetchWithErrors(
    withParams(Endpoints.Tools.Urls.List, params),
  )

  if (!response.error) {
    dispatch(fetchToolsListSuccess(response.response))
  } else {
    dispatch(fetchToolsListFailure(response.message))
  }
}
