import { SurveysActionTypes } from './surveys.types'
import { Endpoints } from '../../constants'
import { fetchWithErrors, withParams } from '../../utils'

export const fetchSurveysListStart = () => ({
  type: SurveysActionTypes.FETCH_SURVEYS_START,
})

export const fetchSurveysListSuccess = (responseJSON) => ({
  type: SurveysActionTypes.FETCH_SURVEYS_SUCCESS,
  payload: responseJSON,
})

export const fetchSurveysListFailure = (errorMessagge) => ({
  type: SurveysActionTypes.FETCH_SURVEYS_FAILURE,
  payload: errorMessagge,
})

// Default fetch action
// Default values for onload are 10 and 0, 10
export const fetchSurveysListStartAsync = () => {
  return async (dispatch) => {
    surveysListFetcher(dispatch, { rowsPerPage: 10, currentPage: 0 })
  }
}

// Change rows per page
// When changing results to display the page needs start from 0
export const changeRowsPerPage = (e, currentPagination) => {
  const rowsPerPage = e.target.value

  return async (dispatch) => {
    surveysListFetcher(dispatch, { rowsPerPage: rowsPerPage, currentPage: 0 })
  }
}

// Change page
export const changePage = (page, pagination) => {
  return async (dispatch) => {
    surveysListFetcher(dispatch, {
      rowsPerPage: pagination.rowsPerPage,
      currentPage: page,
    })
  }
}

const surveysListFetcher = async (dispatch, pagination) => {
  dispatch(fetchSurveysListStart())

  const response = await fetchWithErrors(
    withParams(Endpoints.Surveys.Urls.List, {
      per_page: pagination.rowsPerPage,
      page: pagination.currentPage + 1,
    }),
  )

  if (!response.error) {
    dispatch(fetchSurveysListSuccess(response.response))
  } else {
    dispatch(fetchSurveysListFailure(response.message))
  }
}
