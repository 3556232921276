import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import { fade, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import { AppTestIds } from '../../constants'

const styles = (theme) => ({
  textField: {
    minWidth: '304px',
  },
  inputRoot: {
    color: '#3F536E',
    fontWeight: '500',
    fontSize: '16px',
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    left: '-4px',
    top: '0px',
    opacity: '.54',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  neutralButton: {
    position: 'relative',
    padding: '6px 16px 6px 36px',
    borderRadius: '4px',
    height: '48px',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
    background: 'linear-gradient(#fff 80%, #c5d9e8 180%)',
    border: '1px solid #c5d9e8',
    color: '#3F536E',
    '&:hover': {
      background: '#cecece',
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
})

const SearchInput = ({
  classes,
  isFetching,
  isFocused,
  onInputFocusBlur,
  onSearchChange,
  placeholderDefault,
  searchVal,
}) => {
  return (
    <div className={classes.neutralButton}>
      <div className={classes.searchIcon}>
        {isFetching && isFocused ? (
          <CircularProgress size="24px" color="primary" />
        ) : (
          <SearchIcon style={{ color: '' }} />
        )}
      </div>
      <InputBase
        app-testid={AppTestIds.Filters.Search}
        key={searchVal}
        defaultValue={searchVal}
        className={classes.inputRoot}
        placeholder={placeholderDefault}
        onChange={(e) => onSearchChange(e)}
        onFocus={onInputFocusBlur}
        onBlur={onInputFocusBlur}
      />
    </div>
  )
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  isFocused: PropTypes.bool,
  onInputFocusBlur: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  placeholderDefault: PropTypes.string,
  searchVal: PropTypes.string.isRequired,
}

const styled = withStyles(styles)(SearchInput)

export default styled
