import { DashboardActionTypes } from './dashboard.types'

const INITIAL_STATE = {
  groupCode: 'all',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.SET_GROUP: {
      return {
        ...state,
        groupCode: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
