import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import red from '@material-ui/core/colors/red'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  closeIconWhite: {
    float: 'right',
    color: 'rgba(255,255,255,.54)',
    background: 'rgba(255,255,255,.14)',
    '&:hover': {
      transition: 'all .25s ease-in-out',
      color: 'rgba(255,255,255,.87)',
      background: 'rgba(255,255,255,.27)',
    },
  },
  closeIcon: {
    float: 'right',
    color: 'rgba(0,0,0,.54)',
    fontSize: '18px',
    background: 'transparent',
    '&:hover': {
      transition: 'all .25s ease-in-out',
      color: 'rgba(0,0,0,.87)',
      background: 'rgba(0,0,0,.27)',
    },
  },
  header: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    color: '#fff',
    background:
      'linear-gradient(135deg, rgba(124,196,250,1) -40%, rgba(5,82,181,1) 100%);',
  },
  error: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    color: '#fff',
    background: `linear-gradient(135deg, ${red[800]} -40%, ${red[400]} 100%);`,
  },
})

const ModalDialogTitle = ({ title, modalBoxStyle, handleClose }) => {
  const classes = useStyles()

  if (modalBoxStyle === 'error') {
    return (
      <MuiDialogTitle className={classes.error} disableTypography>
        <Typography variant="h6">{title}</Typography>
      </MuiDialogTitle>
    )
  }

  return (
    <MuiDialogTitle className={''} disableTypography>
      <Typography variant="h6">
        {title}
        <IconButton
          className={classes.closeIcon}
          size="small"
          edge="start"
          color="primary"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Typography>
    </MuiDialogTitle>
  )
}

ModalDialogTitle.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  modalBoxStyle: PropTypes.string,
}

export default ModalDialogTitle
