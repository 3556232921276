import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const ObjectDetailSkeleton = ({
  object,
  detailPath,
  typoVariant = 'h6',
  skeleWidth = 100,
  skeleHeight = 34,
  ...rest
}) =>
  !object ? (
    <Skeleton variant="text" width={skeleWidth} height={skeleHeight} />
  ) : (
    <Typography variant={typoVariant} {...rest}>
      {detailPath
        .split('.')
        .reduce(
          (detail, key) => (detail && detail[key] ? detail[key] : null),
          object,
        )}
    </Typography>
  )

ObjectDetailSkeleton.propTypes = {
  object: PropTypes.object,
  detailPath: PropTypes.string.isRequired,
  typoVariant: PropTypes.string,
  skeleHeight: PropTypes.number,
  skeleWidth: PropTypes.number,
}

export default ObjectDetailSkeleton
