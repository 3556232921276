// browser compatibility stuff. Needs to be always at the top
import './config/polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from './constants/Routes'
import { PublicRoute } from './components/molecules'
import { store, persistor } from './redux/store'
import './config'
import './index.css'
import { AuthenticationPage } from './components/organisms/Pages'
import AuthorizedRoutes from './routes/AuthorizedRoutes'

window.worksense = window.worksense || {}
window.worksense.build_env = `${process.env.REACT_APP_ENV}`

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <Switch>
          <PublicRoute
            exact
            path={Routes.Activate.url}
            component={AuthenticationPage}
          />
          <PublicRoute
            exact
            path={Routes.Login.url}
            component={AuthenticationPage}
          />
          <PublicRoute
            exact
            path={Routes.Register.url}
            component={AuthenticationPage}
          />
          <PublicRoute
            exact
            path={Routes.RegisterAdmin.url}
            component={AuthenticationPage}
          />
          <PublicRoute
            exact
            path={Routes.ResetPassword.url}
            component={AuthenticationPage}
          />
          <AuthorizedRoutes />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
