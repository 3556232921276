import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const ValidationError = ({ error, t, translationProperties }) => {
  const localizedString = translationProperties
    ? t(error, translationProperties)
    : t(error)
  return error ? <span style={{ color: 'red' }}>{localizedString}</span> : ''
}

ValidationError.propTypes = {
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
  translationProperties: PropTypes.object,
}

export default withTranslation(['validation'])(ValidationError)
