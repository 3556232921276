import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Routes from '../constants/Routes'
import { getAllowedNavLink } from './get-allowed-routes'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
  DashboardIcon,
  DeviceTelemetryIcon,
  ReportsIcon,
  // GroupOutlinedIcon,
  PersonOutlineOutlinedIcon,
  PreScreeningIcon,
  TasksIcon,
  SurveysIcon,
  // WorklogIcon,
} from '../components/atoms/Icons'
import { whConsole } from '../config/environment'
import { memoize } from '../lib/lodash.custom'
import { withTranslation } from 'react-i18next'

const navIconClass = 'nav-icon'
const navTextClass = 'nav-text'

const iconMap = {
  Dashboard: <DashboardIcon className={navIconClass} />,
  PreScreening: <PreScreeningIcon className={navIconClass} />,
  DeviceTelemetry: <DeviceTelemetryIcon className={navIconClass} />,
  Reports: <ReportsIcon className={navIconClass} />,
  // Groups: <GroupOutlinedIcon className={navIconClass} />,
  Users: <PersonOutlineOutlinedIcon className={navIconClass} />,
  Tasks: <TasksIcon className={navIconClass} />,
  Surveys: <SurveysIcon className={navIconClass} />,
  // Worklog: <WorklogIcon className={navIconClass} />,
}

// function to convert spaces in string to camelCase and remove spaces
const camelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase()
    })
}

// generate links only once
const memoizedLinks = memoize(getAllowedNavLink)

const MapAllowedMenuItems = ({ roles, classes, collapsed, t }) => {
  let routes = memoizedLinks(roles, Routes) || []

  whConsole('MapAllowedMenuItems: ', routes)

  return routes.map((route, i) => {
    return route.link.inSidebar ? (
      <NavLink
        key={i}
        to={route.url}
        className={classes.navLink}
        activeClassName={classes.activeNavLink}
        exact
      >
        <ListItem button>
          <ListItemIcon>
            {iconMap[route.link.text.replace(/\s/g, '')]}
          </ListItemIcon>
          <ListItemText
            primary={t(
              `navigation:menu.${camelCase(route.link.text.toLowerCase())}`,
            )}
            className={collapsed ? classes.hideText : navTextClass}
          />
        </ListItem>
      </NavLink>
    ) : null
  })
}

MapAllowedMenuItems.propTypes = {
  t: PropTypes.func,
  roles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    roles: state.user.roles,
  }
}

const translated = withTranslation()(MapAllowedMenuItems)
const connected = connect(mapStateToProps)(translated)
export default memo(connected)
