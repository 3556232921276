export const LOGIN_FORM_ID = 'loginForm'
export const REGISTER_FORM_ID = 'registerForm'
export const REGISTER_ADMIN_FORM_ID = 'adminRegisterForm'

export const CREATE_GROUP_FORM_ID = 'createGroup'
export const UPDATE_GROUP_FORM_ID = 'updateGroup'

export const CREATE_USERS_FORM_ID = 'createUsersForm'

export const CREATE_TASK_FORM_ID = 'createTaskForm'
export const UPDATE_TASK_FORM_ID = 'updateTaskForm'
export const CREATE_TOOL_FORM_ID = 'createToolForm'

export const CREATE_SURVEY_FORM_ID = 'createSurveyForm'
export const CREATE_WORKLOG_ENTRY_FORM_ID = 'createWorklogEntryForm'
export const UPDATE_WORKLOG_ENTRY_FORM_ID = 'updateWorklogEntryForm'

export const CREATE_TASK_APP_ACCOUNT_FORM_ID = 'createTaskAppAccountForm'
