import React, { memo, Suspense } from 'react'
import PropTypes from 'prop-types'
import ProtectedRoute from '../components/molecules/ProtectedRoute'
import { connect } from 'react-redux'
import Routes from '../constants/Routes'
import { getAllowedRoutesFromRoles } from './get-allowed-routes'

const AuthorizedRoutes = ({ roles }) =>
  getAllowedRoutesFromRoles(Routes, roles).map((route) => (
    <Suspense key={route.pageName} fallback={'Loading'}>
      <ProtectedRoute
        exact={route.routerIsExactPath}
        path={route.url}
        component={route.component}
      />
    </Suspense>
  ))

AuthorizedRoutes.propTypes = {
  roles: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  return {
    roles: state.user.roles,
  }
}

const connected = connect(mapStateToProps, null)(AuthorizedRoutes)
export default memo(connected)
