import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://23e6b2671f164aec85e071cc83ac982b@o369244.ingest.sentry.io/6154937',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
