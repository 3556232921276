import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Routes from '../../../../constants/Routes'
import GroupListView from './GroupListView'
import GroupDetailView from './GroupDetailView'

class GroupView extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Groups.url} component={GroupListView} />
        <Route path={Routes.Groups.Detail.url} component={GroupDetailView} />
      </Switch>
    )
  }
}

export default GroupView
