import { DEFAULT_TIME_FORMAT } from '../../utils/config'
import { getPredefinedDates, displaySelectedDate } from '../../utils/time-calc'
import { ReportsActionTypes } from './reports.types'

const INITIAL_STATE = {
  groupCode: 'all',
  searchString: '',
  dateMenuSelected: 'all',
  dateMenuIsOpen: false,
  searchIsFocus: false,
  startDate: '',
  endDate: '',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportsActionTypes.SET_GROUP: {
      return {
        ...state,
        groupCode: action.payload,
      }
    }
    case ReportsActionTypes.SET_NAME: {
      return {
        ...state,
        searchString: action.payload,
      }
    }
    case ReportsActionTypes.SET_DATE: {
      const range = action.payload
      const displayName = displaySelectedDate(range.startDate, range.endDate)

      return {
        ...state,
        startDate: range.startDate.format(DEFAULT_TIME_FORMAT),
        endDate: range.endDate.format(DEFAULT_TIME_FORMAT),
        dateMenuSelected: displayName,
        dateMenuIsOpen: false,
      }
    }
    case ReportsActionTypes.SET_PREDEFINED_DATE: {
      const date = action.payload
      const dates = getPredefinedDates(date.query)

      return {
        ...state,
        startDate: dates.startDate,
        endDate: dates.endDate,
        dateMenuSelected: date.name,
        dateMenuIsOpen: false,
      }
    }
    case ReportsActionTypes.TOGGLE_DATE_MENU: {
      return { ...state, dateMenuIsOpen: action.payload }
    }
    case ReportsActionTypes.IS_SEARCHING: {
      const isSearching = !state.searchIsFocus
      return {
        ...state,
        searchIsFocus: isSearching,
      }
    }
    case ReportsActionTypes.CLEAR_FILTERS: {
      return { ...INITIAL_STATE }
    }
    default:
      return state
  }
}

export default reducer
