import { ENV, ENVIRONMENTS } from '../config/environment'

const API_VERSION = 1
const API_BASE_PATH = `v${API_VERSION}`

const Endpoints = {}

const ServerStages = {
  [ENVIRONMENTS.local]: 'http://localhost:81/',
  [ENVIRONMENTS.development]: 'https://api.dev.wearhealth.net/',
  [ENVIRONMENTS.production]: 'https://api.prd.wearhealth.net/',
}

const ApiStages = {
  [ENVIRONMENTS.local]: `${ServerStages[ENVIRONMENTS.local]}${API_BASE_PATH}`,
  [ENVIRONMENTS.development]: `${
    ServerStages[ENVIRONMENTS.development]
  }${API_BASE_PATH}`,
  [ENVIRONMENTS.production]: `${
    ServerStages[ENVIRONMENTS.production]
  }${API_BASE_PATH}`,
}

Endpoints.Ping = {}
Endpoints.Ping.Urls = {}
Endpoints.Ping.Urls.Host = ServerStages[ENV]
Endpoints.Ping.Urls.Base = `${Endpoints.Ping.Urls.Host}`

Endpoints.AvailableTasks = {}
Endpoints.AvailableTasks.Urls = {}
Endpoints.AvailableTasks.Urls.Host = ApiStages[ENV]
Endpoints.AvailableTasks.Urls.Base = `${Endpoints.AvailableTasks.Urls.Host}/available-tasks`
Endpoints.AvailableTasks.Urls.List = `${Endpoints.AvailableTasks.Urls.Base}`

Endpoints.Auth = {}
Endpoints.Auth.Urls = {}
Endpoints.Auth.Urls.Host = ApiStages[ENV]
Endpoints.Auth.Urls.Base = `${Endpoints.Auth.Urls.Host}/auth`
Endpoints.Auth.Urls.Activate = `${Endpoints.Auth.Urls.Base}/activate`
Endpoints.Auth.Urls.Login = `${Endpoints.Auth.Urls.Base}/login`
Endpoints.Auth.Urls.Register = `${Endpoints.Auth.Urls.Base}/register`
Endpoints.Auth.Urls.AdminRegister = `${Endpoints.Auth.Urls.Base}/admin.register`

Endpoints.Analytics = {}
Endpoints.Analytics.Urls = {}
Endpoints.Analytics.Urls.Host = ApiStages[ENV]
Endpoints.Analytics.Urls.Base = `${Endpoints.Analytics.Urls.Host}/analytics`
Endpoints.Analytics.Urls.Metrics = `${Endpoints.Analytics.Urls.Base}/metrics`
Endpoints.Analytics.Urls.SessionsCalendar = `${Endpoints.Analytics.Urls.Base}/sessions.calendar`
Endpoints.Analytics.Urls.WorkloadCalendar = `${Endpoints.Analytics.Urls.Base}/workload.calendar`

Endpoints.DeviceTelemetry = {}
Endpoints.DeviceTelemetry.Urls = {}
Endpoints.DeviceTelemetry.Urls.Host = ApiStages[ENV]
Endpoints.DeviceTelemetry.Urls.Base = `${Endpoints.DeviceTelemetry.Urls.Host}/device-telemetry`
Endpoints.DeviceTelemetry.Urls.List = `${Endpoints.DeviceTelemetry.Urls.Base}/events`

Endpoints.Exoskeletons = {}
Endpoints.Exoskeletons.Urls = {}
Endpoints.Exoskeletons.Urls.Host = ApiStages[ENV]
Endpoints.Exoskeletons.Urls.Base = `${Endpoints.Exoskeletons.Urls.Host}/exoskeletons`
Endpoints.Exoskeletons.Urls.List = `${Endpoints.Exoskeletons.Urls.Base}`

Endpoints.Profile = {}
Endpoints.Profile.Urls = {}
Endpoints.Profile.Urls.Host = ApiStages[ENV]
Endpoints.Profile.Urls.Base = `${Endpoints.Profile.Urls.Host}/profile`

Endpoints.Record = {}
Endpoints.Record.Urls = {}
Endpoints.Record.Urls.Host = ApiStages[ENV]
Endpoints.Record.Urls.Base = `${Endpoints.Record.Urls.Host}/records`
Endpoints.Record.Urls.List = `${Endpoints.Record.Urls.Base}`
Endpoints.Record.Urls.Detail = `${Endpoints.Record.Urls.Base}/:recordId`

Endpoints.WorklogManagement = {}
Endpoints.WorklogManagement.Urls = {}
Endpoints.WorklogManagement.Urls.Host = ApiStages[ENV]
Endpoints.WorklogManagement.Urls.Base = `${Endpoints.WorklogManagement.Urls.Host}/worklog-management`
Endpoints.WorklogManagement.Urls.ListWorkers = `${Endpoints.WorklogManagement.Urls.Base}/workers`

Endpoints.UserManagement = {}
Endpoints.UserManagement.Urls = {}
Endpoints.UserManagement.Urls.Host = ApiStages[ENV]
Endpoints.UserManagement.Urls.Base = `${Endpoints.UserManagement.Urls.Host}/user-management`
Endpoints.UserManagement.Urls.List = `${Endpoints.UserManagement.Urls.Base}/users`
Endpoints.UserManagement.Urls.Create = `${Endpoints.UserManagement.Urls.List}`
Endpoints.UserManagement.Urls.Detail = `${Endpoints.UserManagement.Urls.List}/:userId`
Endpoints.UserManagement.Urls.Update = `${Endpoints.UserManagement.Urls.Detail}`
Endpoints.UserManagement.Urls.Delete = `${Endpoints.UserManagement.Urls.Detail}`
Endpoints.UserManagement.Urls.GenerateCredentials = `${Endpoints.UserManagement.Urls.Base}/generate-credentials`

Endpoints.Group = {}
Endpoints.Group.Urls = {}
Endpoints.Group.Urls.Host = ApiStages[ENV]
Endpoints.Group.Urls.Base = `${Endpoints.Group.Urls.Host}/groups`
Endpoints.Group.Urls.List = `${Endpoints.Group.Urls.Base}`
Endpoints.Group.Urls.Create = `${Endpoints.Group.Urls.Base}`
Endpoints.Group.Urls.Detail = `${Endpoints.Group.Urls.Base}/:groupId`
Endpoints.Group.Urls.Update = `${Endpoints.Group.Urls.Detail}`
Endpoints.Group.Urls.Assign = `${Endpoints.Group.Urls.Detail}/assign` // deprecated (should be removed after WHIT-1659)
Endpoints.Group.Urls.Users = `${Endpoints.Group.Urls.Detail}/users`
Endpoints.Group.Urls.Exoskeletons = `${Endpoints.Group.Urls.Detail}/exoskeletons`

Endpoints.Workers = {}
Endpoints.Workers.Urls = {}
Endpoints.Workers.Urls.Host = ApiStages[ENV]
Endpoints.Workers.Urls.Base = `${Endpoints.Workers.Urls.Host}/workers`

Endpoints.PreScreeningAppMgmt = {}
Endpoints.PreScreeningAppMgmt.Urls = {}
Endpoints.PreScreeningAppMgmt.Urls.Host = ApiStages[ENV]
Endpoints.PreScreeningAppMgmt.Urls.Base = `${Endpoints.PreScreeningAppMgmt.Urls.Host}/pre-screening/app-management`
Endpoints.PreScreeningAppMgmt.Urls.List = `${Endpoints.PreScreeningAppMgmt.Urls.Base}/accounts`
Endpoints.PreScreeningAppMgmt.Urls.Create = `${Endpoints.PreScreeningAppMgmt.Urls.List}`

Endpoints.Tasks = {}
Endpoints.Tasks.Urls = {}
Endpoints.Tasks.Urls.Host = ApiStages[ENV]
Endpoints.Tasks.Urls.Base = `${Endpoints.Tasks.Urls.Host}/tasks`
Endpoints.Tasks.Urls.List = `${Endpoints.Tasks.Urls.Base}`
Endpoints.Tasks.Urls.Create = `${Endpoints.Tasks.Urls.Base}`
Endpoints.Tasks.Urls.Detail = `${Endpoints.Tasks.Urls.Base}/:taskId`
Endpoints.Tasks.Urls.Update = `${Endpoints.Tasks.Urls.Detail}`
Endpoints.Tasks.Urls.Delete = `${Endpoints.Tasks.Urls.Detail}`

Endpoints.Tools = {}
Endpoints.Tools.Urls = {}
Endpoints.Tools.Urls.Host = ApiStages[ENV]
Endpoints.Tools.Urls.Base = `${Endpoints.Tools.Urls.Host}/tools`
Endpoints.Tools.Urls.List = `${Endpoints.Tools.Urls.Base}`
Endpoints.Tools.Urls.Create = `${Endpoints.Tools.Urls.Base}`
Endpoints.Tools.Urls.Delete = `${Endpoints.Tools.Urls.Base}/:toolId`

Endpoints.Surveys = {}
Endpoints.Surveys.Urls = {}
Endpoints.Surveys.Urls.Host = ApiStages[ENV]
Endpoints.Surveys.Urls.Base = `${Endpoints.Surveys.Urls.Host}/surveys`
Endpoints.Surveys.Urls.List = `${Endpoints.Surveys.Urls.Base}`
Endpoints.Surveys.Urls.Create = `${Endpoints.Surveys.Urls.Base}`

Endpoints.Worklog = {}
Endpoints.Worklog.Urls = {}
Endpoints.Worklog.Urls.Host = ApiStages[ENV]
Endpoints.Worklog.Urls.Base = `${Endpoints.Worklog.Urls.Host}/worklog`
Endpoints.Worklog.Urls.List = `${Endpoints.Worklog.Urls.Base}`
Endpoints.Worklog.Urls.Create = `${Endpoints.Worklog.Urls.Base}`
Endpoints.Worklog.Urls.Export = `${Endpoints.Worklog.Urls.Base}/export`
Endpoints.Worklog.Urls.Detail = `${Endpoints.Worklog.Urls.Base}/:worklogId`
Endpoints.Worklog.Urls.Update = `${Endpoints.Worklog.Urls.Detail}`
Endpoints.Worklog.Urls.Delete = `${Endpoints.Worklog.Urls.Detail}`

// TODO: REFACTOR BELOW AFTER BACKEND GROUPS ROUTES APPROPRIATELY
// they should become part of the users group
Endpoints.Tenants = {}
Endpoints.Tenants.Url = `${ApiStages[ENV]}/tenants`
Endpoints.Roles = {}
Endpoints.Roles.Url = `${ApiStages[ENV]}/roles`

export default Endpoints
