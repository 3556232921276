import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fetchEventsListStartAsync } from '../../../../redux/deviceTelemetry/deviceTelemetry.actions'
import EventsListTable from './EventsListTable'

class EventsListView extends Component {
  async componentDidMount() {
    this.props.fetchEventsList()
  }

  render() {
    const { t } = this.props

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.deviceTelemetry')}</title>
        </Helmet>

        <EventsListTable />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEventsList: () => dispatch(fetchEventsListStartAsync()),
})

EventsListView.propTypes = {
  fetchEventsList: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const EventsListViewComposed = compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(EventsListView)

export default EventsListViewComposed
