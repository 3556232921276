export const createRoute = (route, params) => {
  const routeSymbols = route.match(/(:\w+)/g)
  const paramKeys = Object.keys(params)

  let result = route

  for (let i = 0; i < routeSymbols.length; i++) {
    const symbol = routeSymbols[i].replace(':', '')

    if (paramKeys.indexOf(symbol) === -1) continue

    result = result.replace(`:${symbol}`, params[symbol])
  }

  return result
}

const formatQueryParams = (paramsObject) =>
  Object.keys(paramsObject)
    .filter((key) => {
      const value = paramsObject[key]
      return (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
      )
    })
    .map((key) => {
      const value = paramsObject[key]
      const encodedValue = Array.isArray(value)
        ? value.map(encodeURIComponent).join(',')
        : encodeURIComponent(value)
      return `${key}=${encodedValue}`
    })
    .join('&')

export const withParams = (url, urlParamsObject) => {
  const urlParamsQueryString =
    urlParamsObject && formatQueryParams(urlParamsObject)
  return urlParamsQueryString ? `${url}?${urlParamsQueryString}` : url
}
