import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import { SpringFade, ModalDialogTitle } from '../atoms'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { withTranslation } from 'react-i18next'
import AppTestIds from '../../constants/AppTestIds'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-200px',
    overflowY: 'scroll',
  },
  paper: {
    width: '600px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '&.lg': {
      width: '800px',
    },
    '&.xl': {
      width: '924px',
    },
  },
  footer: {
    background: 'rgba(0,0,0,.03)',
  },
  backDrop: {
    backdropFilter: 'blur(2px)',
  },
}))

const ModalBox = (props, { timeout = 300 }) => {
  const classes = useStyles()
  const {
    open,
    paperSize,
    actionButton,
    onAction: handleAction,
    onClose: handleClose,
    type,
    noHeader,
    t,
  } = props

  return (
    <>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: timeout,
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <SpringFade in={open}>
          <div className={[classes.paper, paperSize].join(' ')}>
            {!noHeader ? (
              <ModalDialogTitle
                title={props.modalTitle}
                modalBoxStyle={type}
                handleClose={handleClose}
              />
            ) : null}

            <MuiDialogContent dividers> {props.children}</MuiDialogContent>

            {actionButton !== undefined ? (
              <MuiDialogActions className={classes.footer}>
                {type !== 'error' ? (
                  <Button
                    app-testid={AppTestIds.Groups.CloseDialog}
                    onClick={handleClose}
                  >
                    {t('close')}
                  </Button>
                ) : null}

                {actionButton !== null ? (
                  actionButton
                ) : (
                  <Button onClick={handleAction} color="secondary">
                    {t('add')}
                  </Button>
                )}
              </MuiDialogActions>
            ) : null}
          </div>
        </SpringFade>
      </Modal>
    </>
  )
}

ModalBox.propTypes = {
  open: PropTypes.bool.isRequired,
  paperSize: PropTypes.string,
  modalTitle: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  actionButton: PropTypes.object,
  type: PropTypes.string,
  noHeader: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['global'])(ModalBox)
