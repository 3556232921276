import { GroupsActionTypes } from './groups.types'

export const storeUserIdForAssignment = (userId) => ({
  type: GroupsActionTypes.STORE_USER_ID_FOR_ASSIGNMENT,
  payload: userId,
})

export const removeUserIdFromAssignment = (userId) => ({
  type: GroupsActionTypes.REMOVE_USER_ID_FROM_ASSIGNMENT,
  payload: userId,
})

export const removeAllUserIdsFromAssignment = () => ({
  type: GroupsActionTypes.REMOVE_ALL_USER_IDS_FROM_ASSIGNMENT,
})

export const storeExoskeletonIdForAssignment = (exoskeletonId) => ({
  type: GroupsActionTypes.STORE_EXOSKELETON_ID_FOR_ASSIGNMENT,
  payload: exoskeletonId,
})

export const removeExoskeletonIdFromAssignment = (exoskeletonId) => ({
  type: GroupsActionTypes.REMOVE_EXOSKELETON_ID_FROM_ASSIGNMENT,
  payload: exoskeletonId,
})

export const removeAllExoskeletonIdsFromAssignment = () => ({
  type: GroupsActionTypes.REMOVE_ALL_EXOSKELETON_IDS_FROM_ASSIGNMENT,
})
