import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { _ } from '../../../../lib'
import { Roles, Routes } from '../../../../constants'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Restricted } from '../../../atoms'
import { GroupDropdown } from '../../../molecules'
import { SummaryCards } from '../../../molecules/SummaryCards'
import { compose } from 'redux'
import { fetchDashboardMetrics } from '../../../../api/api.analytics'
import WorkloadCalendarWidget from './WorkloadCalendarWidget'
import SessionsCalendarWidget from './SessionsCalendarWidget'

const styles = (theme) => ({
  root: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  summary: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(2),
    minWidth: `calc(20% - ${theme.spacing(1)}px)`,
  },
})

class DashboardView extends Component {
  state = {
    groupsCount: null,
    reportsCount: null,
    usersCount: null,
    fetchError: false,
    fetching: false,
    loaded: false,
  }

  async componentDidMount() {
    await this.fetchDashboardMetrics()
  }

  fetchDashboardMetrics = async () => {
    const metrics = await fetchDashboardMetrics()

    if (!('error' in metrics)) {
      const oldState = _.cloneDeep(this.state)

      const updateReportsList = {
        oldState,
        reportsCount: metrics.response.record_count,
        groupsCount: metrics.response.group_count,
        usersCount: metrics.response.user_count,
      }

      !this.unmount && this.setState(updateReportsList)
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchError: true,
        })
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  triggerViewError = (error) => {
    if (error) {
      this.setState({
        fetching: false,
        fetchError: true,
      })
    }
  }

  render() {
    const { t, classes } = this.props

    const summaries = [
      {
        name: t('dashboard:widgets.users.title'),
        value: '30',
        subtitle: t('summaries:numOfUsers'),
        color: 'black',
      },
      {
        name: 'Teams',
        value: '2',
        subtitle: t('summaries:allGroups'),
        color: 'black',
      },
      {
        name: t('dashboard:widgets.reports.title'),
        value: '2',
        subtitle: t('summaries:numOfRep'),
        color: 'black',
      },
    ]

    return (
      <>
        <Helmet>
          <title>{t(Routes.Dashboard.title)}</title>
        </Helmet>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px' }}
        >
          <Box p={1}>
            <GroupDropdown
              handleViewError={this.triggerViewError}
              reducer="DASHBOARD"
            />
          </Box>
        </Box>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
          className={classes.root}
        >
          {summaries.map((card, i) => {
            return (
              <Grid item key={i} style={{ flexGrow: 1 }}>
                <SummaryCards
                  key={i}
                  metrics={[
                    {
                      title: card.name,
                      value: card.value,
                      subtitle: card.subtitle,
                      color: card.color,
                    },
                  ]}
                  classes={[classes.summary]}
                />
              </Grid>
            )
          })}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
              <WorkloadCalendarWidget />
            </Restricted>
            <Restricted anyOfRequiredRoles={[Roles.ADMIN]}>
              <SessionsCalendarWidget />
            </Restricted>
          </Grid>
        </Grid>
      </>
    )
  }
}

DashboardView.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  withRouter,
  withTranslation(['global', 'dashboard']),
)(DashboardView)
