import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  withStyles,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  accordionRoot: {
    '&:before': {
      display: 'none',
    },
  },
  summaryRoot: {
    padding: 0,
  },
  summaryContent: {
    margin: 0,
  },
  expandIcon: {
    transform: 'rotate(0deg)!important',
  },
})

class ControlledAccordion extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    preventExpansion: PropTypes.bool,
    summaryComponent: PropTypes.node.isRequired,
    detailComponent: PropTypes.node.isRequired,
    expandIcon: PropTypes.node.isRequired,
    actionButtonHandler: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    expanded: false,
  }

  handleToggleAccordion = () =>
    this.setState({ expanded: !this.state.expanded })

  render() {
    const {
      actionButtonHandler,
      classes,
      preventExpansion,
      expandIcon,
      detailComponent,
      summaryComponent,
      t,
    } = this.props
    const { expanded } = this.state

    return (
      <Accordion expanded={expanded} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expandIcon: classes.expandIcon,
          }}
          expandIcon={expandIcon}
          IconButtonProps={{
            onClick: preventExpansion ? () => null : this.handleToggleAccordion,
          }}
        >
          {summaryComponent}
        </AccordionSummary>
        <AccordionDetails>{detailComponent}</AccordionDetails>
        <AccordionActions>
          <Button size="small" onClick={this.handleToggleAccordion}>
            {t('global:cancel')}
          </Button>
          <Button size="small" color="secondary" onClick={actionButtonHandler}>
            {t('global:delete')}
          </Button>
        </AccordionActions>
      </Accordion>
    )
  }
}

export default compose(
  withTranslation(['global']),
  withStyles(styles),
)(ControlledAccordion)
