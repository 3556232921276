import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { ENV, ENVIRONMENTS } from './environment'
import locales from '../locales'

export const DEFAULT_LANGUAGE = 'en'
export const LANGUAGES = ['en', 'es']

const translations = () => {
  const languageDetectionOptions = [
    ENVIRONMENTS.local,
    ENVIRONMENTS.test,
  ].includes(ENV)
    ? { order: ['localStorage'] }
    : {}

  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      ns: ['global', 'authentication'],
      lngs: LANGUAGES,
      whitelist: LANGUAGES,
      lowerCaseLng: true,
      defaultNS: 'global',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources: locales,
      detection: languageDetectionOptions,
    })

  return i18n
}

export default translations
