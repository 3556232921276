import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { CssBaseline, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { Routes } from '../../constants'
import { isAuthenticated } from '../../utils'

import TopBar from '../organisms/TopBar'
import Footer from '../organisms/Footer'
import LeftSidebar from '../organisms/LeftSidebar'
import BarAlert from '../atoms/BarAlert'
import { MainTheme } from '../themes'
import ErrorBoundary from './ErrorBoundary'

import { appUiSelector } from '../../redux/ui/ui.selectors'
import { closeBarAlert } from '../../redux/ui/ui.actions'

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    background: '#F7F8FC',
  },
  toolbar: theme.mixins.toolbar,

  main: {
    flexGrow: 1,
    padding: `0px 24px`,
    minHeight: 'calc(100% - 124px);',
    marginBottom: 48,
    marginTop: theme.spacing(4),
  },

  content: {
    marginLeft: 264,
  },
  contentExpanded: {
    marginLeft: 72,
  },
})

class ProtectedRoute extends Component {
  state = {
    isAuthenticated: isAuthenticated(),
  }

  onBarAlertClose = () => this.props.closeBarAlert()

  render() {
    const {
      classes,
      component: Component,
      isLeftSidebarCollapsed,
      barAlertContents,
      t,
      ...rest
    } = this.props
    const { isAuthenticated } = this.state

    if (isAuthenticated === undefined || isAuthenticated === false) {
      return (
        <Redirect
          to={{
            pathname: Routes.Login.url,
          }}
        />
      )
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <MainTheme>
            <CssBaseline />
            <TopBar />
            <LeftSidebar />
            <main
              className={[
                isLeftSidebarCollapsed
                  ? classes.contentExpanded
                  : classes.content,
                classes.main,
              ].join(' ')}
            >
              <div className={classes.toolbar} />
              <ErrorBoundary>
                <Component {...props} />
                <BarAlert
                  open={!!barAlertContents}
                  alertBody={
                    barAlertContents
                      ? t(`components:barAlert.body.${barAlertContents.body}`)
                      : undefined
                  }
                  alertType={
                    barAlertContents ? barAlertContents.type : undefined
                  }
                  handleClose={this.onBarAlertClose}
                />
              </ErrorBoundary>
            </main>
            <Footer />
          </MainTheme>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  isLeftSidebarCollapsed: appUiSelector(state).isLeftSidebarCollapsed,
  barAlertContents: appUiSelector(state).barAlertContents,
})

const mapDispatchToProps = (dispatch) => {
  return {
    closeBarAlert: () => dispatch(closeBarAlert()),
  }
}

ProtectedRoute.propTypes = {
  barAlertContents: PropTypes.object,
  closeBarAlert: PropTypes.func.isRequired,
  component: PropTypes.any,
  classes: PropTypes.object.isRequired,
  isLeftSidebarCollapsed: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

const styled = withStyles(styles)(ProtectedRoute)
const connected = connect(mapStateToProps, mapDispatchToProps)(styled)
const translated = withTranslation(['global', 'components'])(connected)

export default translated
