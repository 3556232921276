const AppTestIds = {}


AppTestIds.Auth = { Base: 'auth' }
AppTestIds.Auth.LoginView = { Base: `${AppTestIds.Auth.Base}.login-view` }
AppTestIds.Auth.LoginView.LoginForm = `${AppTestIds.Auth.LoginView.Base}.login-form`
AppTestIds.Auth.LoginView.EmailField = `${AppTestIds.Auth.LoginView.Base}.email-field`
AppTestIds.Auth.LoginView.PasswordField = `${AppTestIds.Auth.LoginView.Base}.password-field`
AppTestIds.Auth.LoginView.LoginButton = `${AppTestIds.Auth.LoginView.Base}.login-button`

AppTestIds.LeftSidebarNavigation ={
  Base: (url) => {
    return `[href='/${url}']`
  }
}

AppTestIds.LeftSidebarNavigation = {
  Reports : AppTestIds.LeftSidebarNavigation.Base('reports'),
  Groups : AppTestIds.LeftSidebarNavigation.Base('groups'),
}

AppTestIds.TopBar = { Base: 'topbar' }

AppTestIds.Filters = { Base: 'filter' }
AppTestIds.Filters.GroupDropdown = `${AppTestIds.Filters.Base}.group-dropdown`
AppTestIds.Filters.Search = `${AppTestIds.Filters.Base}.search`
AppTestIds.Filters.DatePicker = { Base:`${AppTestIds.Filters.Base}.date-picker`}
AppTestIds.Filters.DatePicker.Button = `${AppTestIds.Filters.DatePicker.Base}.button`
AppTestIds.Filters.DatePicker.Days = (days) =>{ return AppTestIds.Filters.DatePicker.Base+"."+days }
AppTestIds.Filters.ClearButton = `${AppTestIds.Filters.Base}.clear-filter-button`

AppTestIds.Reports = { Base: 'reports' }
AppTestIds.Reports.TableRow = `${AppTestIds.Reports.Base}.table-row`
AppTestIds.Reports.FullName = `${AppTestIds.Reports.Base}.full-name`
AppTestIds.Reports.Details = {Base: `${AppTestIds.Reports.Base}.details`}
AppTestIds.Reports.Details.BackButton = `${AppTestIds.Reports.Details.Base}.back-button`

AppTestIds.Groups = { Base: 'groups' }
AppTestIds.Groups.OpenDialog =  `${AppTestIds.Groups.Base}.open-dialog`
AppTestIds.Groups.CloseDialog =  `${AppTestIds.Groups.Base}.close-dialog`
AppTestIds.Groups.SubmitDialog =  `${AppTestIds.Groups.Base}.submit-create`
AppTestIds.Groups.InputName =  `${AppTestIds.Groups.Base}.input-name`
AppTestIds.Groups.InputCode =  `${AppTestIds.Groups.Base}.input-code`
AppTestIds.Groups.InputDesc =  `${AppTestIds.Groups.Base}.input-desc`
AppTestIds.Groups.UpdateButton = `${AppTestIds.Groups.Base}.update-button`

AppTestIds.Tasks = { Base: 'tasks' }
AppTestIds.Tasks.UpdateButton = `${AppTestIds.Tasks.Base}.update-button`

AppTestIds.PreScreening = { Base: 'pre-screening' }
AppTestIds.PreScreening.CreateTaskAppAccountForm = `${AppTestIds.PreScreening.Base}.create-task-app-account-form`

AppTestIds.TopBar.Title = `${AppTestIds.TopBar.Base}.title`

export const TestIdSelector = id => `[app-testid="${id}"]`

export default AppTestIds
