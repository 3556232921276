import { openBarAlert } from '../redux/ui/ui.actions'
import { getAuthenticationToken } from './auth'
import { AlertTypes } from '../constants'
import { store } from '../redux/store'

const AUTHORIZATION_HEADER = 'Authorization'
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Device-Type': 'web',
}

const DEFAULT_OPTIONS = {
  mode: 'cors',
}

/**
 * Fetch server with authentications headers
 * @param {String} url of the API endpoints we need to fetch
 * @param {Object} options to modify the request to be POST, different contentType
 * @returns {Object} that contains response @status (2xx || 4xx || 5xx) and the data as stringified JSON obj
 */
export const fetchWithAuthHeaders = (url, options = {}) => {
  const defaultHeaders = { ...DEFAULT_HEADERS }
  const authHeaderKey = options.authHeaderKey || AUTHORIZATION_HEADER
  const authHeaders = { [authHeaderKey]: getAuthenticationToken() }

  options = {
    ...options,
    headers: { ...defaultHeaders, ...options.headers, ...authHeaders },
  }
  return fetch(url, options)
}

export const fetchWithoutAuthHeaders = (url, options = {}) => {
  const defaultHeaders = { ...DEFAULT_HEADERS }
  options = { ...options, headers: { ...options.headers, ...defaultHeaders } }
  return fetch(url, options)
}

/**
 * Fetch server with authentications headers with specified content type JSON
 * @param {String} url of the API endpoints we need to fetch
 * @param {Object} options to modify the request to be POST, different contentType
 * @returns {Object} that contains response @status (2xx || 4xx || 5xx) and the data as stringified JSON obj
 */
export const fetchWithDefaultHeaders = (url, options = {}) => {
  const defaultHeaders = { ...DEFAULT_HEADERS }

  options = { ...options, headers: { ...options.headers, ...defaultHeaders } }

  if (Object.prototype.hasOwnProperty.call(options, 'noAuthorization')) {
    return fetchWithoutAuthHeaders(url, options)
  } else {
    return fetchWithAuthHeaders(url, options)
  }
}

export const returnResponseJSON = async (response) => {
  let responseJson = {}
  let barAlertContent

  if (!response) {
    responseJson = {
      error: 'NO_INTERNET_ACCESS',
    }
  } else if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202
  ) {
    try {
      responseJson = await response.json()

      if (
        response.status === 201 ||
        responseJson.message.includes('_CREATED') ||
        responseJson.message.includes('_UPDATED') ||
        responseJson.message.includes('_DELETED')
      )
        barAlertContent = {
          title: 'Success',
          body: responseJson.message,
          type: AlertTypes.SUCCESS,
        }
    } catch (e) {
      responseJson = {
        error: 'INVALID_JSON_RESPONSE',
        payload: e,
      }
      barAlertContent = {
        title: 'Error',
        body: responseJson.error,
        type: AlertTypes.ERROR,
      }
    }
  } else if (
    (response.status >= 400 && response.status <= 499) ||
    (response.status >= 500 && response.status <= 500)
  ) {
    const serverResponse = (await response.json()) || 'There was and error'
    responseJson = {
      url: response.url,
      message: serverResponse.message || 'GENERIC_ERROR',
      status: serverResponse.status || 'error',
      error: response.status,
      ...serverResponse,
    }
    barAlertContent = {
      title: 'Error',
      body: responseJson.message,
      type: AlertTypes.ERROR,
    }
  }

  if (barAlertContent) store.dispatch(openBarAlert(barAlertContent))

  return responseJson
}

export const fetchWithErrors = async (url, options) => {
  const response = await fetchWithDefaultHeaders(url, {
    ...options,
    DEFAULT_OPTIONS,
  })

  return returnResponseJSON(response)
}
