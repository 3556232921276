import { createRoute, fetchWithErrors, withParams } from '../utils'
import { Endpoints } from '../constants'

export const fetchGroups = () => fetchWithErrors(Endpoints.Group.Urls.List)
export const fetchGroup = (groupId) =>
  fetchWithErrors(createRoute(Endpoints.Group.Urls.Detail, { groupId }))

export const createGroup = (formData) =>
  fetchWithErrors(Endpoints.Group.Urls.Create, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
export const updateGroup = (groupId, formData) =>
  fetchWithErrors(createRoute(Endpoints.Group.Urls.Update, { groupId }), {
    method: 'PUT',
    body: JSON.stringify(formData),
  })

export const fetchGroupAssignedUsers = (params) =>
  fetchWithErrors(withParams(Endpoints.UserManagement.Urls.List, params))

export const fetchGroupUnassignedUsers = (params) =>
  fetchWithErrors(withParams(Endpoints.UserManagement.Urls.List, params))

export const assignUsersToGroup = (groupId, userIds) =>
  fetchWithErrors(createRoute(Endpoints.Group.Urls.Users, { groupId }), {
    method: 'POST',
    body: JSON.stringify(userIds),
  })

export const assignExoskeletonsToGroup = (groupId, exoskeletonIds) =>
  fetchWithErrors(createRoute(Endpoints.Group.Urls.Exoskeletons, { groupId }), {
    method: 'POST',
    body: JSON.stringify(exoskeletonIds),
  })
