import React from 'react'
// import { Tooltip, Typography, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { DownloadIcon } from '../../../atoms'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import BadgePillow from '../../../atoms/BadgePillow'
import { TableRowSkeleton } from '../../../atoms/TableRowCellSkeleton'
import { withTranslation } from 'react-i18next'
import { DEFAULT_PAGINATION_OPTIONS, AppTestIds } from '../../../../constants'
// import { secondsToDuration } from '../../../../utils'
// import Avatar from '@material-ui/core/Avatar'
import dayjs from 'dayjs'
import TableNoData from '../../../atoms/TableNoData'
import PropTypes from 'prop-types'

const ReportsListTable = ({
  handleChangePage,
  handleChangeRowsPerPage,
  handleClickReportRow,
  t,
  fetching,
  pagination,
  reports,
}) => {
  let tableRows = null

  reports = reports || []

  if ((fetching && reports.length === 0) || (fetching && reports.length > 0)) {
    tableRows = <TableRowSkeleton cells={8} rows={pagination.rowsPerPage} />
  }

  if (!fetching && reports.length === 0) {
    tableRows = <TableNoData cols={8} />
  }

  if (!fetching && reports.length > 0) {
    tableRows = reports.map((row) => (
      <TableRow
        app-testid={AppTestIds.Reports.TableRow}
        hover
        // style={{ cursor: 'pointer' }}
        key={row.id}
        // onClick={(e) => handleClickReportRow(e, row.id)}
      >
        {/* <TableCell component="th" scope="row">
          <Grid container alignItems="center">
            <Avatar color="">
              {row.demographic.user.first_name.charAt(0).toUpperCase()}
            </Avatar>
            <div style={{ paddingLeft: '16px' }}>
              <Tooltip
                title={
                  'Group ID: ' +
                  (row.demographic.group
                    ? row.demographic.group.id
                    : 'Unassigned')
                }
                arrow
              >
                <span app-testid={AppTestIds.Reports.FullName}>
                  {row.demographic.user.first_name}{' '}
                  {row.demographic.user.last_name}
                </span>
              </Tooltip>
              <br />
              <Typography variant="subtitle2">
                {parseFloat(row.demographic.weight / 1000).toFixed(1) +
                  'kg' +
                  ' '}
                {row.demographic.height + 'cm'}
              </Typography>
            </div>
          </Grid>
        </TableCell> */}
        {/* <TableCell component="th" scope="row">
          {row.demographic.user.id}
        </TableCell> */}
        <TableCell align="left">
          {dayjs(row.record_date).format('MM.YYYY')}
        </TableCell>
        <TableCell align="left">
          {row.demographic.group
            ? row.demographic.group.name !== null
              ? row.demographic.group.name
              : row.demographic.group.id
            : 'Unassigned'}
        </TableCell>
        <TableCell align="center">{row.demographic.organization}</TableCell>

        {/* <TableCell align="center">{secondsToDuration(row.duration)}</TableCell> */}
        <TableCell align="center">{row.demographic.no_of_workers}</TableCell>
        <TableCell align="center">
          <BadgePillow value={parseFloat(row.avg_workload_risk).toFixed(1)} />
        </TableCell>
        {/* <TableCell align="right">{row.total_calories + ' kcal'}</TableCell> */}
        {/* <TableCell align="right">
          {Object.prototype.hasOwnProperty.call(row, 'wears_exo') &&
          row.wears_exo !== null
            ? row.wears_exo
              ? t('yes')
              : t('no')
            : 'N/A'}
        </TableCell> */}
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation()
              window.open(row.report_url, '_blank')
            }}
          >
            <DownloadIcon />
          </Button>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table className="" aria-label="worker reports table">
        <TableHead>
          <TableRow>
            {/* <TableCell>{t('name')}</TableCell> */}
            {/* <TableCell>{t('userId')}</TableCell> */}
            <TableCell align="left">{t('date')}</TableCell>
            <TableCell>{t('group')}</TableCell>
            <TableCell align="center">Organization</TableCell>
            {/* <TableCell align="center">{t('duration')}</TableCell> */}
            <TableCell align="center">Workers</TableCell>
            <TableCell align="center">{t('avgWorkloadLevel')}</TableCell>
            {/* <TableCell align="right">{t('totCal')}</TableCell> */}
            <TableCell align="right">{'Download'}</TableCell>
            {/* <TableCell align="right">{t('withExo')}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions}
        component="div"
        count={pagination.total || -1}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

ReportsListTable.propTypes = {
  t: PropTypes.func,
  reports: PropTypes.array.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleClickReportRow: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
}

export default withTranslation(['global'])(ReportsListTable)
