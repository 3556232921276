/* eslint-disable
  @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-argument,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return,
  @typescript-eslint/restrict-template-expressions,
*/
import React, { useCallback, useEffect, useState } from 'react'
import {
  change,
  touch,
  Field,
  Form,
  FormSection,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from 'redux-form'
import type { FormErrors } from 'redux-form'
import * as PropTypes from 'prop-types'
import type { InferProps } from 'prop-types'
import { connect } from 'react-redux'
import type { MapStateToProps, MapDispatchToProps } from 'react-redux'
import {
  demographics,
  required,
  email,
  exactLength,
  minValue,
  maxValue,
  weight,
  height,
} from '../../../../../utils'
import { withTranslation } from 'react-i18next'
import type { WithTranslation } from 'react-i18next'
import { CREATE_USERS_FORM_ID, Roles } from '../../../../../constants'
import { compose } from 'redux'
import FormOutlinedTextField from '../../../../molecules/FormOutlinedTextField'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Typography,
  LinearProgress,
  Divider,
} from '@material-ui/core'
import {
  DatePickerField,
  UserRolesRadioGroup,
  UserAuthMethodRadioGroup,
} from '../../../../molecules'
import type { AuthMethodType } from '../../../../molecules/UserAuthMethodRadioGroup'
import {
  fetchUserRoles,
  generateUserCredentials,
} from '../../../../../api/api.users'

const exactLength6 = exactLength(6)
const exactLength4 = exactLength(4)
const minWeight = minValue(38)
const maxWeight = maxValue(224)
const minHeight = minValue(114)
const maxHeight = maxValue(260)

interface CreateUserFormData {
  role_id?: number
  first_name?: string
  last_name?: string
  demographics?: {
    birthday?: string
    sex?: string
    weight?: number
    height?: number
  }
  email?: string
  alias?: string
  pin?: string
  external_id?: string
}

interface CreateUserFormErrors {
  role_id?: string
  first_name?: string
  last_name?: string
  allDemographics?: string
  email?: string
  alias?: string
  pin?: string
  external_id?: string
}

const validate = (
  values: CreateUserFormData,
): FormErrors<CreateUserFormErrors> => {
  const errors: FormErrors<CreateUserFormErrors> = {}

  if (Object.prototype.hasOwnProperty.call(values, 'demographics')) {
    const demographicsError = demographics(values.demographics)
    if (demographicsError) {
      errors.allDemographics = demographicsError
    }
  }

  return errors
}

const SexRadioGroupTypes = {
  input: PropTypes.object,
}

const SexRadioGroup: React.FC<InferProps<typeof SexRadioGroupTypes>> = ({
  input,
  ...rest
}) => (
  <FormControl style={{ marginBottom: '14px' }}>
    <FormLabel component="legend">Gender</FormLabel>

    <RadioGroup {...input} {...rest}>
      <FormControlLabel
        value="f"
        control={<Radio color="primary" />}
        label="Female"
      />
      <FormControlLabel
        value="m"
        control={<Radio color="primary" />}
        label="Male"
      />
    </RadioGroup>
  </FormControl>
)

SexRadioGroup.propTypes = SexRadioGroupTypes

const CreateUserFormTypes = {
  activeStep: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.shape({
    role_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    demographics: PropTypes.object,
    email: PropTypes.string,
    alias: PropTypes.string,
    pin: PropTypes.string,
    external_id: PropTypes.string,
  }).isRequired,
  steps: PropTypes.array.isRequired,
  skipped: PropTypes.object.isRequired,
  isStepSkipped: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  userRoleIdScope: PropTypes.number,
  updateField: PropTypes.func.isRequired,
  touchField: PropTypes.func.isRequired,
  updateStepCaptionHandler: PropTypes.func.isRequired,
}

const availableAuthMethods: Array<InferProps<typeof AuthMethodType>> = [
  {
    name: 'email',
    label: 'email',
    eligibleRoles: [Roles.ADMIN, Roles.WORKER, Roles.MANAGER],
  },
  {
    name: 'qrCode',
    label: 'qrCode',
    eligibleRoles: [Roles.WORKER],
  },
]

const CreateUserForm: React.FC<
  InferProps<typeof CreateUserFormTypes> & WithTranslation
> = (props) => {
  const {
    activeStep,
    handleSubmit,
    isStepSkipped,
    formSyncErrors,
    t,
    steps,
    submitting,
    userRoleIdScope,
    touchField,
    updateField,
    updateStepCaptionHandler,
  } = props
  const [roles, setRoles] = useState<any[]>([])
  const [fetchingUserRoles, setFetchingUserRoles] = useState<boolean>(false)
  const [fetchingGeneratedCredentials, setFetchingGeneratedCredentials] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [userAuthMethod, setUserAuthMethod] = useState(availableAuthMethods[1])

  const handleFetchUserRoles = useCallback(async (): Promise<void> => {
    setFetchingUserRoles(true)

    try {
      const rootResponse = await fetchUserRoles()
      const enclosedResponse = (rootResponse as any).response

      setRoles(enclosedResponse)
    } catch (error) {
      setErrorMessage((error as any).message)
    }

    setFetchingUserRoles(false)
  }, [])

  const handleGenerateUserCredentials = useCallback(async (): Promise<void> => {
    setFetchingGeneratedCredentials(true)

    try {
      const rootResponse = await generateUserCredentials()
      const enclosedResponse = (rootResponse as any).response

      updateField('alias', enclosedResponse.alias)
      touchField('alias')

      updateField('pin', enclosedResponse.pin)
      touchField('pin')
    } catch (error) {
      setErrorMessage((error as any).message)
    }

    setFetchingGeneratedCredentials(false)
  }, [updateField, touchField])

  useEffect(() => {
    void (async (): Promise<void> => {
      await handleFetchUserRoles()
    })()
  }, [handleFetchUserRoles])

  useEffect(() => {
    if (roles && roles.length > 0) {
      updateField(
        'role_id',
        roles.filter((role) => role.name === Roles.WORKER)[0].id,
      )
      touchField('role_id')
    }
  }, [roles, updateField, touchField])

  useEffect(() => {
    if (activeStep === 0 && userRoleIdScope && roles.length > 0) {
      updateStepCaptionHandler(
        activeStep,
        t(
          `global:${roles
            .filter((role) => role.id === Number(userRoleIdScope))[0]
            .name.toLowerCase()}`,
        ),
      )
    }
    if (activeStep === 1) {
      updateStepCaptionHandler(
        activeStep,
        t(`users:form.authMethod.${userAuthMethod.label}`),
      )
    }
  })

  useEffect(() => {
    if (activeStep === 3 && userAuthMethod.name === 'qrCode') {
      void (async (): Promise<void> => {
        await handleGenerateUserCredentials()
      })()
    }
  }, [userAuthMethod, activeStep, handleGenerateUserCredentials])

  const handleUserAuthMethodChange = (
    authMethod: InferProps<typeof AuthMethodType>,
  ): void => {
    setUserAuthMethod(authMethod)
  }

  const handleUserRoleChange = (roleId: number): void => {
    const selectedRole = roles.filter((role) => role.id === roleId)[0].name

    setUserAuthMethod(availableAuthMethods[1])

    if (!userAuthMethod.eligibleRoles.includes(selectedRole)) {
      const nextEligibleAuthMethod =
        availableAuthMethods[
          availableAuthMethods.indexOf(userAuthMethod) + 1
        ] ||
        availableAuthMethods[availableAuthMethods.indexOf(userAuthMethod) - 1]
      setUserAuthMethod(nextEligibleAuthMethod)
    }
  }

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps.map(
          (
            { title, caption }: { title: string; caption: string },
            index: number,
          ) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: { optional?: React.ReactNode } = {}
            if (caption !== '') {
              labelProps.optional = (
                <Typography variant="caption">{caption}</Typography>
              )
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={title} {...stepProps}>
                <StepLabel {...labelProps}>{title}</StepLabel>
              </Step>
            )
          },
        )}
      </Stepper>
      {fetchingUserRoles || roles.length === 0 ? (
        <LinearProgress />
      ) : (
        <Form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <Field
              name="role_id"
              availableRoles={roles}
              onChange={(event: any) =>
                handleUserRoleChange(Number(event.target.value))
              }
              component={UserRolesRadioGroup}
              required
              validate={[required]}
            />
          )}
          {activeStep === 1 && (
            <UserAuthMethodRadioGroup
              userRoleScope={
                roles.filter((role: any) => role.id === userRoleIdScope)[0].name
              }
              availableAuthMethods={availableAuthMethods}
              changeAuthMethodHandler={handleUserAuthMethodChange}
              value={userAuthMethod}
            />
          )}
          {activeStep === 2 && (
            <>
              <Field
                disabled={submitting}
                label={t('users:form.firstName.label')}
                name="first_name"
                component={FormOutlinedTextField}
                type="text"
              />
              <Field
                disabled={submitting}
                label={t('users:form.lastName.label')}
                name="last_name"
                component={FormOutlinedTextField}
                type="text"
              />
              {userAuthMethod.name === 'qrCode' && (
                <>
                  <Divider variant="middle" style={{ marginBottom: '24px' }} />
                  <FormSection name="demographics">
                    <Field name="sex" component={SexRadioGroup} />
                    <Field
                      disabled={submitting}
                      label={t('users:form.demographics.birthday.label')}
                      name="birthday"
                      type="text"
                      allowSameDateSelection
                      component={DatePickerField}
                    />
                    <Field
                      disabled={submitting}
                      label={t('users:form.demographics.height.label')}
                      name="height"
                      component={FormOutlinedTextField}
                      type="number"
                      validate={[height, minHeight, maxHeight]}
                    />
                    <Field
                      disabled={submitting}
                      label={t('users:form.demographics.weight.label')}
                      name="weight"
                      component={FormOutlinedTextField}
                      type="number"
                      validate={[weight, minWeight, maxWeight]}
                    />
                    {Object.prototype.hasOwnProperty.call(
                      formSyncErrors,
                      'allDemographics',
                    ) && (
                      <Typography color="error">
                        {t(
                          `users:form.demographics.error.allDemographicsFieldsAreRequired`,
                        )}
                      </Typography>
                    )}
                  </FormSection>
                </>
              )}
            </>
          )}
          {activeStep === 3 && (
            <>
              {userAuthMethod.name === 'email' && (
                <Field
                  disabled={submitting}
                  label={t('users:form.email.label')}
                  name="email"
                  component={FormOutlinedTextField}
                  type="text"
                  required
                  validate={[required, email]}
                />
              )}
              {userAuthMethod.name === 'qrCode' && (
                <>
                  <Field
                    disabled={submitting || fetchingGeneratedCredentials}
                    label={t('users:form.alias.label')}
                    name="alias"
                    component={FormOutlinedTextField}
                    type="text"
                    required
                    validate={[required]}
                  />
                  <Field
                    disabled={submitting || fetchingGeneratedCredentials}
                    label={t('users:form.pin.label')}
                    name="pin"
                    component={FormOutlinedTextField}
                    type="number"
                    minLength={4}
                    maxLength={4}
                    required
                    validate={[required, exactLength4]}
                  />
                </>
              )}
              <Field
                disabled={submitting}
                label={t('users:form.externalId.label')}
                name="external_id"
                component={FormOutlinedTextField}
                type="text"
                validate={[exactLength6]}
              />
            </>
          )}
          {errorMessage && errorMessage}
        </Form>
      )}
    </>
  )
}

interface StateProps {
  userRoleScope: number
  formSyncErrors: FormErrors<CreateUserFormErrors>
}

const selector = formValueSelector(CREATE_USERS_FORM_ID)
const mapStateToProps: MapStateToProps<
  InferProps<typeof CreateUserForm>,
  StateProps
> = (state) => ({
  userRoleIdScope: Number(selector(state, 'role_id')),
  formSyncErrors: getFormSyncErrors(CREATE_USERS_FORM_ID)(state),
})
const mapDispatchToProps: MapDispatchToProps<
  InferProps<typeof CreateUserForm>,
  StateProps
> = (dispatch) => ({
  updateField: (field: string, data: any) =>
    dispatch(change(CREATE_USERS_FORM_ID, field, data)),
  touchField: (field: string) => dispatch(touch(CREATE_USERS_FORM_ID, field)),
})

CreateUserForm.propTypes = CreateUserFormTypes

export default compose(
  withTranslation(['users']),
  reduxForm({
    form: CREATE_USERS_FORM_ID,
    validate,
    touchOnChange: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(CreateUserForm)
