import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Routes from '../../../../constants/Routes'
import TasksListView from './TasksListView'
import TaskDetailView from './TaskDetailView'

class TasksView extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Tasks.url} component={TasksListView} />
        <Route path={Routes.Tasks.Detail.url} component={TaskDetailView} />
      </Switch>
    )
  }
}

export default TasksView
