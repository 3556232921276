import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { TextField, MenuItem, withStyles } from '@material-ui/core'
import { CountryCodes } from '../../constants'
import { filterDomProps } from '../../utils'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '8px',
  },

  input: {
    lineHeight: '36px',
    height: 36,
    fontWeight: 500,
  },

  textFieldInputUnderline: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
})

const CountryDropdown = ({ classes, t, label, value, ...rest }) => (
  <TextField
    select
    value={value}
    label={label}
    className={classes.root}
    InputProps={{
      classes: {
        underline: classes.textFieldInputUnderline,
        input: classes.input,
      },
    }}
    {...filterDomProps(rest)}
  >
    {Object.keys(CountryCodes).map((country) => (
      <MenuItem
        value={CountryCodes[country].ALPHA2CODE}
        key={country.toLowerCase()}
      >
        {t(`components:countryDropdown.${country.toLowerCase()}`)}
      </MenuItem>
    ))}
  </TextField>
)

CountryDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), withTranslation())(CountryDropdown)
