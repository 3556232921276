import AccountCircle from '@material-ui/icons/AccountCircle'
import ArrowBack from '@material-ui/icons/ArrowBack'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CropFreeIcon from '@material-ui/icons/CropFree'
import Folder from '@material-ui/icons/Folder'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import People from '@material-ui/icons/People'
import PermIdentity from '@material-ui/icons/PermIdentity'
import RoundedCorner from '@material-ui/icons/RoundedCorner'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import DevicesOtherOutlinedIcon from '@material-ui/icons/DevicesOtherOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import DeviceTelemetryIcon from '@material-ui/icons/RouterOutlined'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import GetApp from '@material-ui/icons/GetApp'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined'
import VideoCam from '@material-ui/icons/Videocam'

export {
  ArrowBackIosIcon as CollapseSidebarIcon,
  ArrowForwardIosIcon as ExpandSidebarIcon,
  AssessmentOutlinedIcon as ReportsIcon,
  CropFreeIcon as QRCodeIcon,
  DevicesOtherOutlinedIcon as DevicesIcon,
  DashboardOutlinedIcon as DashboardIcon,
  AccountCircle as AccountIcon,
  ArrowBack as GoBackIcon,
  Folder as DataIcon,
  FiberManualRecord as ServerConnStatus,
  People as WorkersIcon,
  PermIdentity as IdentityIcon,
  RoundedCorner as PlantIcon,
  SettingsOutlinedIcon as SettingsIcon,
  HelpOutlineOutlinedIcon as HelpIcon,
  GetApp as DownloadIcon,
  GroupOutlinedIcon,
  PersonOutlineOutlinedIcon,
  ListAltIcon as TasksIcon,
  InfoIcon,
  CheckCircleOutline,
  DeleteForeverIcon,
  DeviceTelemetryIcon,
  SpeakerNotesOutlinedIcon as SurveysIcon,
  DateRangeOutlinedIcon as WorklogIcon,
  VideoCam as PreScreeningIcon,
}
