import { withStyles, InputBase } from '@material-ui/core'

const WearHealthInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
    background: 'linear-gradient(#fff 80%, #c5d9e8 180%)',
    border: '1px solid #c5d9e8',
    fontSize: 15,
    padding: '14px 26px 14px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

export default WearHealthInput
