import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Skeleton } from '@material-ui/lab'

export const TableCellSkeleton = ({ numberOfCells = 5 }) => {
  let totalCells = []
  let totalRows = []

  for (let i = 0; i < numberOfCells; i++) {
    totalCells.push(
      <TableCell key={i} align="left">
        <Skeleton variant="text" />
      </TableCell>,
    )
  }

  for (let i = 0; i < 10; i++) {
    totalRows.push(<TableRow key={i}>{totalRows}</TableRow>)
  }

  return totalCells
}

export const TableRowSkeleton = ({ cells, rows }) => {
  let totalRows = []

  for (let i = 0; i < rows; i++) {
    totalRows.push(
      <TableRow key={i}>
        <TableCellSkeleton numberOfCells={cells} />
      </TableRow>,
    )
  }

  return totalRows
}
