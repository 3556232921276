import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from '../../../../constants'
import EventsListView from './EventsListView'

class DeviceTelemetryView extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={Routes.DeviceTelemetry.url}
          component={EventsListView}
        />
      </Switch>
    )
  }
}

export default DeviceTelemetryView
