import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  changeRowsPerPage,
  changePage,
} from '../../../../redux/worklog/worklog.actions'
import WorklogTable, { availableColumns } from '../../../molecules/WorklogTable'

const WorklogListTable = ({
  worklogList,
  fetching,
  pagination,
  changeRowsPerPageHandler,
  changePageHandler,
  clickWorklogRowHandler,
  error,
}) => (
  <WorklogTable
    onChangePage={changePageHandler}
    onChangeRowsPerPage={changeRowsPerPageHandler}
    onClickWorklogRow={clickWorklogRowHandler}
    worklogList={worklogList}
    fetching={fetching}
    error={error}
    pagination={pagination}
    columnsToDisplay={[
      availableColumns.USER_ID,
      availableColumns.USER_EMAIL,
      availableColumns.START_TIME,
      availableColumns.END_TIME,
      availableColumns.UTC_OFFSET,
      availableColumns.ID,
      availableColumns.TASK_NAME,
    ]}
  />
)

const mapStateToProps = (state) => {
  return {
    worklogList: state.worklog.worklogList,
    fetching: state.worklog.isFetching,
    pagination: state.worklog.pagination,
    error: state.worklog.errorMessagge,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRowsPerPageHandler: (e, pagination) =>
    dispatch(changeRowsPerPage(e, pagination)),
  changePageHandler: (page, pagination) =>
    dispatch(changePage(page, pagination)),
})

WorklogListTable.propTypes = {
  worklogList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  changeRowsPerPageHandler: PropTypes.func.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  clickWorklogRowHandler: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorklogListTable)
