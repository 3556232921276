import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { userRolesSelector } from '../../redux/user/user.selectors'
import { hasAnyOfRequiredRoles } from '../../utils'

const Restricted = ({
  children,
  anyOfRequiredRoles,
  replacement = null,
  userRoles,
}) =>
  hasAnyOfRequiredRoles(userRoles, anyOfRequiredRoles) ? children : replacement

const mapStateToProps = (state) => ({
  userRoles: userRolesSelector(state),
})

Restricted.propTypes = {
  children: PropTypes.node,
  replacement: PropTypes.node,
  anyOfRequiredRoles: PropTypes.array,

  userRoles: PropTypes.array,
}

export default connect(mapStateToProps)(Restricted)
