import { createRoute, fetchWithErrors } from '../utils'
import { Endpoints } from '../constants'

export const createNewTool = (formData) =>
  fetchWithErrors(Endpoints.Tools.Urls.Create, {
    method: 'POST',
    body: JSON.stringify(formData),
  })

export const deleteTool = (toolId) =>
  fetchWithErrors(createRoute(Endpoints.Tools.Urls.Delete, { toolId }), {
    method: 'DELETE',
  })
