import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import WorkersDropdown from './WorkersDropdown'

const inputStyles = (theme) => ({
  selectField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 24,
    fontWeight: 500,
  },
})

const WorkersDropdownField = ({
  label,
  input,
  classes,
  meta: { touched, invalid, error },
  ...rest
}) => {
  return (
    <WorkersDropdown
      label={label}
      variant="outlined"
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      className={classes.selectField}
      {...input}
      {...rest}
    />
  )
}

WorkersDropdownField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  classes: PropTypes.any,
  meta: PropTypes.object,
}

export default withStyles(inputStyles)(WorkersDropdownField)
