import React, { Component } from 'react'
import { Field, Form, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { required } from '../../../../../utils'
import { withTranslation } from 'react-i18next'
import { CREATE_TASK_FORM_ID } from '../../../../../constants'
import { compose } from 'redux'
import {
  ControlledAccordion,
  FormCheckbox,
  FormOutlinedTextField,
} from '../../../../molecules'
import {
  Box,
  CircularProgress,
  Divider,
  FormGroup,
  FormLabel,
  FormHelperText,
  Grid,
  withStyles,
} from '@material-ui/core'
import { DeleteForeverIcon, InfoIcon } from '../../../../atoms'

const styles = () => ({
  checkboxField: {
    display: 'inline',
  },
  divider: {
    marginBottom: '24px',
  },
  formLabel: {
    marginBottom: '16px',
  },
  informationBox: {
    borderLeft: '5px solid #9b57cf',
    backgroundColor: 'rgba(155, 87, 207, 0.2)',
    padding: '8px 12px',
    borderRadius: '4px',
    marginBottom: '12px',
  },
  informationIcon: {
    color: '#9b57cf',
  },
})

class CreateTaskForm extends Component {
  render() {
    const {
      classes,
      handleSubmit,
      t,
      toolsList,
      submitting,
      handleDeleteTool,
      isFetchingToolsList,
      fetchingToolsListError,
    } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormLabel component="legend" className={classes.formLabel}>
          {t('tasks:form.formGroup.details.title')}
        </FormLabel>
        <FormGroup>
          <Field
            disabled={submitting}
            label={t('tasks:form.name.label')}
            name="name"
            component={FormOutlinedTextField}
            type="text"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('tasks:form.description.label')}
            name="description"
            component={FormOutlinedTextField}
            multiline
            rows={4}
          />
        </FormGroup>
        <Divider variant="middle" className={classes.divider} />
        <FormGroup>
          <FormLabel component="legend" className={classes.formLabel}>
            {t('tasks:form.formGroup.tools.title')}
          </FormLabel>
          {isFetchingToolsList && <CircularProgress />}
          {!isFetchingToolsList &&
            toolsList.length > 0 &&
            toolsList.map((tool, i) => (
              <ControlledAccordion
                key={tool.id}
                preventExpansion={!!tool.deleted_at}
                expandIcon={<DeleteForeverIcon />}
                actionButtonHandler={() => handleDeleteTool(tool.id)}
                summaryComponent={
                  <Field
                    className={classes.checkboxField}
                    name={`tools.${tool.id}`}
                    label={
                      tool.deleted_at
                        ? `${tool.name} (${t('global:deleted')})`
                        : tool.name
                    }
                    disabled={submitting}
                    component={FormCheckbox}
                    formControlLabelProps={{
                      onClick: (event) => event.stopPropagation(),
                    }}
                    type="checkbox"
                  />
                }
                detailComponent={t('tasks:form.messages.deleteTool', {
                  toolName: tool.name,
                })}
              />
            ))}
          {!isFetchingToolsList && toolsList.length > 0 && (
            <FormHelperText>
              {t('tasks:form.formGroup.tools.helperText')}
            </FormHelperText>
          )}
          {!isFetchingToolsList && toolsList.length === 0 && (
            <Box
              component="span"
              display="block"
              className={classes.informationBox}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <InfoIcon className={classes.informationIcon} />{' '}
                </Grid>
                <Grid item xs={11}>
                  {t('tasks:form.messages.noToolsFound')}
                </Grid>
              </Grid>
            </Box>
          )}
          {fetchingToolsListError}
        </FormGroup>
      </Form>
    )
  }
}

CreateTaskForm.propTypes = {
  classes: PropTypes.object.isRequired,
  toolsList: PropTypes.array.isRequired,
  handleDeleteTool: PropTypes.func.isRequired,
  isFetchingToolsList: PropTypes.bool,
  fetchingToolsListError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  withTranslation(['tasks']),
  withStyles(styles),
  reduxForm({
    form: CREATE_TASK_FORM_ID,
  }),
)(CreateTaskForm)
