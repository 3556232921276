import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { setReportName, isSearching } from '../../redux/reports/reports.actions'
import { connect } from 'react-redux'
import SearchInput from '../atoms/SearchInput'
import { _ } from '../../lib'

const SearchByNameInput = ({
  onSearchByName,
  t,
  reportsQuery: { searchString, searchIsFocus },
  isSearching,
  isFetching,
}) => {
  let debouncedFn = null

  const handleChangeSearchByName = (e) => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = _.debounce(() => {
        onSearchByName(e.target.value)
      }, 700)
    }

    debouncedFn()
  }

  return (
    <SearchInput
      isFetching={isFetching}
      isFocused={searchIsFocus}
      onInputFocusBlur={isSearching}
      onSearchChange={handleChangeSearchByName}
      placeholderDefault={t('components:searchByNameInput.label')}
      searchVal={searchString}
    />
  )
}

SearchByNameInput.propTypes = {
  onSearchByName: PropTypes.func.isRequired,
  reportsQuery: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isSearching: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    reportsQuery: state.reports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchByName: (name) => dispatch(setReportName(name)),
    isSearching: () => dispatch(isSearching()),
  }
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchByNameInput)

const translated = withTranslation(['components'])(connected)

export default translated
