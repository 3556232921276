import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'

import FormOutlinedTextField from './FormOutlinedTextField'
import { required } from '../../utils'
import { AppTestIds } from '../../constants'

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formField: {
    marginBottom: '16px',
  },
  icon: {
    fontSize: '4rem',
    '&.green': {
      color: green[500],
    },
    '&.red': {
      color: red[500],
    },
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
})

const GroupForm = (props) => {
  const { handleSubmit, t, submitting } = props

  const inputDisabled = submitting

  return (
    <form onSubmit={handleSubmit}>
      <Field
        disabled={inputDisabled}
        name="name"
        type="text"
        component={FormOutlinedTextField}
        label={t('groups:form.name.label')}
        validate={[required]}
        inputProps={{
          'app-testid': AppTestIds.Groups.InputName,
        }}
        required
      />
      <Field
        disabled={inputDisabled}
        name="description"
        type="text"
        component={FormOutlinedTextField}
        label={t('groups:form.description.label')}
        validate={[required]}
        inputProps={{
          'app-testid': AppTestIds.Groups.InputDesc,
        }}
        required
      />
    </form>
  )
}

GroupForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default compose(
  withTranslation(['groups']),
  withStyles(styles),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  }),
)(GroupForm)
