import config from '../utils/config'

const DEFAULT_PAGINATION_OPTIONS = {
  rowsPerPage: 25,
  rowsPerPageOptions: [5, 10, 15, 25],
}

// For E2E testing
if (config.getEnv() === 'local' || config.getEnv() === 'development') {
  DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions.unshift(1)
}

export { DEFAULT_PAGINATION_OPTIONS }
