import { DialogActionTypes } from './dialog.types'

const INITIAL_STATE = {
  dialogType: null,
  dialogProps: {},
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DialogActionTypes.SHOW_DIALOG:
      return {
        dialogProps: action.dialogProps,
        dialogType: action.dialogType,
        type: action.type,
      }
    case DialogActionTypes.HIDE_DIALOG:
      return INITIAL_STATE
    default:
      return state
  }
}

export default reducer
