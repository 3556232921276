import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { CREATE_USERS_FORM_ID } from '../../../../constants'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { SubmissionError, reset, getFormSyncErrors } from 'redux-form'
import { compose } from 'redux'
import { fetchUsersListStartAsync } from '../../../../redux/users/users.actions'
import { Button, Box } from '@material-ui/core'
import dayjs from 'dayjs'

import { _ } from '../../../../lib'
import ModalBox from '../../ModalBox'
import UsersListTable from './UsersListTable'
import CreateUserForm from './form/CreateUserForm'
import RemoteSubmitButton from '../../../atoms/RemoteSubmitButton'
import { createNewUser } from '../../../../api/api.users'

const initialSteps = [
  {
    title: 'Role',
    caption: '',
    selectedOption: null,
  },
  {
    title: 'Login method',
    caption: '',
    selectedOption: null,
  },
  {
    title: 'Demographics',
    caption: 'Optional',
  },
  {
    title: 'Credentials',
    caption: '',
  },
]

class UsersListView extends Component {
  state = {
    activeStep: 0,
    isNextDisabled: true,
    skipped: new Set(),
    openFormDialog: false,
    steps: initialSteps,
  }

  async componentDidMount() {
    this.props.fetchUsersList()
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.createUserFormState, prevProps.createUserFormState)
    ) {
      this.handleActiveStepValidity()
    }
  }

  handleOpenFormDialog = () => this.setState({ openFormDialog: true })

  handleCloseFormDialog = () =>
    this.setState({ openFormDialog: false }, () => {
      this.setState({ steps: initialSteps, activeStep: 0 })
      this.props.resetForm(CREATE_USERS_FORM_ID)
      this.handleUpdateStepCaption(0, '')
      this.handleUpdateStepCaption(1, '')
    })

  handleNext = () => {
    const { activeStep, skipped } = this.state
    let newSkipped = skipped

    if (this.isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    this.setState(
      {
        activeStep: activeStep + 1,
      },
      () => {
        this.setState({
          skipped: newSkipped,
          isNextDisabled: true,
        })
      },
    )
  }

  handleActiveStepValidity = () => {
    const {
      createUserFormState: { values: formValues },
      createUserFormSyncErrors,
    } = this.props
    const { activeStep } = this.state

    if (activeStep === 0) {
      if (formValues && formValues.role_id) {
        this.setState({ isNextDisabled: false })
      }
    }

    if (activeStep === 1) {
      this.setState({ isNextDisabled: false })
    }

    if (activeStep === 2) {
      const demographicsExistInFormValues =
        Object.prototype.hasOwnProperty.call(formValues, 'demographics')

      if (
        !demographicsExistInFormValues ||
        (demographicsExistInFormValues &&
          !createUserFormSyncErrors.allDemographics &&
          demographicsExistInFormValues &&
          !Object.prototype.hasOwnProperty.call(
            createUserFormSyncErrors,
            'demographics',
          ))
      ) {
        this.setState({ isNextDisabled: false })
      } else {
        this.setState({ isNextDisabled: true })
      }
    }
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleUpdateStepCaption = (activeStep, caption) => {
    const { steps } = this.state

    steps[activeStep].caption = caption

    this.setState({
      steps,
    })
  }

  isStepSkipped = (step) => this.state.skipped.has(step)

  submitCreateUserForm = async (values) => {
    const { tenantId, fetchUsersList } = this.props
    // should be based on the currently logged in user's tenant!!
    values.tenant_id = tenantId

    if (values.demographics) {
      values.demographics.birthday = dayjs(values.demographics.birthday).format(
        'YYYY-MM-DD',
      )
      // convert kg to grams (per API spec)
      values.demographics.weight = values.demographics.weight * 1000
    }

    const rootResponse = await createNewUser(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'USER_CREATED'
    ) {
      this.handleCloseFormDialog()
      fetchUsersList()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const { t } = this.props
    const { activeStep, skipped, steps, openFormDialog, isNextDisabled } =
      this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.users')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('users:list.createModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openFormDialog}
          paperSize={'lg'}
          actionButton={
            <>
              {activeStep > 0 && (
                <Button variant="contained" onClick={this.handleBack}>
                  {'Back'}
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  disabled={isNextDisabled}
                >
                  {'Next'}
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <RemoteSubmitButton
                  formId={CREATE_USERS_FORM_ID}
                  appTestId={'create-user-form-button-id'}
                />
              )}
            </>
          }
        >
          <CreateUserForm
            activeStep={activeStep}
            onSubmit={this.submitCreateUserForm}
            isStepSkipped={this.isStepSkipped}
            steps={steps}
            updateStepCaptionHandler={this.handleUpdateStepCaption}
            skipped={skipped}
          />
        </ModalBox>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px 8px -8px' }}
        >
          <Box p={1}>
            <Button
              aria-label="add user"
              variant="contained"
              color="primary"
              onClick={this.handleOpenFormDialog}
            >
              {t('add') + ' ' + t('user')}
            </Button>
          </Box>
        </Box>

        <UsersListTable />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  tenantId: state.user.tenantId,
  createUserFormState: state.form[CREATE_USERS_FORM_ID],
  createUserFormSyncErrors: getFormSyncErrors(CREATE_USERS_FORM_ID)(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchUsersList: () => dispatch(fetchUsersListStartAsync()),
  resetForm: () => dispatch(reset(CREATE_USERS_FORM_ID)),
})

UsersListView.propTypes = {
  createUserFormState: PropTypes.object,
  createUserFormSyncErrors: PropTypes.shape({
    role_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    allDemographics: PropTypes.string,
    demographics: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    email: PropTypes.string,
    alias: PropTypes.string,
    pin: PropTypes.string,
    external_id: PropTypes.string,
  }).isRequired,
  fetchUsersList: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resetForm: PropTypes.func.isRequired,
}

const UsersListViewComposed = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(UsersListView)

export default UsersListViewComposed
