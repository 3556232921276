import React, { Component } from 'react'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles, CircularProgress, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Endpoints from '../../../../constants/Endpoints'
import {
  createRoute,
  fetchWithAuthHeaders,
  mapRecordForPresentation,
} from '../../../../utils'
import { formatPercentage, toNum } from '../../../../utils/data-formatter'
import BarChart from '../../charts/BarChart'
import LineChart from '../../charts/LineChart'
import MotionMetricsChart from '../../charts/MotionMetricsChart'
import { SummaryCards } from '../../../molecules/SummaryCards'
import { LinearProgressWithLabel } from '../../../atoms'
import { withTranslation } from 'react-i18next'
import ReportDetailHeader from './ReportDetailHeader'
import dashCardsModel from '../../SummaryCards/summary-cards-model'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  statsPaper: {
    height: '152px',
  },
  circular: {
    position: 'absolute',
    top: '50%',
  },
  graphPaper: {
    height: `472px`,
    position: 'relative',
  },
  graphContainer: {
    height: '360px',
  },
  summaries: {
    width: 300,
  },
  paper: {
    borderRadius: '0px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
})

class ReportsDetailView extends Component {
  state = {
    dataHasLoaded: false,
    heartRate: {
      labels: [],
      data: [],
      min: null,
      max: null,
      avg: null,
      timeOffset: null,
    },
    dataPhysical: {
      labels: [],
      data: [],
      max: null,
      min: null,
      timeOffset: null,
    },
    dataMental: {
      labels: [],
      data: [],
      max: null,
      min: null,
      timeOffset: null,
    },
    dataMotionMetrics: {
      datasets: [],
      max: null,
      min: null,
      timeOffset: null,
    },
    dataWorkload: {
      labels: [],
      data: [],
      max: null,
      min: null,
      timeOffset: null,
    },
  }

  async componentDidMount() {
    const {
      match: {
        params: { reportId },
      },
    } = this.props

    fetchWithAuthHeaders(
      createRoute(Endpoints.Record.Urls.Detail, { recordId: reportId }),
    )
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          ...mapRecordForPresentation(JSON.parse(result).response),
          dataHasLoaded: true,
        })
      })
  }

  render() {
    const { classes, t } = this.props
    const { dataHasLoaded } = this.state

    let physicalChart = { average: null, name: '' }
    let mentalChart = { average: null, name: '' }
    let workloadChart = { average: null, name: '' }

    let dashCards = dashCardsModel(t)

    if (dataHasLoaded) {
      const {
        dataPhysical,
        dataMental,
        dataWorkload,
        duration,
        avgPhyRisk,
        avgMentRisk,
        avgWorkloadRisk,
        totalCal,
        demographic: { group },
      } = this.state

      physicalChart.name = group ? group.name : 'Unassigned'
      physicalChart.average = group
        ? toNum(group.kpis.agg_avg_physical_load)
        : 0

      mentalChart.name = group ? group.name : 'Unassigned'
      mentalChart.average = group ? toNum(group.kpis.agg_avg_mental_load) : 0

      workloadChart.name = group ? group.name : 'Unassigned'
      workloadChart.average = group
        ? toNum(group.kpis.agg_avg_workload_risk)
        : 0

      // Set summary values physical
      dashCards.physical.data[0].value = toNum(avgPhyRisk)
      dashCards.physical.data[1].value = group
        ? toNum(group.kpis.agg_avg_physical_load)
        : 0

      // Physical benchmark
      const P_S = dashCards.physical.data[0].value
      const P_W = dashCards.physical.data[1].value
      dashCards.physical.data[2].value = formatPercentage(P_S, P_W)

      // Set physical KPIs (min, max, avg)
      dashCards.physicalKPIs.data[0].value = toNum(dataPhysical.max)
      dashCards.physicalKPIs.data[1].value = toNum(dataPhysical.min)
      dashCards.physicalKPIs.data[2].value = dashCards.physical.data[0].value

      // Set summary values calories
      dashCards.calories.data[0].value = totalCal
      dashCards.calories.data[1].value = group
        ? toNum((+duration / 60) * +group.kpis.agg_avg_kcal_per_minute, 0)
        : 0

      // Calories benchmark
      const C_S = dashCards.calories.data[0].value
      const C_W = dashCards.calories.data[1].value
      dashCards.calories.data[2].value = formatPercentage(C_S, C_W)

      // Set summary values mental
      dashCards.mental.data[0].value = toNum(avgMentRisk)
      dashCards.mental.data[1].value = group
        ? toNum(group.kpis.agg_avg_mental_load)
        : 0

      // Set summary values workload
      dashCards.workload.data[0].value = toNum(avgWorkloadRisk)
      dashCards.workload.data[1].value = group
        ? toNum(group.kpis.agg_avg_workload_risk)
        : 0

      // Mental benchmark
      const M_S = dashCards.mental.data[0].value
      const M_W = dashCards.mental.data[1].value
      dashCards.mental.data[2].value = formatPercentage(M_S, M_W)

      // Set mental KPIs (min, max, avg)
      dashCards.mentalKPIs.data[0].value = toNum(dataMental.max)
      dashCards.mentalKPIs.data[1].value = dataMental.min
      dashCards.mentalKPIs.data[2].value = dashCards.mental.data[0].value

      // Workload benchmark
      const W_S = dashCards.workload.data[0].value
      const W_W = dashCards.workload.data[1].value
      dashCards.workload.data[2].value = formatPercentage(W_S, W_W)

      // Set workload KPIs (min, max, avg)
      dashCards.workloadKPIs.data[0].value = toNum(dataWorkload.max)
      dashCards.workloadKPIs.data[1].value = dataWorkload.min
      dashCards.workloadKPIs.data[2].value = dashCards.workload.data[0].value

      // Heart Rate KPIs (min, max, avg)
      dashCards.heartRate.data[0].value = toNum(this.state.heartRate.max, 0)
      dashCards.heartRate.data[1].value = toNum(this.state.heartRate.min, 0)
      dashCards.heartRate.data[2].value = toNum(this.state.heartRate.avg, 0)
    }

    return (
      <>
        <Helmet>
          <title>
            {`
            PL: ${physicalChart.average}
            ML: ${mentalChart.average}
              ${
                dataHasLoaded ? this.state.demographic.fullName : ''
              } | WearHealth`}
          </title>
        </Helmet>

        <div className={classes.root}>
          <ReportDetailHeader
            data={
              dataHasLoaded
                ? {
                    ...this.state.demographic,
                    recordDate: this.state.date,
                    duration: this.state.durationInHRF,
                  }
                : undefined
            }
          />
        </div>
        <br />
        <div className={classes.root}>
          <br />
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={3}>
              <Paper
                align="left"
                spacing={1}
                className={[classes.paper, classes.statsPaper].join(' ')}
              >
                <Typography noWrap variant="h6" align="left">
                  {t('motionDataInSession')}
                </Typography>
                <Box style={{ height: 'calc(100% - 18px)' }}>
                  {dataHasLoaded &&
                    this.state.dataMotionMetrics.datasets.length > 0 &&
                    this.state.dataMotionMetrics.datasets.map(
                      (dataset, index) => (
                        <LinearProgressWithLabel
                          key={`dmm_${index}`}
                          value={Math.floor(
                            (dataset.duration / this.state.duration) * 100,
                          )}
                          label={t(
                            `deviceTelemetry:devicePosition.${dataset.label}`,
                          )}
                          devColor={index + 1}
                        />
                      ),
                    )}
                  {dataHasLoaded &&
                    this.state.dataMotionMetrics.datasets.length === 0 && (
                      <Typography
                        variant="body1"
                        align="center"
                        style={{ marginTop: 10 }}
                      >
                        {t('noData')}
                      </Typography>
                    )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
                <Typography variant="h6" align="left">
                  {t('motionSignalQuality')}
                </Typography>

                {!dataHasLoaded ? (
                  <CircularProgress className={classes.circular} size="48px" />
                ) : null}
                <MotionMetricsChart
                  chartId="chart_0"
                  data={this.state.dataMotionMetrics}
                  height={360}
                />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <SummaryCards
                classes={[classes.paper, classes.statsPaper]}
                metrics={dashCards.workload}
                type={dashCards.workload.type}
              />
            </Grid>
            <Grid item xs={9}>
              <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
                <Typography variant="h6" align="left">
                  {t('workload')}
                </Typography>

                {!dataHasLoaded ? (
                  <CircularProgress className={classes.circular} size="48px" />
                ) : null}
                <BarChart
                  group={workloadChart}
                  chartId="chart_3"
                  data={this.state.dataWorkload}
                  height={360}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={3}>
              <SummaryCards
                classes={[classes.paper, classes.statsPaper]}
                metrics={dashCards.physical}
                type={dashCards.physical.type}
              />

              <SummaryCards
                classes={[classes.paper, classes.statsPaper]}
                metrics={dashCards.calories}
                type={dashCards.physical.type}
              />
            </Grid>
            <Grid item xs={9}>
              <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
                <Typography variant="h6" align="left">
                  {t('physical') + ' ' + t('load')}
                </Typography>
                {!dataHasLoaded ? (
                  <CircularProgress className={classes.circular} size="48px" />
                ) : null}

                <BarChart
                  group={physicalChart}
                  chartId="chart_1"
                  data={this.state.dataPhysical}
                  height={380}
                  width={800}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <br />
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={3}>
              <SummaryCards
                classes={[classes.paper, classes.statsPaper]}
                metrics={dashCards.mental}
                type={dashCards.mental.type}
              />
            </Grid>
            <Grid item xs={9}>
              <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
                <Typography variant="h6" align="left">
                  {t('mental') + ' ' + t('load')}
                </Typography>

                {!dataHasLoaded ? (
                  <CircularProgress className={classes.circular} size="48px" />
                ) : null}
                <BarChart
                  group={mentalChart}
                  chartId="chart_2"
                  data={this.state.dataMental}
                  height={360}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={3}>
              <SummaryCards
                classes={[classes.paper, classes.statsPaper]}
                metrics={dashCards.heartRate}
                type={dashCards.heartRate.type}
              />
            </Grid>
            <Grid item xs={9}>
              <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
                <Typography variant="h6" align="left">
                  {t('heartRate')}
                </Typography>

                {!dataHasLoaded ? (
                  <CircularProgress className={classes.circular} size="48px" />
                ) : null}
                <LineChart
                  chartId="chart_3"
                  data={this.state.heartRate}
                  height={360}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

ReportsDetailView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withRouter,
  withTranslation(['global', 'deviceTelemetry']),
)(ReportsDetailView)
