import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const TypographyPlaceholder = ({ msg }) => {
  return (
    <Typography noWrap variant="caption">
      {msg}
    </Typography>
  )
}

TypographyPlaceholder.propTypes = {
  msg: PropTypes.string.isRequired,
}

export default TypographyPlaceholder
