import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, withStyles } from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { Form, Field, reduxForm } from 'redux-form'

import { REGISTER_ADMIN_FORM_ID, Routes } from '../../../../constants'
import { CountryDropdownField, FormTextField } from '../../../molecules'
import { mobilePhoneNo, required } from '../../../../utils'

const styles = (theme) => ({
  typographyHeader: {
    marginBottom: '18px',
  },
  formActionsContainerStyle: {
    textAlign: 'center',
    marginTop: '48px',
    marginBottom: '48px',
  },
  formErrorContainerStyle: {
    display: 'block',
    color: 'red',
    fontSize: '14px',
    maxWidth: '424px',
  },
  registerBtn: {
    width: '50%',
    margin: '0 auto',
  },
})

const validate = (values) => {
  const errors = []

  if (!(values.password === values.repeat_password))
    errors.repeat_password = 'repeat_password_mismatch'

  return errors
}

class RegisterAdminSubview extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { classes, handleSubmit, submitting, t, error } = this.props

    return (
      <>
        <Helmet>
          <title>{t(Routes.RegisterAdmin.title)}</title>
        </Helmet>
        <Typography
          className={classes.typographyHeader}
          variant="h4"
          align="center"
        >
          {t('authentication:registerAdmin.title')}
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Field
            disabled
            label={t('authentication:registerAdmin.form.email.label')}
            name="email"
            component={FormTextField}
            type="email"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.firstName.label')}
            name="first_name"
            component={FormTextField}
            type="text"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.lastName.label')}
            name="last_name"
            component={FormTextField}
            type="text"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.password.label')}
            name="password"
            component={FormTextField}
            type="password"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.repeatPassword.label')}
            name="repeat_password"
            component={FormTextField}
            required
            type="password"
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.tenantName.label')}
            name="name"
            component={FormTextField}
            normalize={(value) =>
              value && value.toUpperCase().replace(' ', '_')
            }
            type="text"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t('authentication:registerAdmin.form.country.label')}
            name="country"
            component={CountryDropdownField}
            type="select"
            required
            validate={[required]}
          />
          <Field
            disabled={submitting}
            label={t(
              'authentication:registerAdmin.form.mobilePhoneNumber.label',
            )}
            name="phone_number"
            component={FormTextField}
            type="tel"
            validate={[mobilePhoneNo]}
          />
          {error && (
            <span className={classes.formErrorContainerStyle}>
              {t(`authentication:registerAdmin.form.errors.${error}`)}
            </span>
          )}
          <div className={classes.formActionsContainerStyle}>
            <Button
              disabled={submitting}
              type="submit"
              variant="contained"
              color="primary"
              component="button"
              className={classes.registerBtn}
            >
              {!submitting && (
                <span>
                  {t('authentication:registerAdmin.form.registerButton')}
                </span>
              )}
              {submitting && (
                <BeatLoader
                  sizeUnit={'px'}
                  size={9}
                  color={'#fff'}
                  loading={submitting}
                />
              )}
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

export default compose(
  withTranslation(['authentication', 'validation']),
  withStyles(styles),
  reduxForm({
    form: REGISTER_ADMIN_FORM_ID,
    enableReinitialize: true,
    validate,
  }),
)(RegisterAdminSubview)
