import React from 'react'
import PropTypes from 'prop-types'
import { unauthenticate } from '../../utils'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { flushUserSettings } from '../../redux/user/user.actions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0 0 0`,
    background: '#fff',
    opacity: 0.95,
    padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
    height: '36px',
    display: 'block',
    fontWeight: 'bold',

    '&:hover': {
      opacity: 1,
      background: '#fff',
    },
  },
}))

const LogoutButton = ({ flushPersistor }) => {
  const classes = useStyles()

  const handleLogout = () => {
    unauthenticate().then(() => {
      flushPersistor()
      window.location.href = '/login'
    })
  }

  return (
    <Button
      className={classes.root}
      variant="outlined"
      size="small"
      color="secondary"
      onClick={handleLogout}
    >
      Logout
    </Button>
  )
}

LogoutButton.propTypes = {
  flushPersistor: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return {
    flushPersistor: () => dispatch(flushUserSettings()),
  }
}

export default connect(null, mapDispatchToProps)(LogoutButton)
