import dayjs from 'dayjs'

import { getAge, secondsToDuration } from '../../time-calc'
import {
  datasetMapper,
  formatMotionMetricsData,
  formatRawData,
  groupData,
  sumAverage,
} from '../../data-formatter'
import { roundFloat } from '../../ui-fns'

const mapDemoForPresentation = (demographic) => ({
  fullName: `${demographic.user.first_name} ${demographic.user.last_name}`,
  sex: demographic.user.sex.toUpperCase(),
  age: getAge(demographic.user.birthday),
  weight: `${roundFloat(demographic.weight / 1000)} kg`,
  height: `${demographic.height} cm`,
  group: demographic.group ? { ...demographic.group } : null,
})

export const mapRecordForPresentation = (recordDetails) => {
  const tzOffset =
    recordDetails &&
    Object.prototype.hasOwnProperty.call(recordDetails, 'timezone_offset')
      ? recordDetails.timezone_offset
      : '+00:00'

  const formattedHRData = formatRawData(recordDetails.heart_rate_signal)
  const formattedMotionMetricsData = formatMotionMetricsData(
    recordDetails.motion_metrics,
    tzOffset,
  )
  const physicalLoadValues = Object.values(recordDetails.physical_load_signal)
  const mentalLoadValues = Object.values(recordDetails.mental_load_signal)
  const workloadValues = Object.values(recordDetails.workload_signal)

  const groupedMentalData = groupData(
    formatRawData(recordDetails.mental_load_signal),
    'mental',
  )
  const groupedPhysicalData = groupData(
    formatRawData(recordDetails.physical_load_signal),
    'physical',
  )
  const groupedWorkloadData = groupData(
    formatRawData(recordDetails.workload_signal),
    'workload',
  )

  return {
    avgPhyRisk: recordDetails.avg_physical_load_risk,
    avgMentRisk: recordDetails.avg_mental_load_risk,
    avgWorkloadRisk: recordDetails.avg_workload_risk,
    demographic: mapDemoForPresentation(recordDetails.demographic),
    date: dayjs(recordDetails.record_date).format('dddd, DD.M.YYYY'),
    duration: recordDetails.duration,
    durationInHRF: secondsToDuration(recordDetails.duration),
    heartRate: {
      labels: formattedHRData.dataTime.map((t) => t.hour),
      data: formattedHRData.data,
      max: Math.max(...formattedHRData.data),
      min: Math.min(...formattedHRData.data),
      avg: sumAverage(formattedHRData.data),
      timeOffset: tzOffset,
    },
    dataPhysical: {
      labels: groupedPhysicalData.timeLabels.map((t) => t.hour),
      data: datasetMapper(groupedPhysicalData.data),
      max: Math.max(...physicalLoadValues),
      min: Math.min(...physicalLoadValues),
      avg: recordDetails.avg_physical_load_risk,
      timeOffset: tzOffset,
    },
    dataMental: {
      labels: groupedMentalData.timeLabels.map((t) => t.hour),
      data: datasetMapper(groupedMentalData.data),
      max: Math.max(...mentalLoadValues),
      min: Math.min(...mentalLoadValues),
      avg: recordDetails.avg_mental_load_risk,
      timeOffset: tzOffset,
    },
    dataMotionMetrics: {
      datasets: formattedMotionMetricsData,
      max: 1,
      min: 0,
      timeOffset: tzOffset,
    },
    dataWorkload: {
      labels: groupedWorkloadData.timeLabels.map((t) => t.hour),
      data: datasetMapper(groupedWorkloadData.data),
      max: Math.max(...workloadValues),
      min: Math.min(...workloadValues),
      avg: recordDetails.avg_workload_risk,
      timeOffset: tzOffset,
    },
    totalCal: recordDetails.total_calories,
  }
}
