import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select, FormControl, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { Endpoints, AppTestIds } from '../../constants'
import { fetchWithErrors } from '../../utils'
import WearHealthInput from '../atoms/WearHealthInput'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import { setReportGroup } from '../../redux/reports/reports.actions'
import { connect } from 'react-redux'
import { setDashGroup } from '../../redux/dashboard/dashboard.actions'

const styles = (theme) => ({
  formControl: {
    minWidth: '174px',
    border: 'none',
  },

  neutralSelect: {
    padding: '0px 12px',
  },
})

class GroupDropdown extends Component {
  state = {
    groups: [],
    fetching: false,
    fetchingError: false,
  }

  async componentDidMount() {
    await this.fetchGroups()
  }

  fetchGroups = async () => {
    !this.unmount && this.setState({ fetching: true })

    const rootResponse = await fetchWithErrors(Endpoints.Group.Urls.List)

    if (!('error' in rootResponse) && !this.state.fetchingError) {
      const enclosedResponse = rootResponse.response

      if (!('error' in rootResponse)) {
        !this.unmount &&
          this.setState({ groups: enclosedResponse, fetching: false })
      } else {
        !this.unmount &&
          this.setState({
            fetching: false,
            fetchingError: true,
          })
        console.log('ERROR', enclosedResponse)
      }
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchingError: true,
        })
      console.log('ERROR', rootResponse)
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  render() {
    const { groups: data, fetching } = this.state
    const { t, classes, groupCode } = this.props

    let groupOptions = null

    if (data.length > 0) {
      groupOptions = data.map((w) => (
        <MenuItem key={w.id} value={w.id}>
          {w.name}
        </MenuItem>
      ))
    }

    return (
      <>
        <FormControl className={classes.formControl}>
          <Select
            disabled={false}
            value={data.length === 0 ? 'all' : groupCode}
            displayEmpty
            app-testid={AppTestIds.Filters.GroupDropdown}
            onChange={(e) => this.props.onGroupChange(e.target.value)}
            IconComponent={KeyboardArrowDownOutlinedIcon}
            input={<WearHealthInput />}
            label={t('components:groupDropdown.label')}
          >
            <MenuItem value="all">
              {t('components:groupDropdown.options.all')}
            </MenuItem>
            {!fetching && groupOptions}
          </Select>
        </FormControl>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  switch (props.reducer) {
    case 'REPORTS': {
      return {
        groupCode: state.reports.groupCode,
      }
    }
    case 'DASHBOARD': {
      return {
        groupCode: state.dashboard.groupCode,
      }
    }
    default:
      throw new Error(
        'Component not connected to any reducer. Connect the component to a reducer.',
      )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  switch (props.reducer) {
    case 'REPORTS': {
      return {
        onGroupChange: (groupName) => dispatch(setReportGroup(groupName)),
      }
    }
    case 'DASHBOARD': {
      return {
        onGroupChange: (groupName) => dispatch(setDashGroup(groupName)),
      }
    }
    default:
      return null
  }
}

GroupDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object,
  onGroupChange: PropTypes.func.isRequired,
  groupCode: PropTypes.string.isRequired,
  // handleChangeGroup: PropTypes.func,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(GroupDropdown)

const styled = withStyles(styles)(connected)

export default withTranslation(['components'])(styled)
