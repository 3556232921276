const userStoreSelector = (state) => state.user

export const userAuthorizationSelector = (state) =>
  userStoreSelector(state).authorization

export const userTenantSelector = (state) =>
  userStoreSelector(state).permissions.tenants

export const userRolesSelector = (state) =>
  userStoreSelector(state).permissions.roles
