import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { semanticColorFromValue, roundFloat } from '../../utils/ui-fns'
import p from '../themes/styles/palette'
import { Skeleton } from '@material-ui/lab'

const { cyan, orange, green, red, yellow } = p

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    position: 'relative',
    bottom: '-16px',

    '& > small': {
      fontSize: '.62em',
      fontWeight: 600,
    },

    '&.lightblue': {
      color: cyan[600],
    },

    '&.green': {
      color: green[600],
    },
    '&.yellow': {
      color: yellow[700],
    },
    '&.darkorange': {
      color: orange['A400'],
    },
    '&.red': {
      color: red[500],
    },
  },
})

/**
 * Colorize number and strings, or numbers with string, based on value or desired color
 * Display a loading state when the value is empty string, null, undefined
 * @param {Number or String} value
 * @param {String} color
 * @returns {DOM}
 */

// TODO improve color scales to reflect 5 and 3 stages of colors
const ColorizeNumbers = ({ value, color, ...rest }) => {
  const classes = useStyles()

  if (!value && value !== 0) return <Skeleton animation="wave" variant="text" />

  if (!isNaN(value)) {
    value = roundFloat(+value)
  }

  const valueColor = [classes.root, semanticColorFromValue(value, color)].join(
    ' ',
  )

  return (
    <span className={[valueColor, rest.className || ' '].join(' ')}>
      {rest.children}
      {value !== 'children' ? value : null}
    </span>
  )
}

ColorizeNumbers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
}

export default ColorizeNumbers
