import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { TableRowSkeleton } from '../../../atoms/TableRowCellSkeleton'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import BadgePillow from '../../../atoms/BadgePillow'
import dayjs from 'dayjs'
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { secondsToHour } from '../../../../utils/time-calc'
import TableNoData from '../../../atoms/TableNoData'
import Restricted from '../../../atoms/Restricted'
import Roles from '../../../../constants/Roles'

const GroupListTable = ({
  fetching,
  t,
  pagination,
  groupList,
  handleClickGroupRow,
}) => {
  let tableRows = null

  if (fetching && groupList.length === 0) {
    tableRows = (
      <>
        <Restricted anyOfRequiredRoles={[Roles.ADMIN]}>
          <TableRowSkeleton cells={4} rows={pagination.rowsPerPage} />
        </Restricted>
        <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
          <TableRowSkeleton cells={6} rows={pagination.rowsPerPage} />
        </Restricted>
      </>
    )
  }

  if (!fetching && groupList.length === 0) {
    tableRows = (
      <>
        <Restricted anyOfRequiredRoles={[Roles.ADMIN]}>
          <TableNoData cols={4} />
        </Restricted>
        <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
          <TableNoData cols={6} />
        </Restricted>
      </>
    )
  }

  if (!fetching && groupList.length > 0) {
    tableRows = groupList.map((row) => (
      <TableRow
        hover
        style={{ cursor: 'pointer' }}
        key={row.id}
        onClick={(e) => handleClickGroupRow(e, row.id)}
      >
        <TableCell>
          <Typography variant="body1">
            <strong>{row.name}</strong>
          </Typography>
          <Typography variant="subtitle2">{row.description}</Typography>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              t('updated') +
              ': ' +
              dayjs(dayjs.utc(row.updated_at)).utc().fromNow()
            }
          >
            <span>{dayjs(dayjs.utc(row.created_at)).format('DD.M.YYYY')}</span>
          </Tooltip>
        </TableCell>
        <Restricted anyOfRequiredRoles={[Roles.ADMIN]}>
          <TableCell>
            {
              row.assigned_users.filter(
                (assignedUser) =>
                  assignedUser.role_name === Roles.MANAGER && assignedUser,
              )[0].count
            }
          </TableCell>
          <TableCell>
            {
              row.assigned_users.filter(
                (assignedUser) =>
                  assignedUser.role_name === Roles.WORKER && assignedUser,
              )[0].count
            }
          </TableCell>
        </Restricted>
        <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
          <TableCell align="center">
            <BadgePillow
              value={parseFloat(row.kpis.agg_avg_mental_load).toFixed(1)}
            />
          </TableCell>
          <TableCell align="center">
            <BadgePillow
              value={parseFloat(row.kpis.agg_avg_physical_load).toFixed(1)}
            />
          </TableCell>
          <TableCell align="center">
            {(row.kpis.agg_avg_kcal_per_minute * 60).toFixed(1)} kcal/h
          </TableCell>
          <TableCell align="right">
            {secondsToHour(row.kpis.agg_avg_duration) + ' ' + t('hours')}
          </TableCell>
        </Restricted>
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('groups:list.tableHeaders.name')}</TableCell>
            <TableCell>{t('groups:list.tableHeaders.createdOn')} </TableCell>
            <Restricted anyOfRequiredRoles={[Roles.ADMIN]}>
              <TableCell>
                {t('groups:list.tableHeaders.assignedManagers')}
              </TableCell>
              <TableCell>
                {t('groups:list.tableHeaders.assignedWorkers')}
              </TableCell>
            </Restricted>
            <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
              <TableCell align="center">
                {t('groups:list.tableHeaders.avgMentalLoadLvl')}
              </TableCell>
              <TableCell align="center">
                {t('groups:list.tableHeaders.avgPhysicalLoadLvl')}
              </TableCell>
              <TableCell align="center">
                {t('groups:list.tableHeaders.calPerHour')}
              </TableCell>
              <TableCell align="right">
                {t('groups:list.tableHeaders.avgSession')}
              </TableCell>
            </Restricted>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  )
}

GroupListTable.propTypes = {
  t: PropTypes.func,
  fetching: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  groupList: PropTypes.array.isRequired,
  handleClickGroupRow: PropTypes.func.isRequired,
}

export default withTranslation(['groups'])(GroupListTable)
