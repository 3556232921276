import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'
import rootReducer from './root-reducer'
import { ENV, whConsole } from '../config/environment'
import { persistStore } from 'redux-persist'
import { isAuthenticated } from '../utils/auth'

import { UserActionTypes } from './user/user.types'

if (ENV === 'local' || ENV === 'development') {
  import(
    /* webpackChunkName: "redux-logger" */
    '../../node_modules/redux-logger/dist/redux-logger'
  ).then((logger) => {
    console.log('logger loaded')
  })
}

/**
 * redux-state-sync here is whitelisted to only
 * trigger when 'FLUSH_USER_SETTINGS' is dispatched
 * (fixes issue with being logged in in multiple tabs)
 */
const middlewares = [
  thunk,
  createStateSyncMiddleware({
    whitelist: [UserActionTypes.FLUSH_USER_SETTINGS],
  }),
]
const disableLogger = true

if ((ENV === 'local' || ENV === 'development') && !disableLogger) {
  middlewares.push(logger)
}

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

const enhancer =
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true, trace: true })
if (!enhancer) {
  console.warn(
    'Install Redux DevTools Extension to inspect the app state: ' +
      'https://github.com/zalmoxisus/redux-devtools-extension#installation',
  )
}

if (module.hot) {
  module.hot.accept('../redux/root-reducer', () => {
    store.replaceReducer(rootReducer)
  })
}

export const store = createStoreWithMiddleware(rootReducer, enhancer)
export const persistor = persistStore(store)

initMessageListener(store)

window.wh_persist = persistor

if (!isAuthenticated()) {
  persistor.purge()
  whConsole('local storage purged')
}
