import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reports from './reports/reports.reducer'
import dashboard from './dashboard/dashboard.reducer'
import deviceTelemetry from './deviceTelemetry/deviceTelemetry.reducer'
import app from './ui/ui.reducer'
import user from './user/user.reducer'
import dialog from './dialog/dialog.reducer'
import users from './users/users.reducer'
import groups from './groups/groups.reducer'
import preScreeningAppMgmt from './preScreeningAppMgmt/preScreeningAppMgmt.reducer'
import tasks from './tasks/tasks.reducer'
import tools from './tools/tools.reducer'
import surveys from './surveys/surveys.reducer'
import worklog from './worklog/worklog.reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const rootReducer = combineReducers({
  app,
  dialog,
  reports,
  dashboard,
  deviceTelemetry,
  user,
  users,
  groups,
  preScreeningAppMgmt,
  tasks,
  tools,
  surveys,
  worklog,
  form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)
