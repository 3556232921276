import dayjs from 'dayjs'
import { DEFAULT_TIME_FORMAT } from './config'

/**
 * Get date from birth age, function takes into account
 * Years and Months, drawbacks is this function is not so precise for:
 * - different time zones
 * - leapyers
 * Results may letu from few hours to max 2 days.
 * If we need higher precision for age function needs to be upgraded
 * @param {String} dateString date should be formatted as 1990-03-18
 * @returns {Number} which correspond to the age of the person
 */
export const getAge = (dateString) => {
  let today = new Date()
  let birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  let m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

/**
 * Get start and end date based on predefined values
 * @param {String} date
 */
export const getPredefinedDates = (date) => {
  let startDate, endDate

  endDate = dayjs.utc(new Date()).format(DEFAULT_TIME_FORMAT)

  switch (date) {
    case 'today':
      startDate = endDate
      break
    case 'yesterday':
      startDate = dayjs
        .utc(new Date())
        .subtract(1, 'days')
        .format(DEFAULT_TIME_FORMAT)
      endDate = startDate
      break
    case '7d':
      startDate = dayjs
        .utc(new Date())
        .subtract(7, 'days')
        .format(DEFAULT_TIME_FORMAT)
      break
    case '30d':
      startDate = dayjs
        .utc(new Date())
        .subtract(30, 'days')
        .format(DEFAULT_TIME_FORMAT)
      break
    case '60d':
      startDate = dayjs
        .utc(new Date())
        .subtract(60, 'days')
        .format(DEFAULT_TIME_FORMAT)
      break
    case '6m':
      startDate = dayjs
        .utc(new Date())
        .subtract(6, 'month')
        .format(DEFAULT_TIME_FORMAT)
      break
    case '12m':
      startDate = dayjs
        .utc(new Date())
        .subtract(12, 'month')
        .format(DEFAULT_TIME_FORMAT)
      break

    default:
    // code block
  }

  return {
    startDate: startDate,
    endDate: endDate,
  }
}

/**
 * Display name of selected dates
 * @param {Dayjs Obj} startDate formatted date from dayjs in UTC time
 * @param {Dayjs Obj} endDate formatted date from dayjs in UTC time
 * @return {String} date string
 */
export const displaySelectedDate = (startDate, endDate) => {
  // if same year
  if (startDate.format('YYYY') === endDate.format('YYYY')) {
    return startDate.format('DD MMM') + ' - ' + endDate.format('DD MMM YYYY')
  } else {
    return (
      startDate.format('DD MMM YYYY') + ' - ' + endDate.format('DD MMM YYYY')
    )
  }
}

/**
 * Converts seconds to hours
 * @param {Number} seconds
 * @return {String} date string
 */
export const secondsToHour = (seconds) => {
  return (seconds / 60 / 60).toFixed(1)
}

/**
 * TODO: connect to translation string with i18next
 * Converts seconds to 5 minutes, 4 hours, 15 minutes
 * @param {Number or String} seconds
 * @return {String} formatted time duration
 */
export const secondsToDuration = (seconds) => {
  seconds = Number(seconds)

  if (seconds === 0) {
    return '0 minutes'
  }

  const hour = 3600

  let d = Math.floor(seconds / (hour * 24))
  let h = Math.floor((seconds % (hour * 24)) / hour)
  let m = Math.floor((seconds % hour) / 60)
  // let s = Math.floor(seconds % 60)

  let dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : ''
  let hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : ''
  let mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes') : ''
  // let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''

  return dDisplay + hDisplay + mDisplay
}
