import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

const datetimeparser = () => {
  dayjs.extend(relativeTime)
  dayjs.extend(utc)

  return dayjs
}

export default datetimeparser
