import { createRoute, fetchWithErrors } from '../utils'
import { mapTaskDataToServer } from '../utils/transform'
import { Endpoints } from '../constants'

export const createNewTask = (toolsList, formData) =>
  fetchWithErrors(Endpoints.Tasks.Urls.Create, {
    method: 'POST',
    body: JSON.stringify(mapTaskDataToServer(toolsList, formData)),
  })

export const fetchTask = (taskId) =>
  fetchWithErrors(createRoute(Endpoints.Tasks.Urls.Detail, { taskId }))
export const updateTask = (taskId, toolsList, formData) =>
  fetchWithErrors(createRoute(Endpoints.Tasks.Urls.Update, { taskId }), {
    method: 'PUT',
    body: JSON.stringify(mapTaskDataToServer(toolsList, formData)),
  })
export const deleteTask = (taskId) =>
  fetchWithErrors(createRoute(Endpoints.Tasks.Urls.Delete, { taskId }), {
    method: 'DELETE',
  })

export const fetchAvailableTasks = () =>
  fetchWithErrors(Endpoints.AvailableTasks.Urls.List)
