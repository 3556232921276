import React from 'react'
import { createMuiTheme } from '@material-ui/core'
import ThemeWrapper from './ThemeWrapper'
import PropTypes from 'prop-types'
// TODO (refactor): split overrides into "guest" and "main"
import * as styles from './styles/index'

const baseName = 'guest-theme'

const guestTheme = createMuiTheme({
  palette: styles.palette,

  root: {
    height: '100%',
    letterSpacing: '-0.025rem',
  },

  bold: {
    fontWeight: 600,
  },

  typography: {
    useNextVariants: true,
    fontFamily: ['Maven Pro'],
    fontSize: 15,
    lineHeight: '1.5rem',
    body1: {
      fontSize: 15,
      fontWeight: 500,
    },
    h2: {
      letterSpacing: '-0.25rem',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: styles.palette.text.secondary,
      lineHeight: '1.5rem',
    },
    subtitle2: {
      color: styles.palette.text.secondary,
    },
  },

  paperShadow: {
    boxShadow: '0px 2px 6px #0000000A',
  },
  overrides: {
    MuiButton: {
      // Name of the styleSheet
      root: {
        // Name of the rule
        height: 48,
        textTransform: 'none',
        // fontSize: '15px',
        fontWeight: 600,
        borderRadius: '6px',
        padding: '8px 16px',
        letterSpacing: '-0.0125rem',
      },

      containedPrimary: {
        color: '#fff',
        background: `linear-gradient(0deg, ${styles.palette.primary[1000]} -39%,    ${styles.palette.primary[400]} 100%)`,
        disabled: {
          color: '#fff',
        },
        '&$disabled': {
          opacity: 0.54,

          color: 'white',
          cursor: 'disabled',
        },
      },
    },
  },
})

class GuestTheme extends React.Component {
  render() {
    const { children, className, ...others } = this.props
    const classes = [baseName, className]

    return (
      <ThemeWrapper
        className={classes.join(' ')}
        theme={guestTheme}
        {...others}
      >
        {children}
      </ThemeWrapper>
    )
  }
}

GuestTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  others: PropTypes.object,
}

export default GuestTheme
