import { fetchWithErrors } from '../utils'
import { Endpoints } from '../constants'

export const fetchUserRoles = () => fetchWithErrors(Endpoints.Roles.Url)

export const createNewUser = (formData) =>
  fetchWithErrors(Endpoints.UserManagement.Urls.Create, {
    method: 'POST',
    body: JSON.stringify(formData),
  })

export const generateUserCredentials = () =>
  fetchWithErrors(Endpoints.UserManagement.Urls.GenerateCredentials)
