import React, { Component } from 'react'
import Chart from 'chart.js'
import * as ChartAnnotation from '../../../lib/chart-js-annotation/chartjs-plugin-annotation'
import * as ChartCrosshair from 'chartjs-plugin-crosshair'
import { chartOptions } from '../../../constants/ChartBoundaries'
import PropTypes from 'prop-types'
import { mapGradients, formatUTC } from '../../../utils/data-formatter'
import { withTranslation } from 'react-i18next'
import palette from '../../themes/styles/palette'
import { hexToRgbA } from '../../../utils/ui-fns'

Chart.defaults.global.defaultFontFamily = 'Maven Pro'
Chart.pluginService.register(ChartAnnotation)
Chart.pluginService.register(ChartCrosshair)

class BarChart extends Component {
  chartRef = React.createRef()

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { data: prevData } = prevProps
    const { data } = this.props

    if (data !== prevData && data.data.length > 0) {
      this.buildChart()
    }
  }

  buildChart = () => {
    const { data, t, group } = this.props
    const timeOffset = formatUTC(data.timeOffset)

    this.chartInstance = this.chartRef.current.getContext('2d')

    if (typeof data.data[0] === 'object') {
      data.data.forEach((el) => {
        const colors = mapGradients(
          el.label,
          this.chartInstance,
          this.props.height,
        )

        el.backgroundColor = colors.area
        el.borderColor = colors.stroke
        el.label = t(el.label)
      })
    }

    this.cx = new Chart(this.chartInstance, {
      type: 'bar',
      data: {
        // Bring in data
        labels: data.labels,
        datasets: data.data,
      },
      options: {
        ...chartOptions,
        legend: {
          align: 'end',
        },
        annotation: {
          events: ['mouseover', 'mouseout'],
          annotations: [
            {
              onMouseout: function (e) {
                var element = this
                // element.options.borderWidth = 4
                // element.options.label.content = e.type
                element.options.label.enabled = false
                element.chartInstance.update()
                element.chartInstance.chart.canvas.style.cursor = 'initial'
              },
              onMouseover: function (e) {
                var element = this
                // element.options.borderWidth = 7
                element.options.label.enabled = true
                // element.options.label.content = e.type
                element.chartInstance.update()
                element.chartInstance.chart.canvas.style.cursor = 'pointer'
              },
              type: 'line',
              id: 'hLine',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: group.average, // data-value at which the line is drawn
              borderWidth: 1,
              // borderDash: [6, 4],
              borderDashOffset: 5,
              borderColor: hexToRgbA(palette.primary[700], 0.54),
              label: {
                backgroundColor: hexToRgbA(palette.primary[700], 0.87),
                content: 'Average of: ' + group.name,
                enabled: false,
              },
            },
          ],
        },

        hover: {
          mode: 'average',
          intersect: false,
        },

        tooltips: {
          // mode: 'index',
          intersect: false,
          enabled: true,
          // position: 'nearest',
          // custom: customTooltip,
          callbacks: {
            title: function (tooltipItem, data) {
              const time = data['labels'][tooltipItem[0]['index']]

              return time + ' (UTC' + timeOffset + ')'
            },
            label: function (tooltipItem, data) {
              const dataPoint = data.datasets[tooltipItem['datasetIndex']]
              const value =
                data['datasets'][tooltipItem['datasetIndex']]['data'][
                  tooltipItem['index']
                ]

              const text = dataPoint.label + ': ' + value || ''
              return text
            },
          },
          backgroundColor: '#FFF',
          titleFontSize: 14,
          titleFontColor: 'rgba(0,0,0,1)',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          xPadding: 16,
          yPadding: 16,
          displayColors: true,
          boxShadow: '0px 2px 3px rgba(0, 0, 0, 1)',
          borderColor: 'rgba(0,0,0,.14)',
          borderWidth: 1,
          bodySpacing: 4,
          cornerRadius: 8,
        },
        plugins: {
          crosshair: {
            line: {
              color: 'rgba(0,0,0,.14)', // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: false, // enable trace line syncing with other charts
            },
            zoom: {
              enabled: false, // enable zooming
              zoomboxBackgroundColor: 'rgba(66,133,244,0.2)', // background color of zoom box
              zoomboxBorderColor: '#48F', // border color of zoom box
              zoomButtonText: 'Reset Zoom', // reset zoom button text
              zoomButtonClass: 'reset-zoom', // reset zoom button class
            },
            callbacks: {
              beforeZoom: function (start, end) {
                // called before zoom, return false to prevent zoom
                return true
              },
              afterZoom: function (start, end) {
                // called after zoom
              },
            },
          },
        },
      },
    })
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height + 'px',
          position: 'absolute',
          bottom: '16px',
          width: 'calc(100% - 32px)',
        }}
      >
        <canvas id={this.props.chartId} ref={this.chartRef} />
      </div>
    )
  }
}

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  t: PropTypes.func,
  chartId: PropTypes.string.isRequired,
  group: PropTypes.object,
}

export default withTranslation(['global'])(BarChart)
