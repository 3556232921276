// eslint-disable-next-line
import { lazy } from 'react'
import { intersection } from '../lib/lodash.custom.min'

export const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length
}

export const filterAuthorizedRoles = (routes, roles) => {
  if (routes && Array.isArray(routes)) {
    return routes.filter(({ disabled, linkText, authorizedRoles }) => {
      if (!authorizedRoles) {
        return true
      } else if (!isArrayWithLength(authorizedRoles)) {
        // if there is no permission in array filter it out
        return true
      } else {
        return intersection(authorizedRoles, roles).length
      }
    })
  } else {
    // default return empty array
    return []
  }
}

const filterObjetRouteToArray = (routes) => {
  const filterRouteByRole = []

  Object.keys(routes).forEach((key) => {
    if ('component' in routes[key]) {
      filterRouteByRole.push({
        ...routes[key].component,
        url: routes[key].url,
      })
    }
  })

  return filterRouteByRole
}

export const getAllowedRoutesFromRoles = (routes, roles) => {
  if (typeof roles !== typeof []) {
    throw new Error('Role(s) have to be passed as an array!')
  }

  const filterRouteByRole = filterObjetRouteToArray(routes)

  const filteredRoutes = filterAuthorizedRoles(filterRouteByRole, roles)

  const mapLazyComponent = filteredRoutes.map((route) => {
    if (!route.mounted) {
      return {
        ...route,
        mounted: true,
        component: lazy(() =>
          // eslint-disable-next-line
          import(
            /* webpackChunkName: "[request]" */
            `../components/organisms/Pages/${route.pageName}`
          ),
        ),
      }
    }
  })

  return mapLazyComponent
}

export const getAllowedNavLink = (roles, routes) => {
  if (typeof roles !== typeof []) {
    throw new Error('Role(s) have to be passed as an array!')
  }

  const filterRouteByRole = filterObjetRouteToArray(routes)

  return filterAuthorizedRoles(filterRouteByRole, roles)
}
