import { GroupsActionTypes } from './groups.types'

const INITIAL_STATE = {
  usersAssignment: {
    fetching: false,
    userIdsList: [],
  },
  exoskeletonsAssignment: {
    fetching: false,
    exoskeletonIdsList: [],
  },
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GroupsActionTypes.ASSIGN_USER_IDS_TO_GROUP: {
      return {
        ...state,
        usersAssignment: {
          ...state.usersAssignment,
          fetching: true,
        },
      }
    }
    case GroupsActionTypes.STORE_USER_ID_FOR_ASSIGNMENT: {
      const userId = action.payload
      const userIdsToAssign = [...state.usersAssignment.userIdsList]

      if (userIdsToAssign.indexOf(userId) === -1) userIdsToAssign.push(userId)

      return {
        ...state,
        usersAssignment: {
          ...state.usersAssignment,
          userIdsList: userIdsToAssign,
        },
      }
    }
    case GroupsActionTypes.REMOVE_USER_ID_FROM_ASSIGNMENT: {
      const userId = action.payload
      const userIdsToAssign = [...state.usersAssignment.userIdsList]
      const userIdIndexToRemove = userIdsToAssign.indexOf(userId)

      if (userIdIndexToRemove > -1)
        userIdsToAssign.splice(userIdIndexToRemove, 1)

      return {
        ...state,
        usersAssignment: {
          ...state.usersAssignment,
          userIdsList: userIdsToAssign,
        },
      }
    }
    case GroupsActionTypes.REMOVE_ALL_USER_IDS_FROM_ASSIGNMENT: {
      return {
        ...state,
        ...INITIAL_STATE.usersAssignment,
      }
    }
    case GroupsActionTypes.ASSIGN_EXOSKELETON_IDS_TO_GROUP: {
      return {
        ...state,
        exoskeletonsAssignment: {
          ...state.exoskeletonsAssignment,
          fetching: true,
        },
      }
    }
    case GroupsActionTypes.STORE_EXOSKELETON_ID_FOR_ASSIGNMENT: {
      const exoskeletonId = action.payload
      const exoskeletonIdsToAssign = [
        ...state.exoskeletonsAssignment.exoskeletonIdsList,
      ]

      if (exoskeletonIdsToAssign.indexOf(exoskeletonId) === -1)
        exoskeletonIdsToAssign.push(exoskeletonId)

      return {
        ...state,
        exoskeletonsAssignment: {
          ...state.exoskeletonsAssignment,
          exoskeletonIdsList: exoskeletonIdsToAssign,
        },
      }
    }
    case GroupsActionTypes.REMOVE_EXOSKELETON_ID_FROM_ASSIGNMENT: {
      const exoskeletonId = action.payload
      const exoskeletonIdsToAssign = [
        ...state.exoskeletonsAssignment.exoskeletonIdsList,
      ]
      const exoskeletonIdIndexToRemove =
        exoskeletonIdsToAssign.indexOf(exoskeletonId)

      if (exoskeletonIdIndexToRemove > -1)
        exoskeletonIdsToAssign.splice(exoskeletonIdIndexToRemove, 1)

      return {
        ...state,
        exoskeletonsAssignment: {
          ...state.exoskeletonsAssignment,
          exoskeletonIdsList: exoskeletonIdsToAssign,
        },
      }
    }
    case GroupsActionTypes.REMOVE_ALL_EXOSKELETON_IDS_FROM_ASSIGNMENT: {
      return {
        ...state,
        ...INITIAL_STATE.exoskeletonsAssignment,
      }
    }
    default:
      return state
  }
}

export default reducer
