import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import ReportsListTable from './ReportsListTable'
import { withTranslation } from 'react-i18next'
import Routes from '../../../../constants/Routes'
import { Box, Button } from '@material-ui/core'
// import { fetchReports } from '../../../../api/api.reports'
import {
  Endpoints,
  DEFAULT_PAGINATION_OPTIONS,
  AppTestIds,
} from '../../../../constants'
import { SearchByNameInput, GroupDropdown } from '../../../molecules'
import { _ } from '../../../../lib'
import StatHeader from '../../../molecules/StatHeader'
import PropTypes from 'prop-types'
import { createRoute, fetchWithErrors, getSummaries } from '../../../../utils'
import DateRangePicker from '../../DateRangePicker/DateRangePicker'
import { connect } from 'react-redux'
import {
  toggleDateMenu,
  clearFilters,
} from '../../../../redux/reports/reports.actions'

const fetchReports = async (
  searchString,
  groupCode,
  rowsPerPage,
  currentPage,
  startDate,
  endDate,
) =>
  Promise.resolve({
    message: 'Ok.',
    status: 'success',
    response: {
      current_page: 1,
      data: [
        {
          id: 2,
          duration: '6962',
          total_calories: '314.92',
          avg_mental_load_risk: '4.19',
          avg_physical_load_risk: '5.25',
          pct_in_low_workload: '0.21',
          pct_in_mid_workload: '0.76',
          pct_in_high_workload: '0.03',
          record_date: '2024-05',
          session_id: '63090d20-7559-11ed-82f6-c16d841e6907',
          event_id: null,
          avg_workload_risk: '4.5',
          wears_exo: false,
          exoskeleton_id: null,
          report_url:
            'https://cdn.wearhealth.net/wearprivate.dev/60b6ed65-f866-4cdc-ad48-2f81847f5106/a9c8bc63-16bd-4e7d-9d8a-91d2264423b1/2749215d-a90b-4c9f-a0e3-cafad17c351a/Team_2_Mai_2024.pdf',
          demographic: {
            id: 79,
            height: 180,
            weight: 78000,
            no_of_workers: '13/15',
            organization: 'Organization A',
            waist: '0',
            user_id: 'b51940e0-5509-11ed-8e43-e54082b14ce2',
            group_id: '77a15ba0-7042-11eb-a1c3-a119dccc27e4',
            hr_rest: null,
            hr_rest_uat: null,
            user: {
              id: 'b51940e0-5509-11ed-8e43-e54082b14ce2',
              email: 'worker5',
              first_name: 'Worker',
              last_name: 'Five',
              country: null,
              region: null,
              phone_number: null,
              sex: 'm',
              birthday: '1997-12-08',
              active: true,
              external_id: null,
              alias: 'worker5',
              pin: '5115',
              qr_code:
                'V0gvLy8vL3YxLy8vLy93b3JrZXI1Ly8vLy8kMnkkMTAkV1VGeWx0MkZTOXNQOGs4VUNMLm96T20vZkZTMGEzbnpXbGswRWg0emtRc3hUT2ZlYXhhZ2k=',
              name: 'Worker Five',
            },
            group: {
              id: '77a15ba0-7042-11eb-a1c3-a119dccc27e4',
              name: 'Team 2',
              description: 'Team 2 Description!',
              created_at: '2021-02-16',
              updated_at: '2021-02-16',
              tenant_id: '779a7630-7042-11eb-b332-e3d1dfae7904',
              author_id: '7693b5b0-7042-11eb-a03f-dd351999af57',
              kpis: {
                agg_avg_mental_load: '2.19740740740741',
                agg_avg_physical_load: '7.70259259259259',
                agg_avg_workload_risk: '7.4837037037037',
                agg_avg_duration: '7939.70814814815',
                agg_avg_kcal_per_minute: '2.996869490586',
                laravel_through_key: '77a15ba0-7042-11eb-a1c3-a119dccc27e4',
              },
              exoskeletons: [],
            },
          },
        },
        {
          id: 1,
          duration: '6962',
          total_calories: '314.92',
          avg_mental_load_risk: '4.19',
          avg_physical_load_risk: '5.25',
          pct_in_low_workload: '0.21',
          pct_in_mid_workload: '0.76',
          pct_in_high_workload: '0.03',
          record_date: '2024-05',
          session_id: '63090d20-7559-11ed-82f6-c16d841e6907',
          event_id: null,
          avg_workload_risk: '5.2',
          wears_exo: false,
          exoskeleton_id: null,
          report_url:
            'https://cdn.wearhealth.net/wearprivate.dev/2a6d56a2-9b9a-4f46-883e-14f3acec750e/7847fe5f-b113-4dcd-a640-df6b1ee09229/2836eaa9-35c3-40ad-9974-af32bba59838/Team_1_Mai_2024.pdf',
          demographic: {
            id: 79,
            height: 180,
            weight: 78000,
            no_of_workers: '14/15',
            organization: 'Organization A',
            waist: '0',
            user_id: 'b51940e0-5509-11ed-8e43-e54082b14ce2',
            group_id: '779f5dc0-7042-11eb-ad46-dd3e28814a3f',
            hr_rest: null,
            hr_rest_uat: null,
            user: {
              id: 'b51940e0-5509-11ed-8e43-e54082b14ce2',
              email: 'worker5',
              first_name: 'Worker',
              last_name: 'Five',
              country: null,
              region: null,
              phone_number: null,
              sex: 'm',
              birthday: '1997-12-08',
              active: true,
              external_id: null,
              alias: 'worker5',
              pin: '5115',
              qr_code:
                'V0gvLy8vL3YxLy8vLy93b3JrZXI1Ly8vLy8kMnkkMTAkV1VGeWx0MkZTOXNQOGs4VUNMLm96T20vZkZTMGEzbnpXbGswRWg0emtRc3hUT2ZlYXhhZ2k=',
              name: 'Worker Five',
            },
            group: {
              id: '779f5dc0-7042-11eb-ad46-dd3e28814a3f',
              name: 'Team 1',
              description: 'Team 1 Description!',
              created_at: '2021-02-16',
              updated_at: '2021-02-16',
              tenant_id: '779a7630-7042-11eb-b332-e3d1dfae7904',
              author_id: '7693b5b0-7042-11eb-a03f-dd351999af57',
              kpis: {
                agg_avg_mental_load: '2.77972972972973',
                agg_avg_physical_load: '3.49918918918919',
                agg_avg_workload_risk: '2.04567567567568',
                agg_avg_duration: '10223.52',
                agg_avg_kcal_per_minute: '3.92971691375171',
                laravel_through_key: '779f5dc0-7042-11eb-ad46-dd3e28814a3f',
              },
              exoskeletons: [
                {
                  id: 'SKELEX_360_XFR_2.5',
                  name: 'Skel-Ex 360 XFR 2.5',
                  created_at: '2023-02-10 10:42:04',
                  updated_at: '2023-02-10 10:42:04',
                },
                {
                  id: 'DUMMY_EXO_1',
                  name: 'Dummy Exo 1',
                  created_at: '2023-02-10 14:06:57',
                  updated_at: '2023-02-10 14:06:57',
                },
              ],
            },
          },
        },
      ],
      first_page_url: 'http://api.dev.wearhealth.net/v1/records?page=1',
      from: 1,
      last_page: 1,
      last_page_url: 'http://api.dev.wearhealth.net/v1/records?page=1',
      next_page_url: null,
      path: 'http://api.dev.wearhealth.net/v1/records',
      per_page: 25,
      prev_page_url: null,
      to: 25,
      total: 2,
    },
  })

class ReportsList extends PureComponent {
  state = {
    loadedList: false,
    fetching: false,
    fetchError: false,
    reports: [],
    pagination: {
      rowsPerPage: DEFAULT_PAGINATION_OPTIONS.rowsPerPage,
      currentPage: 0,
      total: 0,
    },
    dateMenuHandle: null,
    groupKPI: {
      loaded: false,
      avg_duration: 0,
      avg_kcal: 0,
      avg_mental: 0,
      avg_physical: 0,
    },
  }

  async componentDidMount() {
    this.fetchReportsList(this.props.reportsQuery)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pagination: {
        rowsPerPage: prevRowsPerPage,
        currentPage: prevCurrentPage,
      },
    } = prevState

    // Redux prev props
    const {
      reportsQuery: {
        groupCode: prevPropGroupCode,
        searchString: prevPropssearchString,
        startDate: prevPropStartDate,
        endDate: prevPropEndDate,
      },
    } = prevProps

    const {
      pagination: { rowsPerPage, currentPage },
    } = this.state

    // Redux current props
    const {
      reportsQuery: {
        groupCode: propGroupCode,
        searchString: propsearchString,
        startDate: propStartDate,
        endDate: propEndDate,
      },
    } = this.props

    // update if page changes or if filter changes
    if (
      prevRowsPerPage !== rowsPerPage ||
      prevCurrentPage !== currentPage ||
      prevPropGroupCode !== propGroupCode ||
      prevPropssearchString !== propsearchString ||
      prevPropStartDate !== propStartDate ||
      prevPropEndDate !== propEndDate
    ) {
      this.fetchReportsList(this.props.reportsQuery)
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  handleChangePage = (e, page) => {
    const { pagination } = this.state
    this.setState({ pagination: { ...pagination, currentPage: page } })
  }

  handleChangeRowsPerPage = (e) => {
    const rowsPerPage = e.target.value
    const oldState = _.cloneDeep(this.state)
    this.setState({ oldState, pagination: { currentPage: 0, rowsPerPage } })
  }

  fetchReportsList = async (props) => {
    const { pagination } = this.state

    !this.unmount && this.setState({ fetching: true })

    let groupKPI

    const rootResponse = await fetchReports(
      props.searchString,
      props.groupCode === 'all' ? null : props.groupCode,
      pagination.rowsPerPage,
      pagination.currentPage,
      props.startDate,
      props.endDate,
    )

    groupKPI = await this.fetchGroupKPI(props.groupCode)

    if (!('error' in rootResponse)) {
      const enclosedResponse = await rootResponse.response

      if (!('error' in rootResponse)) {
        !this.unmount &&
          this.setState({
            reports: enclosedResponse.data,
            pagination: { ...pagination, total: enclosedResponse.total },
            loadedList: true,
            fetching: false,
            groupKPI: { loaded: true, ...groupKPI },
          })
      } else {
        !this.unmount &&
          this.setState({
            fetching: false,
            fetchError: true,
          })
      }
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchError: true,
        })
    }
  }

  fetchGroupKPI = async (selectedGroup) => {
    const rootResponse = await fetchWithErrors(Endpoints.Group.Urls.List)

    let sum = {
      avg_duration: null,
      avg_kcal: null,
      avg_mental: null,
      avg_physical: null,
    }

    // default values if there are no values for the group
    const sumDefault = {
      avg_duration: 0,
      avg_kcal: 0,
      avg_mental: 0,
      avg_physical: 0,
    }

    if (!('error' in rootResponse)) {
      const enclosedResponse = await rootResponse.response

      if (!('error' in rootResponse)) {
        return getSummaries(selectedGroup, enclosedResponse)
      } else {
        return sum
      }
    } else {
      return sumDefault
    }
  }

  triggerViewError = (error) => {
    if (error) {
      this.setState({
        fetching: false,
        fetchError: true,
      })
    }
  }

  handleClickReportRow = (e, id) => {
    const { history } = this.props
    history.push(createRoute(Routes.Reports.Detail.url, { reportId: id }))
  }

  openSelectDate = (event) => {
    const oldState = _.cloneDeep(this.state)
    this.setState({ oldState, dateMenuHandle: event.currentTarget })
    this.props.toggleDateMenu(true)
  }

  render() {
    const { fetching, pagination, reports } = this.state
    const { t, reportsQuery, toggleDateMenu, resetFilters } = this.props

    const summaryKPIs = {
      optionalKPI: this.state.pagination.total,
      avgMental: this.state.groupKPI.avg_mental,
      avgPhy: this.state.groupKPI.avg_physical,
      avgDur: this.state.groupKPI.avg_duration,
      avgKcal: this.state.groupKPI.avg_kcal,
    }

    return (
      <>
        <Helmet>
          <title>{t(Routes.Reports.title)}</title>
        </Helmet>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px' }}
        >
          <Box p={1}>
            <DateRangePicker
              displayDate={
                reportsQuery.dateMenuSelected === 'all'
                  ? t('selectDates')
                  : reportsQuery.dateMenuSelected
              }
              openMenuHandler={this.openSelectDate}
              closeMenuHandler={() => toggleDateMenu(false)}
              anchorMenuEl={this.state.dateMenuHandle}
              selectedDateRange={[reportsQuery.startDate, reportsQuery.endDate]}
            />
          </Box>
          <Box p={1}>
            <SearchByNameInput isFetching={this.state.fetching} />
          </Box>
          <Box p={1}>
            <GroupDropdown
              handleViewError={this.triggerViewError}
              reducer="REPORTS"
            />
          </Box>
          <Box p={1}>
            <Button
              app-testid={AppTestIds.Filters.ClearButton}
              disableElevation
              onClick={resetFilters}
              variant="contained"
            >
              {t('clearFilter')}
            </Button>
          </Box>
        </Box>

        <StatHeader
          type="reports"
          selectedGroup={reportsQuery.groupCode}
          handleViewError={this.triggerViewError}
          kpis={summaryKPIs}
          fetching={this.state.fetching}
        />

        <ReportsListTable
          fetching={fetching}
          reports={reports}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleClickReportRow={this.handleClickReportRow}
          openReport={this.openReport}
          pagination={pagination}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reportsQuery: state.reports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDateMenu: (isOpen) => dispatch(toggleDateMenu(isOpen)),
    resetFilters: () => dispatch(clearFilters()),
  }
}

ReportsList.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toggleDateMenu: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  reportsQuery: PropTypes.object.isRequired,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ReportsList)

const translated = withTranslation(['global'])(connected)

export default withRouter(translated)
