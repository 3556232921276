import { UserActionTypes } from './user.types'
import { _ } from '../../lib'

const INITIAL_STATE = {
  permissions: {},
  authorization: null,
  profileSettings: {},
  roles: [],
  tenantId: undefined,
}

// TODO: Remove tenant_id. It's already in the permissions object (decoded token)
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_PERMISSIONS: {
      const oldState = _.cloneDeep(state)

      return {
        ...oldState,
        authorization: action.payload.token,
        permissions: action.payload.decoded,
        roles: action.payload.decoded.roles.map((role) => role.name),
        tenantId:
          action.payload.decoded.tenants[0].id || 'ERR: USER HAS NO TENANT',
      }
    }

    case UserActionTypes.FLUSH_USER_SETTINGS: {
      return {
        ...INITIAL_STATE,
      }
    }

    default:
      return state
  }
}

export default reducer
