import React from 'react'
import PropTypes from 'prop-types'

import { MuiThemeProvider } from '@material-ui/core/styles'

const baseName = 'theme'

const contentModifier = `${baseName}--content`
const stretchModifier = `${baseName}--stretch`

class ThemeWrapper extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    stretch: PropTypes.bool,
    theme: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
  }

  render() {
    const {
      className,
      content,
      children,
      stretch,
      theme,
      style,
      onClick,
      ...others
    } = this.props
    const classes = [baseName, className]

    if (content) classes.push(contentModifier)
    if (stretch) classes.push(stretchModifier)

    return (
      <MuiThemeProvider theme={theme} {...others}>
        {children}
      </MuiThemeProvider>
    )
  }
}

export default ThemeWrapper
