import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from '../../../../constants'
import WorklogListView from './WorklogListView'
import WorklogDetailView from './WorklogDetailView'

class WorklogView extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Worklog.url} component={WorklogListView} />
        <Route path={Routes.Worklog.Detail.url} component={WorklogDetailView} />
      </Switch>
    )
  }
}

export default WorklogView
