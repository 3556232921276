const CountryCodes = Object.freeze({
  AUSTRIA: {
    DIAL: '43',
    ALPHA2CODE: 'AT',
  },
  CZECH_REPUBLIC: {
    DIAL: '420',
    ALPHA2CODE: 'CZ',
  },
  FRANCE: {
    DIAL: '33',
    ALPHA2CODE: 'FR',
  },
  GERMANY: {
    DIAL: '49',
    ALPHA2CODE: 'DE',
  },
  ITALY: {
    DIAL: '39',
    ALPHA2CODE: 'IT',
  },
  NETHERLANDS: {
    DIAL: '31',
    ALPHA2CODE: 'NL',
  },
  SPAIN: {
    DIAL: '34',
    ALPHA2CODE: 'ES',
  },
  PORTUGAL: {
    DIAL: '351',
    ALPHA2CODE: 'PT',
  },
  UK: {
    DIAL: '44',
    ALPHA2CODE: 'GB',
  },
})

export default CountryCodes
