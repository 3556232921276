import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  withStyles,
} from '@material-ui/core'
import { BeatLoader } from 'react-spinners'

import ModalBox from '../../../ModalBox'
import {
  AppTestIds,
  DEFAULT_PAGINATION_OPTIONS,
} from '../../../../../constants'
import {
  assignUsersToGroup,
  fetchGroupAssignedUsers,
  fetchGroupUnassignedUsers,
} from '../../../../../api/api.groups'
import UsersTable, { availableColumns } from '../../../../molecules/UsersTable'
import {
  storeUserIdForAssignment,
  removeUserIdFromAssignment,
  removeAllUserIdsFromAssignment,
} from '../../../../../redux/groups/groups.actions'
import { usersAssignmentSelector } from '../../../../../redux/groups/groups.selectors'

const styles = () => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  openFormButton: {
    height: 'auto',
  },
})

class GroupAssignedUsersList extends Component {
  state = {
    openUserAssignmentDialog: false,
    assignedUsersState: {
      fetching: false,
      error: null,
      list: [],
      pagination: {
        currentPage: 0,
        rowsPerPage: DEFAULT_PAGINATION_OPTIONS.rowsPerPage,
        total: 0,
      },
    },
    unassignedUsersState: {
      fetching: false,
      error: null,
      list: [],
      pagination: {
        currentPage: 0,
        rowsPerPage: 10,
        total: 0,
      },
    },
  }

  async componentDidMount() {
    this.fetchGroupAssignedUsers()
  }

  async fetchGroupAssignedUsers() {
    const {
      assignedUsersState,
      assignedUsersState: { pagination },
    } = this.state
    const {
      match: {
        params: { groupId },
      },
    } = this.props

    this.setState({
      assignedUsersState: { ...assignedUsersState, fetching: true },
    })

    const rootResponse = await fetchGroupAssignedUsers({
      page: pagination.currentPage + 1,
      per_page: pagination.rowsPerPage,
      group_id: groupId,
    })

    if (!('error' in rootResponse)) {
      const enclosedResponse = rootResponse.response

      this.setState({
        assignedUsersState: {
          ...assignedUsersState,
          pagination: { ...pagination, total: enclosedResponse.total },
          list: enclosedResponse.data,
          fetching: false,
        },
      })
    }
  }

  async fetchGroupUnassignedUsers() {
    const {
      unassignedUsersState,
      unassignedUsersState: { pagination },
    } = this.state

    this.setState({
      unassignedUsersState: { ...unassignedUsersState, fetching: true },
    })

    const rootResponse = await fetchGroupUnassignedUsers({
      page: pagination.currentPage + 1,
      per_page: pagination.rowsPerPage,
      group_id: 'unassigned',
      all_of: 'manager',
    })

    if (!('error' in rootResponse)) {
      const enclosedResponse = rootResponse.response

      this.setState({
        unassignedUsersState: {
          ...unassignedUsersState,
          pagination: { ...pagination, total: enclosedResponse.total },
          list: enclosedResponse.data,
          fetching: false,
        },
      })
    }
  }

  checkUserRow = (e, checkState) => {
    const { storeUserIdForAssignment, removeUserIdFromAssignment } = this.props

    checkState
      ? storeUserIdForAssignment(e.target.value)
      : removeUserIdFromAssignment(e.target.value)
  }

  assignedUsersChangePageHandler = (page, pagination) =>
    this.setState(
      {
        assignedUsersState: {
          ...this.state.assignedUsersState,
          pagination: {
            ...this.state.pagination,
            rowsPerPage: pagination.rowsPerPage,
            currentPage: page,
          },
        },
      },
      this.fetchGroupAssignedUsers,
    )

  assignedUsersChangeRowsPerPageHandler = (e, pagination) =>
    this.setState(
      {
        assignedUsersState: {
          ...this.state.assignedUsersState,
          pagination: {
            ...this.state.pagination,
            rowsPerPage: e.target.value,
            currentPage: 0,
          },
        },
      },
      this.fetchGroupAssignedUsers,
    )

  unassignedUsersChangePageHandler = (page, pagination) =>
    this.setState(
      {
        unassignedUsersState: {
          ...this.state.unassignedUsersState,
          pagination: {
            ...this.state.pagination,
            rowsPerPage: pagination.rowsPerPage,
            currentPage: page,
          },
        },
      },
      this.fetchGroupUnassignedUsers,
    )

  unassignedUsersChangeRowsPerPageHandler = (e, pagination) =>
    this.setState(
      {
        unassignedUsersState: {
          ...this.state.unassignedUsersState,
          pagination: {
            ...this.state.pagination,
            rowsPerPage: e.target.value,
            currentPage: 0,
          },
        },
      },
      this.fetchGroupUnassignedUsers,
    )

  handleOpenUserAssignmentDialog = () =>
    this.setState({ openUserAssignmentDialog: true }, () =>
      this.fetchGroupUnassignedUsers(),
    )

  handleCloseFormDialog = () =>
    this.setState({ openUserAssignmentDialog: false })

  handleAssignUserIdsToGroup = async () => {
    const {
      selectedIdsList,
      removeAllUserIdsFromAssignment,
      match: {
        params: { groupId },
      },
    } = this.props

    const rootResponse = await assignUsersToGroup(groupId, selectedIdsList)

    if (!rootResponse.error) {
      this.handleCloseFormDialog()
      removeAllUserIdsFromAssignment()
      this.fetchGroupAssignedUsers()
    }
  }

  render() {
    const {
      openUserAssignmentDialog,
      assignedUsersState,
      unassignedUsersState,
    } = this.state
    const { classes, selectedIdsList, fetching, t } = this.props

    return (
      <>
        <ModalBox
          modalTitle={t('groups:detail.assignUsersModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openUserAssignmentDialog}
          actionButton={
            <Button
              variant="contained"
              color="primary"
              component="button"
              onClick={this.handleAssignUserIdsToGroup}
            >
              {!fetching && <span>Submit</span>}
              {fetching && (
                <BeatLoader sizeUnit={'px'} size={9} color={'#fff'} loading />
              )}
            </Button>
          }
        >
          <>
            <Typography variant={'body1'} gutterBottom>
              {t('groups:detail.assignUsersModal.description')}
            </Typography>
            <UsersTable
              onChangePage={this.unassignedUsersChangePageHandler}
              onChangeRowsPerPage={this.unassignedUsersChangeRowsPerPageHandler}
              usersList={unassignedUsersState.list}
              fetching={unassignedUsersState.fetching}
              error={unassignedUsersState.error}
              pagination={unassignedUsersState.pagination}
              columnsToDisplay={[
                availableColumns.CHECKBOX,
                availableColumns.EMAIL,
                availableColumns.ROLES,
              ]}
              checkboxOnChange={this.checkUserRow}
              selectedIdsList={selectedIdsList}
            />
          </>
        </ModalBox>
        <Card>
          <CardHeader
            title={t('groups:detail.assignedUsersListTable.title')}
            action={
              <Button
                app-testid={AppTestIds.Groups.OpenDialog}
                aria-label="assign users"
                variant="contained"
                color="primary"
                onClick={this.handleOpenUserAssignmentDialog}
                className={classes.openFormButton}
              >
                {t(
                  `groups:detail.assignedUsersListTable.openFormButton${
                    !assignedUsersState.fetching &&
                    assignedUsersState.list.length === 0
                      ? 1
                      : 2
                  }`,
                )}
              </Button>
            }
          />
          <CardContent className={classes.cardContent}>
            <UsersTable
              onChangePage={this.assignedUsersChangePageHandler}
              onChangeRowsPerPage={this.assignedUsersChangeRowsPerPageHandler}
              usersList={assignedUsersState.list}
              fetching={assignedUsersState.fetching}
              error={assignedUsersState.error}
              pagination={assignedUsersState.pagination}
              columnsToDisplay={[
                availableColumns.FULL_NAME,
                availableColumns.EMAIL,
                availableColumns.ID,
                availableColumns.ROLES,
                availableColumns.STATUS,
              ]}
            />
          </CardContent>
        </Card>
      </>
    )
  }
}

GroupAssignedUsersList.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  removeAllUserIdsFromAssignment: PropTypes.func.isRequired,
  selectedIdsList: PropTypes.array.isRequired,
  storeUserIdForAssignment: PropTypes.func.isRequired,
  removeUserIdFromAssignment: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  selectedIdsList: usersAssignmentSelector(state).userIdsList,
  fetching: usersAssignmentSelector(state).fetching,
})

const mapDispatchToProps = (dispatch) => ({
  storeUserIdForAssignment: (userId) =>
    dispatch(storeUserIdForAssignment(userId)),
  removeUserIdFromAssignment: (userId) =>
    dispatch(removeUserIdFromAssignment(userId)),
  removeAllUserIdsFromAssignment: () =>
    dispatch(removeAllUserIdsFromAssignment()),
})

export default compose(
  withTranslation(['groups']),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(GroupAssignedUsersList)
