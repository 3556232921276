import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Button, Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import TaskDetailCard from './TaskDetailCard'
import ModalBox from '../../../ModalBox'
import { Routes } from '../../../../../constants'
import { deleteTask } from '../../../../../api/api.tasks'

class TaskDetailView extends Component {
  state = {
    openDeleteDialog: false,
  }

  handleOpenDeleteDialog = () => this.setState({ openDeleteDialog: true })
  handleCloseDeleteDialog = () => this.setState({ openDeleteDialog: false })

  submitDeleteTask = async () => {
    const {
      history,
      match: {
        params: { taskId },
      },
    } = this.props

    const rootResponse = await deleteTask(taskId)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'TASK_DELETED'
    ) {
      history.push(Routes.Tasks.url)
    }
  }

  render() {
    const { t } = this.props
    const { openDeleteDialog } = this.state

    return (
      <>
        <Helmet>
          <title>{t('navigation:title.tasks')}</title>
        </Helmet>

        <ModalBox
          modalTitle={t('tasks:detail.modal.delete.title')}
          onClose={this.handleCloseDeleteDialog}
          open={openDeleteDialog}
          actionButton={
            <Button color="secondary" onClick={this.submitDeleteTask}>
              {t('global:delete')}
            </Button>
          }
        >
          <span>{t('tasks:detail.modal.delete.content')}</span>
        </ModalBox>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <TaskDetailCard
              handleOpenDeleteDialog={this.handleOpenDeleteDialog}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

TaskDetailView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTranslation(['tasks'])(TaskDetailView)
