import React from 'react'
import { compose } from 'redux'
import { Grid, Box, Typography, Divider, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ObjectDetailSkeleton } from '../../../atoms'

const styles = () => ({
  uppercase: {
    fontWeight: 600,
    fontSize: '14px',
    textTransform: 'uppercase',
    color: '#97A1B0',
  },
})

const ReportDetailheader = ({ classes, t, data }) => (
  <Grid container spacing={1} alignItems="stretch">
    <Grid item xs={3}>
      <Box>
        <ObjectDetailSkeleton object={data} detailPath={'recordDate'} />
        <Typography className={classes.uppercase}>
          {t('record') + ' ' + t('date')}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={9} spacing={0} container alignItems="stretch">
      <Box mr={6}>
        <ObjectDetailSkeleton object={data} detailPath={'fullName'} />
        <Typography className={classes.uppercase}>{t('user')}</Typography>
      </Box>
      <Box mr={6}>
        <ObjectDetailSkeleton object={data} detailPath={'sex'} />
        <Typography className={classes.uppercase}>{t('gender')}</Typography>
      </Box>
      <Box mr={6}>
        <ObjectDetailSkeleton object={data} detailPath={'height'} />
        <Typography className={classes.uppercase}>{t('height')}</Typography>
      </Box>
      <Box mr={6}>
        <ObjectDetailSkeleton object={data} detailPath={'weight'} />
        <Typography className={classes.uppercase}>{t('weight')}</Typography>
      </Box>
      <Box mr={3}>
        <ObjectDetailSkeleton object={data} detailPath={'age'} />
        <Typography className={classes.uppercase}>{t('age')}</Typography>
      </Box>
      <Box mr={3}>
        <Divider orientation="vertical" />
      </Box>
      <Box mr={3}>
        <ObjectDetailSkeleton object={data} detailPath={'group.name'} />
        <Typography className={classes.uppercase}>
          {t('group') + ' ' + t('name')}
        </Typography>
      </Box>
      <Box mr={3}>
        <ObjectDetailSkeleton object={data} detailPath={'duration'} />
        <Typography className={classes.uppercase}>
          {t('session') + ' ' + t('duration')}
        </Typography>
      </Box>
    </Grid>
  </Grid>
)

ReportDetailheader.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withTranslation(),
)(ReportDetailheader)
