import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { SubmissionError } from 'redux-form'
import { Card, CardHeader, CardContent, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { RemoteSubmitButton } from '../../../../atoms'
import { GroupForm } from '../../../../molecules'
import { fetchGroup, updateGroup } from '../../../../../api/api.groups'
import { AppTestIds, UPDATE_GROUP_FORM_ID } from '../../../../../constants'

const styles = () => ({
  remoteSubmitButton: {
    height: 'auto',
  },
})

class GroupDetailsCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  state = {
    group: null,
  }

  async componentDidMount() {
    this.fetchGroup()
  }

  async fetchGroup() {
    const {
      match: {
        params: { groupId },
      },
    } = this.props

    const rootResponse = await fetchGroup(groupId)
    this.setState({ group: rootResponse.response })
  }

  submitUpdateGroupForm = async (values) => {
    const {
      match: {
        params: { groupId },
      },
    } = this.props
    const rootResponse = await updateGroup(groupId, values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'GROUP_UPDATED'
    ) {
      this.fetchGroup()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  render() {
    const { classes, t } = this.props
    const { group } = this.state

    return (
      <Card>
        <CardHeader
          title={t('groups:detail.detailsCard.title')}
          action={
            <RemoteSubmitButton
              appTestId={AppTestIds.Groups.UpdateButton}
              className={classes.remoteSubmitButton}
              formId={UPDATE_GROUP_FORM_ID}
              buttonLabel={'update'}
            />
          }
          subheader={t('groups:detail.detailsCard.subtitle', {
            date: group ? group.updated_at : '...',
          })}
        />
        <CardContent>
          <GroupForm
            form={UPDATE_GROUP_FORM_ID}
            onSubmit={this.submitUpdateGroupForm}
            initialValues={{ ...group }}
          />
        </CardContent>
      </Card>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(['groups']),
  withRouter,
)(GroupDetailsCard)
