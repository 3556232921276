import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from '../../../../constants'
import SurveysListView from './SurveysListView'

class SurveysView extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Surveys.url} component={SurveysListView} />
      </Switch>
    )
  }
}

export default SurveysView
