import store from 'store/dist/store.modern'
import config, { TOKEN_KEY } from './config'

export const authenticate = (token) => {
  return Promise.resolve(config.setToken(token))
}

export const unauthenticate = () => {
  store.remove(TOKEN_KEY)
  return Promise.resolve()
}

export const isAuthenticated = () => {
  return !!config.getToken()
}

export const getAuthenticationToken = () => config.getToken()

/**
 * Assess whether ANY of the USER'S ROLES are included
 * in ANY of the REQUIRED ROLES
 * @param {Array} userRoles [{id: 0, name: "MANAGER"}]
 * @param {Array} required ["MANAGER"]
 * @returns {Boolean}
 */
export const hasAnyOfRequiredRoles = (userRoles = [], required) =>
  required.some((ele) =>
    userRoles
      .reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.name)
        return accumulator
      }, [])
      .includes(ele),
  )
