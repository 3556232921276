import React from 'react'
import * as PropTypes from 'prop-types'
import type { InferProps } from 'prop-types'
import { TextField } from '@material-ui/core'
import DaysJsAdapter from '@material-ui/pickers/adapter/dayjs'
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers'

import type { RestProps } from '../../types'

const DatePickerTypes = {
  allowSameDateSelection: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputFormat: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const DatePicker: React.FC<InferProps<typeof DatePickerTypes> & RestProps> = ({
  allowSameDateSelection,
  disabled,
  error,
  onChange,
  value,
  mask = '____-__-__',
  inputFormat = 'YYYY-MM-DD',
  label,
  ...rest
}) => (
  <LocalizationProvider dateAdapter={DaysJsAdapter}>
    <MuiDatePicker
      disabled={disabled || undefined}
      inputFormat={inputFormat || undefined}
      mask={mask || undefined}
      label={label}
      value={value}
      allowSameDateSelection={!!allowSameDateSelection}
      renderInput={(props) => (
        <TextField
          variant="outlined"
          style={{
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 24,
            fontWeight: 500,
          }}
          {...props}
          error={error || undefined} // maybe this should be refactored
        />
      )}
      onChange={onChange}
      {...rest}
    />
  </LocalizationProvider>
)

export default DatePicker
