import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Routes from '../../../../constants/Routes'
import UsersListView from './UsersListView'
import UserAnalyticsView from './UserAnalyticsView'

class UsersView extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.Users.url} component={UsersListView} />
        <Route path={Routes.Users.Profile.url} component={UserAnalyticsView} />
      </Switch>
    )
  }
}

export default UsersView
