import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { AppTestIds, Routes } from '../../../constants'
import { BackButton } from '../../atoms'

import { unauthenticate } from '../../../utils'
import { appUiSelector } from '../../../redux/ui/ui.selectors'
import { flushUserSettings } from '../../../redux/user/user.actions'
import TopBarMenu from './TopBarMenu'

const styles = (theme) => ({
  appLogoStyle: {
    marginLeft: '47px',
    marginTop: '8px',
    width: '124px',
  },
  appBar: {
    display: 'block',
    zIndex: theme.zIndex.drawer,
    backgroundColor: '#fff',
    height: 64,
    color: theme.palette.text.primary,
    width: 'calc(100% - 264px)',
    boxShadow: '0px 2px 6px rgba(0,0,0,.08)',
    // overflow: 'hidden',
  },

  appBarExpanded: {
    display: 'block',
    height: 64,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 6px rgba(0,0,0,.08)',
  },

  appTitleContext: {
    position: 'relative',
    width: 'calc(100%)',
    lineHeight: '42px',
    top: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appTitleContextExpanded: {
    position: 'relative',
    width: 'calc(100%)',
    lineHeight: '42px',
    top: 0,
    left: 74,
    color: 'black',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appPageTitle: {
    display: 'inline-block',
    marginTop: 14,
  },
  pageTitleMargin: {
    marginLeft: 32,
  },
})

class TopBar extends Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }))
  }

  handleLogout = () => {
    const { history, flushPersistor } = this.props

    this.handleClose()

    unauthenticate().then(() => {
      flushPersistor()
      history.push(Routes.Login.url)
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, location, isLeftSidebarCollapsed } = this.props

    const entitlePage = () => {
      const pathArray = location.pathname.split('/')
      let pathname = ''

      if (pathArray.length > 1)
        pathname = pathArray[1].charAt(0).toUpperCase() + pathArray[1].substr(1)

      // if user logged in on Dashboard page
      if (location.pathname === '/') pathname = 'Dashboard'

      return pathname
    }

    let backButtonCSS = [classes.appPageTitle]

    if (location.pathname.split('/').length > 2) {
      backButtonCSS.push(classes.pageTitleMargin)
    }

    return (
      <AppBar
        className={
          isLeftSidebarCollapsed ? classes.appBarExpanded : classes.appBar
        }
      >
        <Toolbar>
          <div
            className={
              isLeftSidebarCollapsed
                ? classes.appTitleContextExpanded
                : classes.appTitleContext
            }
          >
            {location.pathname.split('/').length > 2 && <BackButton />}

            <Typography
              display="inline"
              variant="h5"
              color="inherit"
              app-testid={AppTestIds.TopBar.Title}
              noWrap
              // className={backButtonCSS.join(' ')}
            >
              {entitlePage()}
            </Typography>
          </div>
        </Toolbar>

        <TopBarMenu
          handleLogout={this.handleLogout}
          handleToggle={this.handleToggle}
          handleClose={this.handleClose}
          menuOpen={this.state.open}
        />
      </AppBar>
    )
  }
}

const mapStateToProps = (state) => ({
  isLeftSidebarCollapsed: appUiSelector(state).isLeftSidebarCollapsed,
})

const mapDispatchToProps = (dispatch) => {
  return {
    flushPersistor: () => dispatch(flushUserSettings()),
  }
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLeftSidebarCollapsed: PropTypes.bool.isRequired,
  flushPersistor: PropTypes.func.isRequired,
}

const styled = withStyles(styles)(TopBar)
const routed = withRouter(styled)
const connected = connect(mapStateToProps, mapDispatchToProps)(routed)

export default connected
