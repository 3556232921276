import { Checkbox, FormControlLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const FormCheckbox = ({
  label,
  input,
  classes,
  formControlLabelProps,
  ...custom
}) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox height={48} placeholder={label} {...input} {...custom} />
    }
    {...formControlLabelProps}
  />
)

FormCheckbox.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  InputLabelProps: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  disableMinHeight: PropTypes.bool,
  formControlLabelProps: PropTypes.object,
}

export default FormCheckbox
