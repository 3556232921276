import { UsersActionTypes } from './users.types'
import { Endpoints } from '../../constants'
import { fetchWithErrors, withParams } from '../../utils'

export const fetchUsersListStart = () => ({
  type: UsersActionTypes.FETCH_USERS_START,
})

export const fetchUsersListSuccess = (responseJSON) => ({
  type: UsersActionTypes.FETCH_USERS_SUCCESS,
  payload: responseJSON,
})

export const fetchUsersListFailure = (errorMessagge) => ({
  type: UsersActionTypes.FETCH_USERS_FAILURE,
  payload: errorMessagge,
})

// Default fetch action
// Default values for onload are 10 and 0, 10
export const fetchUsersListStartAsync = () => {
  return async (dispatch) => {
    usersListFetcher(dispatch, { rowsPerPage: 10, currentPage: 0 })
  }
}

// Change rows per page
// When changing results to display the page needs start from 0
export const changeRowsPerPage = (e, currentPagination) => {
  const rowsPerPage = e.target.value

  return async (dispatch) => {
    usersListFetcher(dispatch, { rowsPerPage: rowsPerPage, currentPage: 0 })
  }
}

// Change page
export const changePage = (page, pagination) => {
  return async (dispatch) => {
    usersListFetcher(dispatch, {
      rowsPerPage: pagination.rowsPerPage,
      currentPage: page,
    })
  }
}

const usersListFetcher = async (dispatch, pagination) => {
  dispatch(fetchUsersListStart())

  const response = await fetchWithErrors(
    withParams(Endpoints.UserManagement.Urls.List, {
      per_page: pagination.rowsPerPage,
      page: pagination.currentPage + 1,
    }),
  )

  if (!response.error) {
    dispatch(fetchUsersListSuccess(response.response))
  } else {
    dispatch(fetchUsersListFailure(response.message))
  }
}
