import store from 'store/dist/store.modern'

const namespace = `worksense`

export const ENV_KEY = `${namespace}.env`
export const TOKEN_KEY = `${namespace}.token`

export const DEFAULT_TIME_FORMAT = 'YYYY-MM-DD'

const config = {
  setEnv: (env) => store.set(ENV_KEY, env),
  getEnv: () => store.get(ENV_KEY),
  setToken: (jwt) => store.set(TOKEN_KEY, jwt),
  getToken: () => store.get(TOKEN_KEY),
}

window.worksense = window.worksense || {}
window.worksense.config = config

export default config
