export const GroupsActionTypes = Object.freeze({
  ASSIGN_USER_IDS_TO_GROUP: 'ASSIGN_USER_IDS_TO_GROUP',
  STORE_USER_ID_FOR_ASSIGNMENT: 'SELECT_USER_FOR_ASSIGNMENT',
  REMOVE_USER_ID_FROM_ASSIGNMENT: 'REMOVE_USER_ID_FROM_ASSIGNMENT',
  REMOVE_ALL_USER_IDS_FROM_ASSIGNMENT: 'REMOVE_ALL_USER_IDS_FROM_ASSIGNMENT',
  ASSIGN_EXOSKELETON_IDS_TO_GROUP: 'ASSIGN_EXOSKELETON_IDS_TO_GROUP',
  STORE_EXOSKELETON_ID_FOR_ASSIGNMENT: 'SELECT_EXOSKELETON_FOR_ASSIGNMENT',
  REMOVE_EXOSKELETON_ID_FROM_ASSIGNMENT:
    'REMOVE_EXOSKELETON_ID_FROM_ASSIGNMENT',
  REMOVE_ALL_EXOSKELETON_IDS_FROM_ASSIGNMENT:
    'REMOVE_ALL_EXOSKELETON_IDS_FROM_ASSIGNMENT',
})
