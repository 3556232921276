// Palette generator tool based on main color
// http://mcg.mbitson.com/#!?mcgpalette0=%232894e7&themename=mcgtheme

import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import yellow from '@material-ui/core/colors/yellow'
import cyan from '@material-ui/core/colors/cyan'
// import styles

const palette = {
  text: {
    primary: 'rgba(44,64,90,1)',
    secondary: 'rgba(44,64,90,.54)',
    disabled: 'rgba(44,64,90,.38)',
    87: 'rgba(44,64,90,.87)',
    27: 'rgba(44,64,90,.27)',
    14: 'rgba(44,64,90,.14)',
    '07': 'rgba(44,64,90,.07)',
  },

  action: {
    active: 'rgba(44,64,90,.54)',
    hover: 'rgba(44,64,90,.04)',
    selected: 'rgba(44,64,90,.08)',
    disabled: 'rgba(44,64,90,.27)',
    disabledBackground: 'rgba(44,64,90,.12)',
  },

  primary: {
    50: '#e5f2fc',
    100: '#bfdff8',
    200: '#94caf3',
    300: '#69b4ee',
    400: '#57a1e5',
    500: '#2894e7',
    600: '#248ce4',
    700: '#1e81e0',
    800: '#1877dd',
    900: '#0f65d7',
    1000: '#0552B5',
    A100: '#ffffff',
    A200: '#d0e2ff',
    A400: '#9dc3ff',
    A700: '#84b4ff',
    contrastDefaultColor: 'light',
  },

  cyan: {
    ...cyan,
    50: '#e3fafb',
    100: '#baf3f4',
    200: '#8ceced',
    300: '#5de4e6',
    400: '#3bdee0',
    500: '#18d8db',
    600: '#15d4d7',
    700: '#11ced2',
    800: '#0ec8cd',
    900: '#08bfc4',
  },

  red: { ...red },
  green: {
    ...green,
    50: '#e8f9e8',
    100: '#c5efc5',
    200: '#9fe49f',
    300: '#78d978',
    400: '#5bd15b',
    500: '#3ec93e',
    600: '#38c338',
    700: '#30bc30',
    800: '#28b528',
    900: '#1ba91b',
    contrastDefaultColor: 'dark',
  },
  yellow: { ...yellow },
  orange: { ...orange },
  blue: { ...blue },
}

export default palette
