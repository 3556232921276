import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { TableRowSkeleton } from '../atoms/TableRowCellSkeleton'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Checkbox, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import TableNoData from '../atoms/TableNoData'

const ExoskeletonsTable = ({
  showCheckBoxes = false,
  checkboxOnChange = () => {},
  selectedIdsList,
  fetching,
  t,
  pagination,
  exoskeletonsList,
}) => {
  let tableRows = null

  if (fetching && exoskeletonsList.length === 0) {
    tableRows = <TableRowSkeleton cells={4} rows={pagination.rowsPerPage} />
  }

  if (!fetching && exoskeletonsList.length === 0) {
    tableRows = <TableNoData cols={4} />
  }

  if (!fetching && exoskeletonsList.length > 0) {
    tableRows = exoskeletonsList.map((row) => (
      <TableRow hover key={row.id}>
        {showCheckBoxes && (
          <TableCell>
            <Checkbox
              checked={selectedIdsList.includes(row.id)}
              value={row.id}
              onChange={checkboxOnChange}
            />
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body1">
            <strong>{row.name}</strong>
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              t('updated') +
              ': ' +
              dayjs(dayjs.utc(row.updated_at)).utc().fromNow()
            }
          >
            <span>{dayjs(dayjs.utc(row.created_at)).format('DD.M.YYYY')}</span>
          </Tooltip>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {showCheckBoxes && (
              <TableCell>
                {t('exoskeletons:list.tableHeaders.checkbox')}
              </TableCell>
            )}
            <TableCell>{t('exoskeletons:list.tableHeaders.name')}</TableCell>
            <TableCell>
              {t('exoskeletons:list.tableHeaders.createdAt')}{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  )
}

ExoskeletonsTable.propTypes = {
  showCheckBoxes: PropTypes.bool,
  checkboxOnChange: PropTypes.func,
  selectedIdsList: PropTypes.array,
  t: PropTypes.func,
  fetching: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  exoskeletonsList: PropTypes.array.isRequired,
}

export default withTranslation(['exoskeletons'])(ExoskeletonsTable)
