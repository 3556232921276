import React from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { TableRowSkeleton, TableNoData } from '../atoms'
import Paper from '@material-ui/core/Paper'
import { TableRow } from '@material-ui/core'
import { DEFAULT_PAGINATION_OPTIONS } from '../../constants'
import { withTranslation } from 'react-i18next'

export const availableColumns = Object.freeze({
  ID: 'id',
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  UTC_OFFSET: 'utc_offset',
  BORG_SCORE: 'borg_score',
  TASK_NAME: 'task_name',
  USER_ID: 'user_id',
  USER_EMAIL: 'user_email',
})

const renderCellContents = (row, columnName) => {
  switch (columnName) {
    case availableColumns.TASK_NAME:
      return Object.prototype.hasOwnProperty.call(row, 'task') && !!row['task']
        ? row['task']['name']
        : ''
    case availableColumns.USER_EMAIL:
      return Object.prototype.hasOwnProperty.call(row, 'user') && !!row['user']
        ? row['user']['email']
        : ''
    default:
      return row[columnName]
  }
}

const WorklogTable = ({
  worklogList,
  fetching,
  pagination,
  onChangeRowsPerPage: changeRowsPerPageHandler,
  onChangePage: changePageHandler,
  onClickWorklogRow: handleClickWorklogRow,
  error,
  t,
  columnsToDisplay,
}) => {
  let tableRows = null

  if (
    (fetching && worklogList.length === 0) ||
    (fetching && worklogList.length > 0)
  ) {
    tableRows = (
      <TableRowSkeleton
        cells={columnsToDisplay.length}
        rows={pagination.rowsPerPage}
      />
    )
  }

  if (!fetching && error === 0) {
    tableRows = (
      <TableRow>
        <TableCell align="center" colSpan={columnsToDisplay.length}>
          {t('errors:failedToLoadData')}
        </TableCell>
      </TableRow>
    )
  }

  if (!fetching && worklogList.length === 0) {
    tableRows = <TableNoData cols={columnsToDisplay.length} />
  }

  if (!fetching && worklogList.length > 0) {
    tableRows = worklogList.map((row) => (
      <TableRow
        key={row.id}
        onClick={(e) => handleClickWorklogRow(e, row.id)}
        hover
        style={{ cursor: 'pointer' }}
      >
        {columnsToDisplay.map((columnName) => (
          <TableCell key={columnName}>
            {renderCellContents(row, columnName)}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: '0px 2px 6px rgba(0,0,0,.06)' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columnsToDisplay.map((val, i) => (
              <TableCell key={i}>
                {t(`components:worklogTable.tableHeaders.${val}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS.rowsPerPageOptions}
        component="div"
        count={pagination.total || -1}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.currentPage}
        onChangePage={(e, page) => changePageHandler(page, pagination)}
        onChangeRowsPerPage={(e) => changeRowsPerPageHandler(e, pagination)}
      />
    </TableContainer>
  )
}

WorklogTable.propTypes = {
  columnsToDisplay: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(availableColumns)),
  ),
  worklogList: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onClickWorklogRow: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['components'])(WorklogTable)
