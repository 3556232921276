import React from 'react'

class OptionsView extends React.Component {
  render() {
    return (
      <div>
        <span role="img" aria-label="warning">
          ⚠️
        </span>
        <i>page under construction</i>
      </div>
    )
  }
}

export default OptionsView
