import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { submit } from 'redux-form'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'

import { filterDomProps } from '../../utils'

const RemoteSubmitButton = ({
  appTestId,
  dispatch,
  error,
  formId,
  submitting,
  buttonLabel = 'create',
  submitSucceeded,
  syncErrors,
  anyTouched,
  t,
  ...rest
}) => (
  <Button
    app-testid={appTestId}
    onClick={() => dispatch(submit(formId))}
    type="submit"
    color="primary"
    variant="contained"
    component="button"
    disabled={
      submitting ||
      !anyTouched ||
      !!error ||
      !!syncErrors ||
      (submitSucceeded && !!error) ||
      (submitSucceeded && !!syncErrors)
    }
    {...filterDomProps(rest)}
  >
    {!submitting && t(`components:remoteSubmitButton.label.${buttonLabel}`)}
    {submitting && (
      <BeatLoader
        sizeUnit={'px'}
        size={9}
        color={'#fff'}
        loading={submitting}
      />
    )}
  </Button>
)

RemoteSubmitButton.propTypes = {
  appTestId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  syncErrors: PropTypes.object,
  error: PropTypes.string.isRequired,
  anyTouched: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  if (Object.prototype.hasOwnProperty.call(state.form, ownProps.formId)) {
    return {
      submitting: state.form[ownProps.formId].submitting || false,
      submitSucceeded: state.form[ownProps.formId].submitSucceeded || false,
      anyTouched: state.form[ownProps.formId].anyTouched || false,
      error: state.form[ownProps.formId].error || '',
      syncErrors: state.form[ownProps.formId].syncErrors,
    }
  } else {
    return {
      submitting: false,
      submitSucceeded: false,
      anyTouched: false,
      error: '',
      syncErrors: null,
    }
  }
}

export default compose(
  withTranslation(['components']),
  connect(mapStateToProps, null),
)(RemoteSubmitButton)
