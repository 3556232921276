import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import {
  collapseLeftSidebar,
  expandLeftSidebar,
} from '../../redux/ui/ui.actions'
import { appUiSelector } from '../../redux/ui/ui.selectors'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const mapStateToProps = (state) => ({
  isLeftSidebarCollapsed: appUiSelector(state).isLeftSidebarCollapsed,
})

const mapDispatchToProps = {
  collapseLeftSidebar,
  expandLeftSidebar,
}

const styles = (theme) => ({
  root: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    right: '-25px',
    bottom: '8px',
    cursor: 'pointer',
  },

  roundBtn: {
    width: '14px',
    height: '14px',
    background: '#fff',
    position: 'relative',
    top: '12px',
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
    borderRadius: '50%',
    zIndex: theme.zIndex.drawer + 2,
    boxShadow:
      'rgba(9, 30, 66, 0.18) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
    '&:hover': {
      background: 'rgba(250,250,250,1)',
    },
  },
  icon: {
    position: 'relative',
    left: '0px',
    color: 'rgba(5,82,181,.75)',
    top: '-9px',
    fontSize: 18,
    transform: 'rotate(0deg)',
  },

  iconRotated: {
    transform: 'rotate(180deg)',
  },
})

class ToggleCollapseButton extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    collapseLeftSidebar: PropTypes.func.isRequired,
    expandLeftSidebar: PropTypes.func.isRequired,
    isLeftSidebarCollapsed: PropTypes.bool.isRequired,
  }

  handleClick = () => {
    const { collapseLeftSidebar, expandLeftSidebar, isLeftSidebarCollapsed } =
      this.props

    if (isLeftSidebarCollapsed) {
      expandLeftSidebar()
    } else {
      collapseLeftSidebar()
    }
  }

  render() {
    const { classes, isLeftSidebarCollapsed } = this.props

    return (
      <>
        <div
          className={[classes.root, 'toggle'].join(' ')}
          onClick={this.handleClick}
        >
          <IconButton
            aria-label={isLeftSidebarCollapsed ? 'Expand' : 'Collapse'}
            className={[classes.roundBtn, 'collapse-hover'].join(' ')}
          >
            <ChevronRightIcon
              fontSize="small"
              className={
                isLeftSidebarCollapsed
                  ? classes.icon
                  : [classes.icon, classes.iconRotated].join(' ')
              }
            />
          </IconButton>
        </div>
      </>
    )
  }
}

const styled = withStyles(styles)(ToggleCollapseButton)
const connected = connect(mapStateToProps, mapDispatchToProps)(styled)

export default connected
