import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  withStyles,
} from '@material-ui/core'
import { BeatLoader } from 'react-spinners'

import ModalBox from '../../../ModalBox'
import { DEFAULT_PAGINATION_OPTIONS } from '../../../../../constants'
import {
  assignExoskeletonsToGroup,
  fetchGroup,
} from '../../../../../api/api.groups'
import { fetchExoskeletons } from '../../../../../api/api.exoskeletons'
import { ExoskeletonsTable } from '../../../../molecules'
import {
  storeExoskeletonIdForAssignment,
  removeExoskeletonIdFromAssignment,
  removeAllExoskeletonIdsFromAssignment,
} from '../../../../../redux/groups/groups.actions'
import { exoskeletonsAssignmentSelector } from '../../../../../redux/groups/groups.selectors'

const styles = () => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  openFormButton: {
    height: 'auto',
  },
})

class GroupAssignedExoskeletonsList extends Component {
  state = {
    openExoskeletonAssignmentDialog: false,
    group: {
      fetching: false,
      exoskeletons: [],
    },
    exoskeletons: {
      fetching: false,
      list: [],
    },
    unassignedExoskeletonsList: [],
  }

  async componentDidMount() {
    this.fetchGroup()
  }

  async fetchGroup() {
    const {
      match: {
        params: { groupId },
      },
    } = this.props

    this.setState({
      group: { ...this.state.group, fetching: true },
    })

    const rootResponse = await fetchGroup(groupId)

    if (!('error' in rootResponse)) {
      const enclosedResponse = rootResponse.response

      this.setState({
        group: {
          ...this.state.group,
          fetching: false,
          exoskeletons: enclosedResponse.exoskeletons,
        },
      })
    }
  }

  async fetchExoskeletons() {
    this.setState({
      exoskeletons: { ...this.state.exoskeletons, fetching: true },
    })

    const rootResponse = await fetchExoskeletons()

    if (!('error' in rootResponse)) {
      const enclosedResponse = rootResponse.response

      this.setState({
        exoskeletons: {
          ...this.state.exoskeletons,
          fetching: false,
          list: enclosedResponse,
        },
        // remove already assigned exoskeletons from unassigned list
        unassignedExoskeletonsList: enclosedResponse.filter(
          (exoskeleton) =>
            !this.state.group.exoskeletons.find(
              (assignedExoskeleton) =>
                assignedExoskeleton.id === exoskeleton.id,
            ),
        ),
      })
    }
  }

  checkExoskeletonRow = (e, checkState) => {
    const {
      storeExoskeletonIdForAssignment,
      removeExoskeletonIdFromAssignment,
    } = this.props

    checkState
      ? storeExoskeletonIdForAssignment(e.target.value)
      : removeExoskeletonIdFromAssignment(e.target.value)
  }

  handleOpenExoskeletonAssignmentDialog = () =>
    this.setState({ openExoskeletonAssignmentDialog: true }, () =>
      this.fetchExoskeletons(),
    )

  handleCloseFormDialog = () =>
    this.setState({ openExoskeletonAssignmentDialog: false })

  handleAssignExoskeletonIdsToGroup = async () => {
    const {
      selectedIdsList,
      removeAllExoskeletonIdsFromAssignment,
      match: {
        params: { groupId },
      },
    } = this.props

    const rootResponse = await assignExoskeletonsToGroup(
      groupId,
      selectedIdsList,
    )

    if (!rootResponse.error) {
      this.handleCloseFormDialog()
      removeAllExoskeletonIdsFromAssignment()
      this.fetchGroup()
    }
  }

  render() {
    const {
      group,
      exoskeletons,
      openExoskeletonAssignmentDialog,
      unassignedExoskeletonsList,
    } = this.state
    const { classes, selectedIdsList, fetching, t } = this.props

    return (
      <>
        <ModalBox
          modalTitle={t('groups:detail.assignExoskeletonsModal.title')}
          onClose={this.handleCloseFormDialog}
          open={openExoskeletonAssignmentDialog}
          actionButton={
            <Button
              variant="contained"
              color="primary"
              component="button"
              onClick={this.handleAssignExoskeletonIdsToGroup}
            >
              {!fetching && <span>Submit</span>}
              {fetching && (
                <BeatLoader sizeUnit={'px'} size={9} color={'#fff'} loading />
              )}
            </Button>
          }
        >
          <>
            <Typography variant={'body1'} gutterBottom>
              {t('groups:detail.assignExoskeletonsModal.description')}
            </Typography>
            <ExoskeletonsTable
              exoskeletonsList={unassignedExoskeletonsList}
              fetching={exoskeletons.fetching}
              pagination={DEFAULT_PAGINATION_OPTIONS}
              showCheckBoxes
              checkboxOnChange={this.checkExoskeletonRow}
              selectedIdsList={selectedIdsList}
            />
          </>
        </ModalBox>
        <Card>
          <CardHeader
            title={t('groups:detail.assignedExoskeletonsListTable.title')}
            action={
              <Button
                aria-label="assign exoskeletons"
                variant="contained"
                color="primary"
                onClick={this.handleOpenExoskeletonAssignmentDialog}
                className={classes.openFormButton}
              >
                {t(
                  `groups:detail.assignedExoskeletonsListTable.openFormButton${
                    !group.fetching && group.exoskeletons.length === 0 ? 1 : 2
                  }`,
                )}
              </Button>
            }
          />
          <CardContent className={classes.cardContent}>
            <ExoskeletonsTable
              exoskeletonsList={group.exoskeletons}
              fetching={group.fetching}
              pagination={DEFAULT_PAGINATION_OPTIONS}
            />
          </CardContent>
        </Card>
      </>
    )
  }
}

GroupAssignedExoskeletonsList.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  removeAllExoskeletonIdsFromAssignment: PropTypes.func.isRequired,
  selectedIdsList: PropTypes.array.isRequired,
  storeExoskeletonIdForAssignment: PropTypes.func.isRequired,
  removeExoskeletonIdFromAssignment: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  selectedIdsList: exoskeletonsAssignmentSelector(state).exoskeletonIdsList,
  fetching: exoskeletonsAssignmentSelector(state).fetching,
})

const mapDispatchToProps = (dispatch) => ({
  storeExoskeletonIdForAssignment: (exoskeletonId) =>
    dispatch(storeExoskeletonIdForAssignment(exoskeletonId)),
  removeExoskeletonIdFromAssignment: (exoskeletonId) =>
    dispatch(removeExoskeletonIdFromAssignment(exoskeletonId)),
  removeAllExoskeletonIdsFromAssignment: () =>
    dispatch(removeAllExoskeletonIdsFromAssignment()),
})

export default compose(
  withTranslation(['groups']),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(GroupAssignedExoskeletonsList)
