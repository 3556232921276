import palette from '../../themes/styles/palette'

const { cyan, orange, green, red, yellow } = palette

export const physicalCalendarLegend = [
  {
    id: '1',
    label: 'Rest',
    value: 410,
    color: cyan['A400'],
  },
  {
    id: '2',
    label: 'Low',
    value: 410,
    color: green['A700'],
    css: {
      outline: '1px solid rgba(27,31,35,.04)',
      outlineOffset: '-1px',
      rx: 2,
      ry: 2,
    },
  },
  {
    id: '3',
    label: 'Moderate',
    value: 410,
    color: yellow['600'],
  },
  {
    id: '4',
    label: 'High',
    value: 410,
    color: orange['A200'],
  },
  {
    id: '5',
    label: 'Very high',
    value: 410,
    color: red['A200'],
  },
]

export const mentalCalendarLegend = [
  {
    id: '1',
    label: 'Low',
    value: 410,
    color: green['A700'],
  },
  {
    id: '2',
    label: 'Moderate',
    value: 410,
    color: yellow['600'],
  },
  {
    id: '3',
    label: 'High',
    value: 410,
    color: red['A200'],
  },
]

export const sessionsCalendarLegend = []
