import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userRolesSelector } from '../../../redux/user/user.selectors'
import {
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  withStyles,
  Button,
  Avatar,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { AccountIcon } from '../../atoms/Icons'
import { withTranslation } from 'react-i18next'

const styles = (theme) => ({
  accountMenu: {
    position: 'absolute',
    top: 12,
    right: 20,
  },

  menuWrapper: { position: 'relative', top: '-6px', right: '8px' },

  accountMenuButton: {
    padding: '8px',
    height: 38,
    borderRadius: 24,
    fontWeight: 500,
    background: 'rgb(44 64 90 / 0%)',
    '&:hover': {
      background: 'rgb(44 64 90 / 7%)',
    },
  },

  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  role: {
    textTransform: 'capitalize',
  },
  menuClass: {
    width: 200,
    marginTop: 12,
  },

  iconKeyboard: {
    fontSize: 22,
    top: 1,
    position: 'relative',
    marginRight: 0,
    opacity: 0.27,
  },
  iconExit: { fontSize: 18, marginRight: 6 },
})

class TopBarMenu extends Component {
  render() {
    const {
      classes,
      userRoles,
      handleLogout,
      handleToggle,
      handleClose,
      menuOpen,
      t,
    } = this.props

    const roles = userRoles
      ? userRoles.map((role) => role.name.toLowerCase())
      : ''

    return (
      <div className={classes.accountMenu}>
        <Button
          aria-label="Account"
          buttonRef={(node) => {
            this.anchorEl = node
          }}
          disableRipple
          className={classes.accountMenuButton}
          onClick={handleToggle}
          variant="contained"
          disableElevation
        >
          <Avatar
            className={classes.medium}
            color="inherit"
            style={{ left: '-5px', marginRight: 4 }}
          >
            <AccountIcon />
          </Avatar>

          <span className={classes.role}>
            {t('components:TopBar.helloUser', {
              user: userRoles && roles[0],
            })}
          </span>

          <KeyboardArrowDownRoundedIcon className={classes.iconKeyboard} />
        </Button>

        <Popper
          open={menuOpen}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              className={classes.menuWrapper}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={3}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={classes.menuClass}>
                    <MenuItem component={Link} to="/account">
                      {t('components:TopBar.menu.account')}
                    </MenuItem>
                    <MenuItem component={Link} to="/help">
                      {t('components:TopBar.menu.help')}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <ExitToAppOutlinedIcon className={classes.iconExit} />
                      {t('components:TopBar.menu.logout')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userRoles: userRolesSelector(state),
})

TopBarMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  userRoles: PropTypes.array,
  handleLogout: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const styled = withStyles(styles)(TopBarMenu)
const connected = connect(mapStateToProps)(styled)
const translated = withTranslation(['global', 'components'])(connected)

export default translated
