import * as ct from './card-types'

/*
  Root Object key =>  physical || physicalKPIs || mental ||  mentalKPIs || calories
  Root Props => title, colorScale [menral || physical], type [card-types], data
  Data Array of object, props => style [text-bg || text || percent]
*/

const dashCardsModel = (t) => {
  return {
    physical: {
      title: `${t('physical')} ${t('load').toLowerCase()} ${t(
        'average',
      ).toLowerCase()}`,
      colorScale: 'physical',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('session').toLowerCase()}`,
          // subtitle: `(${t('session').toLowerCase()})`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('group').toLowerCase()}`,
        },
        {
          style: 'percent',
          subtitle: `${t('difference')}`,
          value: null,
          color: 'black',
          // postfix: '%',
        },
      ],
    },
    physicalKPIs: {
      title: `${t('physical')} ${t('load').toLowerCase()} ${t(
        'session',
      ).toLowerCase()}`,
      colorScale: 'physical',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('max')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('min')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('average')}`,
        },
      ],
    },
    mental: {
      title: `${t('mental')} ${t('load').toLowerCase()} ${t(
        'average',
      ).toLowerCase()}`,
      colorScale: 'mental',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('session').toLowerCase()}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('group').toLowerCase()}`,
        },
        {
          style: 'percent',
          subtitle: 'difference',
          value: null,
          color: 'black',
          // postfix: '%',
        },
      ],
    },
    mentalKPIs: {
      title: `${t('mental')} ${t('load').toLowerCase()} ${t(
        'session',
      ).toLowerCase()}`,
      colorScale: 'mental',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('max')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('min')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('average')}`,
        },
      ],
    },
    workload: {
      title: `${t('workload')} ${t('average').toLowerCase()}`,
      colorScale: 'mental',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('session').toLowerCase()}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('group').toLowerCase()}`,
        },
        {
          style: 'percent',
          subtitle: 'difference',
          value: null,
          color: 'black',
          // postfix: '%',
        },
      ],
    },
    workloadKPIs: {
      title: `${t('workload')} ${t('session').toLowerCase()}`,
      colorScale: 'mental',
      type: ct.SINGLE_TITLE,
      data: [
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('max')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('min')}`,
        },
        {
          style: 'text-bg',
          value: null,
          subtitle: `${t('average')}`,
        },
      ],
    },
    calories: {
      title: `${t('totCal')} (Kcal)`,
      colorScale: 'mental',
      type: ct.SINGLE_TITLE,
      data: [
        {
          value: null,
          color: 'black',
          style: 'text',
          subtitle: `${t('session').toLowerCase()}`,
        },
        {
          value: null,
          color: 'black',
          style: 'text',
          subtitle: `${t('group').toLowerCase()}`,
        },
        {
          value: null,
          color: 'black',
          style: 'percent',
          subtitle: `${t('group').toLowerCase()}`,
        },
      ],
    },
    heartRate: {
      title: `${t('heartRate')} ${t('trend').toLowerCase()}`,
      colorScale: 'physical',
      type: ct.SINGLE_TITLE,
      data: [
        {
          value: null,
          style: 'text',
          color: 'black',
          subtitle: `${t('max')}`,
        },
        {
          value: null,
          style: 'text',
          color: 'black',
          subtitle: `${t('min')}`,
        },
        {
          value: null,
          style: 'text',
          color: 'black',
          subtitle: `${t('average')}`,
        },
      ],
    },
  }
}

export default dashCardsModel
