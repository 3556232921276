import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, SvgWrapper } from '@nivo/core'
import { BoxLegendSvg } from '@nivo/legends'

const theme = {
  fontFamily: 'Maven Pro',
  fontWeight: 700,
  fontSize: 14,
  textColor: '#333333',
  tooltip: {
    tableCell: {
      background: 'red',
    },
  },
}

const CalendarLegends = ({ legends }) => {
  const height = 32

  return (
    <div style={{ position: 'absolute', right: '36px', zIndex: 2 }}>
      <ThemeProvider theme={theme}>
        <SvgWrapper
          height={height}
          width={520}
          margin={{ left: 0, right: 0, top: 16, bottom: 0 }}
        >
          <BoxLegendSvg
            anchor="right"
            data={legends}
            containerWidth={400}
            containerHeight={height}
            height={height}
            width={400}
            direction="row"
            // itemDirection="right-to-left"
            itemWidth={80}
            itemHeight={20}
            itemsSpacing={15}
            // itemOpacity={.5}
            padding={10}
            translateX={100}
            translateY={-10}
            symbolSize={14}
            symbolShape="square"
          />
        </SvgWrapper>
      </ThemeProvider>
    </div>
  )
}

CalendarLegends.propTypes = {
  legends: PropTypes.array.isRequired,
}

export default CalendarLegends
