import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Helmet } from 'react-helmet'
import { SubmissionError } from 'redux-form'
import { fetchGroups, createGroup } from '../../../../api/api.groups'
import ModalBox from '../../ModalBox'
import { RemoteSubmitButton } from '../../../atoms'
import GroupListTable from './GroupListTable'
import {
  AppTestIds,
  Roles,
  Routes,
  CREATE_GROUP_FORM_ID,
} from '../../../../constants'
import Restricted from '../../../atoms/Restricted'
import { GroupForm, StatHeader } from '../../../molecules'
import { userRolesSelector } from '../../../../redux/user/user.selectors'
import {
  createRoute,
  hasAnyOfRequiredRoles,
  getSummaries,
} from '../../../../utils'

class GroupListView extends Component {
  state = {
    fetching: false,
    openFormDialog: false,
    groupList: [],
    groupKPIs: {
      avg_duration: 0,
      avg_kcal: 0,
      avg_mental: 0,
      avg_physical: 0,
    },
  }

  // async awaits also the internal React lifecycle method, with this we avoid
  // unecessary call to componentDiDUpdate and checking which props changed
  async componentDidMount() {
    this.setState({ fetching: true })
    this.fetchGroupList()
  }

  fetchGroupList = async () => {
    const rootResponse = await fetchGroups()
    const enclosedResponse = rootResponse.response

    // set 0 if kpi in list table are null or
    // kpi property not present in response
    if (!('error' in rootResponse)) {
      enclosedResponse.forEach((group) => {
        if (group.kpis === null || !('kpis' in group)) {
          group.kpis = {
            agg_avg_duration: 0,
            agg_avg_kcal_per_minute: 0,
            agg_avg_physical_load: 0,
            agg_avg_mental_load: 0,
          }
        }
      })

      this.setState({
        groupList: enclosedResponse,
        fetching: false,
        groupKPIs: {
          ...getSummaries('all', enclosedResponse),
        },
      })
    }
  }

  handleOpenFormDialog = () => this.setState({ openFormDialog: true })

  handleCloseFormDialog = () => this.setState({ openFormDialog: false })

  submitCreateGroupForm = async (values) => {
    const rootResponse = await createGroup(values)

    if (
      rootResponse.status === 'success' ||
      rootResponse.message === 'GROUP_CREATED'
    ) {
      this.handleCloseFormDialog()
      this.fetchGroupList()
    } else {
      throw new SubmissionError({
        ...rootResponse.response,
        _error: rootResponse.message,
      })
    }
  }

  handleClickGroupRow = (e, id) => {
    const { history, userRoles } = this.props
    if (hasAnyOfRequiredRoles(userRoles, [Roles.ADMIN]))
      history.push(createRoute(Routes.Groups.Detail.url, { groupId: id }))
  }

  render() {
    const { t, userTenant } = this.props
    const { fetching, openFormDialog, groupList } = this.state

    const summaryKPIs = {
      optionalKPI: this.state.groupList.length,
      avgMental: this.state.groupKPIs.avg_mental,
      avgPhy: this.state.groupKPIs.avg_physical,
      avgDur: this.state.groupKPIs.avg_duration,
      avgKcal: this.state.groupKPIs.avg_kcal,
    }

    return (
      <>
        <Helmet>
          <title>{t(Routes.Groups.title)}</title>
        </Helmet>

        <ModalBox
          modalTitle="Create group"
          onClose={this.handleCloseFormDialog}
          open={openFormDialog}
          actionButton={
            <RemoteSubmitButton
              appTestId={AppTestIds.Groups.SubmitDialog}
              formId={CREATE_GROUP_FORM_ID}
            />
          }
        >
          <GroupForm
            initialValues={{
              tenant_id: userTenant,
            }}
            onSubmit={this.submitCreateGroupForm}
            form={CREATE_GROUP_FORM_ID}
          />
        </ModalBox>

        <Restricted anyOfRequiredRoles={[Roles.ADMIN, Roles.SUPER_ADMIN]}>
          <Box
            display="flex"
            flexDirection="row-reverse"
            p={1}
            m={1}
            style={{ padding: '0px', margin: '0px -8px 8px -8px' }}
          >
            <Box p={1}>
              <Button
                app-testid={AppTestIds.Groups.OpenDialog}
                aria-label="add group"
                variant="contained"
                color="primary"
                onClick={this.handleOpenFormDialog}
              >
                {t('add') + ' ' + t('group')}
              </Button>
            </Box>
          </Box>
        </Restricted>

        <Restricted anyOfRequiredRoles={[Roles.MANAGER]}>
          <StatHeader
            type="groups"
            selectedGroup="all"
            handleViewError={this.triggerViewError}
            kpis={summaryKPIs}
            fetching={fetching}
          />
        </Restricted>

        <GroupListTable
          fetching={fetching}
          pagination={{ rowsPerPage: 10 }}
          groupList={groupList}
          handleClickGroupRow={this.handleClickGroupRow}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userTenant: state.user.tenantId,
  userRoles: userRolesSelector(state),
})

GroupListView.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  userTenant: PropTypes.string.isRequired,
  userRoles: PropTypes.array,
}

const translated = withTranslation(['global'])(GroupListView)
const connected = connect(mapStateToProps)(translated)

export default connected
