import React from 'react'
import * as PropTypes from 'prop-types'
import type { InferProps } from 'prop-types'
import { withTranslation } from 'react-i18next'
import type { WithTranslation } from 'react-i18next'
import type { WrappedFieldProps } from 'redux-form'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import { filterDomProps } from '../../utils'
import type { RestProps } from '../../types'

const UserRolesRadioGroupTypes = {
  availableRoles: PropTypes.array.isRequired,
}

const UserRolesRadioGroup: React.FC<
  InferProps<typeof UserRolesRadioGroupTypes> &
    RestProps &
    WrappedFieldProps &
    WithTranslation
> = ({ availableRoles, input, t, ...rest }) => {
  return (
    <FormControl fullWidth>
      <FormLabel component="legend">
        {t('components:userRolesRadioGroup.formLabel')}
      </FormLabel>
      <RadioGroup
        aria-label="user-role"
        row
        style={{
          justifyContent: 'center',
          gap: '1rem',
        }}
        {...input}
        value={(input.value as string) && Number(input.value as string)}
        // eslint-disable-next-line
        {...filterDomProps(rest as any)}
      >
        {availableRoles.map((role: { id: number; name: string }) => (
          <FormControlLabel
            key={role.id}
            value={Number(role.id)}
            control={<Radio color="primary" />}
            label={t(`global:${role.name.toLowerCase()}`)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

UserRolesRadioGroup.propTypes = UserRolesRadioGroupTypes

export default withTranslation(['components'])(UserRolesRadioGroup)
