import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TextField, MenuItem } from '@material-ui/core'
import { fetchUserRoles } from '../../api/api.users'

class UserRolesDropdown extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  state = {
    fetching: false,
    fetchError: false,
    errorMessage: '',
    roles: null,
  }

  async componentDidMount() {
    !this.unmount && this.setState({ fetching: true })

    await this.fetchUserRoles()
  }

  componentWillUnmount() {
    this.unmount = true
  }

  fetchUserRoles = async () => {
    const rootResponse = await fetchUserRoles()
    const enclosedResponse = rootResponse.response

    if (!('error' in rootResponse)) {
      !this.unmount &&
        this.setState({
          fetching: false,
          roles: enclosedResponse,
        })
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchError: true,
          errorMessage: 'Error fetching Roles',
        })
    }
  }

  render() {
    const { roles, fetching, fetchError, errorMessage } = this.state
    const { label, defaultValue = '', value, ...rest } = this.props
    return (
      <TextField
        select
        label={label}
        defaultValue={defaultValue}
        // TODO: Refactor condition below after all forms and success/error popups have been refactored
        // EXPLANATION: A render will trigger from a form somewhere when reduxform is destroying the inputs
        // after successfully submitting. For that render, the props submitting and submitSucceeded will be both false
        // this means that before this component re-renders, the "value" will be set but the "roles" will not be fetched yet
        value={roles ? value : ''}
        {...rest}
      >
        {!fetching && roles
          ? roles.map((role) => (
              <MenuItem value={role.id} key={role.id}>
                {role.name}
              </MenuItem>
            ))
          : 'Loading'}
        {fetchError ? errorMessage : null}
      </TextField>
    )
  }
}

export default UserRolesDropdown
